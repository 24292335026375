import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { Notification } from 'element-ui';
/**
 * 通过地址来下载文件
 * @param downUrl
 *  * @param fileName
 */
export function downLoadByURL(downUrl: string, fileName = '文件名称') {
  const a = document.createElement('a'); // 创建a标签
  if ('download' in a) {
    a.download = fileName; // 设置下载文件的文件名
  }
  (document.body || document.documentElement).appendChild(a);
  a.href = downUrl; // downUrl为后台返回的下载地址
  a.target = '_parent';
  a.click(); // 设置点击事件
  a.remove(); // 移除a标签
}

function getFile(url: any) {
  return new Promise((resolve, reject) => {
    // 通过请求获取文件blob格式
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open('GET', url, true);
    xmlhttp.responseType = 'blob';
    xmlhttp.onload = function () {
      if (this.status === 200) {
        resolve(this.response);
      } else {
        reject(this.status);
      }
    };
    xmlhttp.send();
  });
}

/**
 * 下载文件 传数组  实例：[{url:www.123.jpg,name:'123.jpg'}]   url：文件网络路径，name：文件名字
 * @param {*} fileList
 */
export function downLoadFile(fileList: any, fileName: string) {
  const zip = new JSZip();
  const promises = [] as any;
  fileList.forEach((item: any) => {
    const promise = getFile(item.url).then((data: any) => {
      zip.file(item.name, data, { binary: true }); // 文件名、文件流、是否为二进制
    });
    promises.push(promise);
  });
  return Promise.all(promises)
    .then(() => {
      zip.generateAsync({ type: 'blob' }).then(content => {
        FileSaver.saveAs(content, fileName);
      });
      Notification({
        title: '下载成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
    })
    .catch(() => {
      Notification({
        title: '文件压缩失败',
        message: '',
        type: 'error',
        position: 'bottom-right'
      });
    });
}
