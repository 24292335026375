
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import service from '@/api/consumerPush';
import { ElForm } from 'element-ui/types/form';
import serviceC from '@/api/consumerUser';
import OSS from 'ali-oss';
import drawer from './component/drawer.vue';
import YkWarn from '@/components/Yk_Warn/index.vue';
@Component({ components: { drawer, YkWarn } })
export default class PageConsumerPush extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    searchParam: null,
    status: null,
    searchStartTime: null,
    searchEndTime: null,
    orders: {
      column: 'ba_created_on',
      asc: false
    }
  } as any;

  pushTime = [] as Date[];

  params = {
    pageNum: 1,
    pageSize: 10
  };

  loading = false;
  isWarn = false;
  isShowDrawer = false;
  BannerDetail = {} as any;
  srcList = [] as any;
  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  statusList = [
    {
      value: '0',
      label: '未设置'
    },
    {
      value: '1',
      label: '待推送'
    },
    {
      value: '2',
      label: '推送成功'
    },
    {
      value: '3',
      label: '推送失败'
    }
  ] as any;

  tableList = [] as any;
  list = [] as any;
  pageTotal = 0;
  clashNum = 0 as any; // 冲突设置数量
  async ShowDrawer(row: any) {
    this.srcList = [];
    this.isShowDrawer = true;
    const {
      data: { data }
    } = await service.getByBaNumber(row.baNumber);
    this.BannerDetail = { ...data };
    if (this.BannerDetail.baStatus === 0) {
      this.BannerDetail.baStatus = '待上线';
    } else if (this.BannerDetail.baStatus === 1) {
      this.BannerDetail.baStatus = '已上线';
    } else {
      this.BannerDetail.baStatus = '已下线';
    }
    if (this.BannerDetail.type === 1) {
      this.BannerDetail.type = '日常';
    } else if (this.BannerDetail.type === 2) {
      this.BannerDetail.type = '活动';
    } else {
      this.BannerDetail.type = '个性化';
    }
    if (this.BannerDetail.smStatus === 1) {
      this.BannerDetail.smStatus = '未使用';
    } else if (this.BannerDetail.smStatus === 2) {
      this.BannerDetail.smStatus = '已使用';
    }

    if (!this.BannerDetail.tagName) {
      this.BannerDetail.tagName = '所有用户';
      this.BannerDetail.tagColor = '';
    }
    const res = await serviceC.getOSSToken('user/material/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + this.BannerDetail.smUrl, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
    }
  }

  getDefaultTime() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);
    this.pushTime = [start, end] as any;
  }

  sortChange(val: any) {
    if (val.order === 'descending') {
      this.queryForm.orders.asc = false;
    } else {
      this.queryForm.orders.asc = true;
    }
    this.getList();
  }

  edit(row: any) {
    const date = new Date(); // 1. js获取当前时间
    if (row.baStatus === 2 || date.getTime() > this.$moment(row.endTime).valueOf()) {
      this.isWarn = true;
      return;
    }
    this.$router.push(`/consumerPush/detail/${row.baNumber}`);
  }

  onReset() {
    this.pushTime = [];
    this.params = {
      pageNum: 1,
      pageSize: 10
    };
    this.$queryForm.resetFields();
    this.getList();
  }

  handlePageNum(value: number) {
    this.params.pageNum = value;
    this.getList();
  }

  repetition(arr: any) {
    // 保留相同baNumber的最新记录的编号、时间
    const list = [...arr];
    arr.forEach((i: any) => {
      list.forEach((j: any) => {
        if (i.baNumber === j.baNumber && !j.index) {
          j.baNumber = '';
          j.baCreatedOn = '';
        }
      });
    });
    return list;
  }

  async getList() {
    this.loading = true;
    this.clashNum = 0;
    if (this.pushTime !== null && this.pushTime.length > 1) {
      this.queryForm.searchStartTime = this.$moment(this.pushTime[0]).format('yyyy-MM-DD HH:mm:ss') as any;
      this.queryForm.searchEndTime = this.$moment(this.pushTime[1].setHours(23, 59, 59)).format('yyyy-MM-DD HH:mm:ss') as any;
    } else {
      this.queryForm.searchStartTime = null;
      this.queryForm.searchEndTime = null;
    }
    let tableList = [] as any;
    this.list = [] as any;
    const {
      data: { data }
    } = await service.query(this.params, this.queryForm);
    if (data !== null) {
      this.pageTotal = data.total;
      tableList = data.records;
      tableList.forEach((item: any) => {
        item.bannerPushList.forEach((push: any, index: any) => {
          push.pushType = item.pushType;
          push.endTime = item.endTime;
          push.startTime = item.startTime;
          push.baStatus = item.baStatus;
          if (index === 0) {
            push.index = 1;
          } else {
            push.index = '';
          }
        });
        this.list.push(...this.repetition(item.bannerPushList));
      });
      this.list.forEach((item: any) => {
        if (item.status === 0) {
          item.status = '未设置';
        } else if (item.status === 1) {
          item.status = '待推送';
        } else if (item.status === 2) {
          item.status = '推送成功';
        } else if (item.status === 3) {
          item.status = '推送失败';
        }
        Object.keys(item).forEach((key: any) => {
          if (item[key] === null) {
            item[key] = '-';
          }
        });

        if (
          (item.pushTime !== '-' && this.$moment(item.pushTime).valueOf() > this.$moment(item.endTime).valueOf()) ||
          this.$moment(item.pushTime).valueOf() < this.$moment(item.startTime).valueOf()
        ) {
          if (item.baNumber && item.status === '待推送') {
            this.clashNum++;
          }
        }
      });
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  activated() {
    this.onReset();
  }

  // table
  tableRequest = service.query;
  get tableColumns() {
    const data: ColumnItem<consumerPushItem>[] = [
      { slot: 'baNumber', label: 'Banner编号', prop: 'baNumber', width: 150 },
      { label: 'Banner发布时间', prop: 'baCreatedOn', sortable: 'custom', width: 260 },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 180
      },
      { slot: 'status', label: '状态', prop: 'status', width: 180 },
      { label: '推送标题', prop: 'title', tooltip: true },
      { label: '推送内容', prop: 'content', tooltip: true },
      { label: '推送时间', prop: 'pushTime' }
    ];
    return data;
  }
}
