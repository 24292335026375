
import { Component, Mixins, Watch, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import TreeSelect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import service from '@/api/user';
import { ElForm } from 'element-ui/types/form';
import YkWarn from '@/components/Yk_Warn/index.vue';

@Component({ components: { TreeSelect, YkWarn } })
export default class PageUser extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    type: 1, // 用户类型 1:平台用户  4:gApp用户
    likeParam: null, // 关键字模糊查询
    phoneNumber: null, // 手机号
    departmentId: null, // 部门id
    roleId: null, // 角色id
    roleName: null
  };

  Role = [] as UserRole[];
  Department = [] as UserTree[];
  depVal = null;

  isWarn = false;
  ConfirmFlag = false;

  @Watch('depVal', { immediate: true })
  onValChange(newValue: any) {
    this.queryForm.departmentId = newValue;
  }

  handleSelect(val: any) {
    this.queryForm.roleId = val;
  }

  userId = '';
  del(row: UserRow) {
    service
      .userCanDelCheck(row.id)
      .then(() => {
        this.ConfirmFlag = true;
        this.userId = row.id;
      })
      .catch(() => {
        this.isWarn = true;
      });
  }

  onReset() {
    this.$queryForm.resetFields();
    this.depVal = null;
    this.queryForm.roleId = null;
    this.onResetM(true);
  }

  // 删除完成
  async confirmDone() {
    const {
      data: { msg }
    } = await service.remove(this.userId);
    if (msg === '操作成功') {
      this.ConfirmFlag = false;
      this.onQueryM();
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
    }
  }

  async getRole() {
    const {
      data: { data }
    } = await service.getAllRole();
    if (data !== null) {
      data.forEach((item: UserRole) => {
        item.label = item.name;
        item.value = item.id;
      });
      this.Role = data;
    } else {
      this.Role = [];
    }
  }

  async getTreeDep() {
    const {
      data: { data }
    } = await service.getTreeDep();
    if (data !== null) {
      this.Department = this.changTree(data);
    } else {
      this.Department = [];
    }
  }

  // 处理树形children为null
  changTree(data: any) {
    data.map((item: any) => {
      if (item.children == null) {
        delete item.children;
        item.label = item.name;
      }
      if (item.children) {
        this.changTree(item.children);
        item.label = item.name;
      }
    });
    return data;
  }

  created() {
    this.getRole();
    this.getTreeDep();
  }

  activated() {
    this.onQueryM();
  }

  // table
  tableRequest = service.query;
  removeRequest = service.remove;
  get tableColumns() {
    const data: ColumnItem<UserItem>[] = [
      { label: '账号ID', prop: 'externalId', minWidth: 100, tooltip: true },
      { label: '账号姓名', prop: 'fullname' },
      { label: '手机号', prop: 'phoneNumber' },
      { label: '部门', prop: 'departmentName' },
      { label: '所属角色', prop: 'roleName', minWidth: 100, tooltip: true },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 200
      }
    ];
    return data;
  }
}
