import request from '@/utils/request';
const headers = {
  'accept-language': 'zh-Hans-CN',
  from: 1
};
const service = {
  query(params: any, data: any) {
    return request({
      method: 'POST',
      url: `/api/user/v1/tag/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
      headers: headers,
      data
    });
  },
  create(data: any) {
    return request({
      method: 'POST',
      url: '/api/user/v1/tag/add',
      headers: headers,
      data
    });
  },
  update(data: any) {
    return request({
      method: 'POST',
      url: '/api/user/v1/tag/update',
      headers: headers,
      data
    });
  },
  remove(id: any) {
    return request({
      method: 'POST',
      url: `/api/user/v1/tag/del?ids=${id}`,
      headers: headers
    });
  },
  detail(id: any) {
    return request({
      method: 'get',
      url: `/api/user/v1/tag/getById/${id}`,
      headers: headers
    });
  },
  existed(params: any) {
    return request({
      method: 'post',
      url: `/api/user/v1/tag/existed?name=${params.name}&id=${params.id}`,
      headers: headers
    });
  },
  tagCanDelCheck(id: any) {
    return request({
      method: 'post',
      url: `/api/user/v1/tag/canDelCheck?id=${id}`,
      headers: {
        'Accept-Language': 'zh-CN',
        from: 1
      }
    });
  }
};

export default service;
