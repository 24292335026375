
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import service from '@/api/consumerUser';
import { ElForm } from 'element-ui/types/form';

@Component({ components: {} })
export default class PageConsumerUser extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    type: 4, // 用户类型 1:平台用户  4:gApp用户
    phoneNumber: null, // 手机号
    externalId: null,
    fullname: null, // 昵称
    regionLocationId: null, // 省市code
    tagId: null, // 用户画像id
    deviceId: null // 设备id
  } as any;

  params = {
    pageNum: 1,
    pageSize: 10
  };

  options = [] as any;
  province = [] as any;
  city = [] as any;
  tagList = [] as any;
  userTagList = [] as any;
  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  selectVal = '手机号';
  selectOptions = [
    {
      value: '手机号',
      label: '手机号'
    },
    {
      value: '用户ID',
      label: '用户ID'
    },
    {
      value: '用户昵称',
      label: '用户昵称'
    },
    {
      value: '设备ID',
      label: '设备ID'
    }
  ];

  list = [] as any;
  pageTotal = 0;
  showTooltip = false;
  isShow = false;
  isReset = true;
  edit(id: number) {
    this.$router.push(`/consumerUser/detail/${id}`);
    this.$queryForm.resetFields();
  }

  id = 0;
  props = {
    lazy: true,
    lazyLoad: this.lazyLoad
  };

  @Watch('queryForm', { deep: true })
  onChangeForm() {
    Object.keys(this.queryForm).forEach((key: any) => {
      if (this.queryForm[key] === '') {
        this.queryForm[key] = null;
      }
    });
  }

  lazyLoad(node: any, resolve: any) {
    const { level } = node;
    let id = '';
    if (level) {
      id = node.data.pId;
    }
    this.getAreaInfo(id).then((data: any) => {
      if (data) {
        const nodes = data.map((item: any) => {
          item.label = item.nameZh;
          item.value = item.locationId;
          item.pId = item.id;
          item.leaf = level >= 1;
          return item;
        });
        resolve(nodes);
      }
    });
  }

  async cascaderChange(val: any) {
    if (val.length) {
      this.queryForm.regionLocationId = val[1];
    } else {
      this.queryForm.regionLocationId = null;
    }
  }

  handleSelect() {
    this.$queryForm.resetFields();
    this.queryForm.regionLocationId = null;
    this.queryForm.tagId = null;
    this.queryForm.externalId = null;
  }

  handlePageNum(value: number) {
    this.params.pageNum = value;
    this.getList();
  }

  onReset() {
    this.selectVal = '手机号';
    this.$queryForm.resetFields();
    this.isReset = true;
    this.isShow = false;
  }

  async getList() {
    this.userTagList = [] as any;
    let num = 0;
    Object.keys(this.queryForm).forEach((key: any) => {
      if (this.queryForm[key] === null) {
        num++;
      }
    });
    if (num < 6) {
      const {
        data: { data }
      } = await service.query(this.params, this.queryForm);
      this.pageTotal = data.total;
      if (data.records) {
        this.list = data.records;
        this.list.forEach((item: any) => {
          Object.keys(item).forEach(key => {
            if (item[key] === null) {
              item[key] = '-';
            }
          });
          if (item.tags !== '-') {
            item.tags.forEach((tag: any) => {
              if (tag.color === null) {
                tag.color = 1;
              }
            });
          }
        });
        this.isShow = true;
      } else {
        this.isShow = false;
        this.isReset = false;
      }
    } else {
      this.isShow = false;
      this.isReset = false;
    }
  }

  async getTagList() {
    const queryForm = {
      likeName: null,
      orders: [
        {
          column: 'modified_on',
          asc: false
        }
      ]
    };
    const params = {
      pageNum: 1,
      pageSize: 10000
    };
    const {
      data: { data }
    } = await service.getTagList(params, queryForm);
    this.tagList = data.records;
    this.tagList.forEach((item: any) => {
      item.label = item.name;
      item.value = item.id;
    });
  }

  async getAreaInfo(id: any) {
    const {
      data: { data }
    } = await service.getAreaInfo(id);
    return data;
  }

  activated() {
    this.isReset = true;
    this.isShow = false;
    this.selectVal = '手机号';
    this.$queryForm.resetFields();
    this.getTagList();
    this.params = {
      pageNum: 1,
      pageSize: 10
    };
  }

  // table
  get tableColumns() {
    const data: ColumnItem<ConsumerUserItem>[] = [
      {
        slot: 'externalId',
        prop: 'externalId',
        label: '用户ID',
        fixed: 'left',
        width: 200
      },
      { label: '用户昵称', prop: 'fullname', width: 200, fixed: 'left' },
      {
        label: '性别',
        prop: 'gender',
        formatter(row, col, value) {
          return value === 0 ? '-' : value === 1 ? '男' : '女';
        }
      },
      {
        label: '肤质16型',
        prop: 'personalProfile',
        width: 260,
        formatter(row, col, value) {
          return value !== null && value !== '-' ? value.skinEnAbridge + `(${value.skinCn})` : '-';
        }
      },
      {
        label: 'IP所在省市',
        prop: 'locationNames',
        formatter(row, col, value) {
          return value !== null && value !== '-' ? value.join(',') : '-';
        }
      },
      { label: '用户注册时间', prop: 'createdOn', width: 200 },
      { label: '首次绑定设备时间', prop: 'firstBindDeviceTime', width: 200 },
      {
        slot: 'actions',
        prop: 'actions',
        label: '用户画像',
        width: 200,
        fixed: 'right'
      }
    ];
    return data;
  }
}
