
import { Component, Vue } from 'vue-property-decorator';
import service from '@/api/reportFile';
@Component({ components: {} })
export default class BannerDetail extends Vue {
  title = '编辑 黑眼圈-变化报告标签';
  ConfirmFlag = false;
  isWarning = false;
  warnText = '';
  list = [
    {
      title: '新问题',
      description: '',
      disabled: true,
      symptom: 1
    },
    {
      title: '变严重',
      description: '',
      disabled: true,
      symptom: 2
    },
    {
      title: '持续中',
      description: '',
      disabled: true,
      symptom: 0
    },
    {
      title: '已改善',
      description: '',
      disabled: true,
      symptom: -1
    },
    {
      title: '已恢复',
      description: '',
      disabled: true,
      symptom: -2
    }
  ];

  queryData = [] as any;

  edit(index: number) {
    this.list.forEach((item: any, i: any) => {
      if (index === i) {
        item.disabled = false;
      }
    });
  }

  created() {
    this.title = '编辑' + ' ' + this.$route.query.targetName + '-变化报告标签';
  }

  async activated() {
    if (this.$route.params) {
      const {
        data: { data }
      } = await service.skinTagDetail(this.$route.params);
      this.list.forEach((list: any) => {
        list.disabled = true;
        data.forEach((item: any) => {
          if (list.symptom === item.symptom) {
            list.description = item.description;
          }
        });
      });
    }
  }

  closeConfirm() {
    this.ConfirmFlag = false;
    this.$router.go(-1);
  }

  // 确认保存
  async confirmDone() {
    this.queryData = [];
    this.list.forEach((item: any) => {
      this.queryData.push({
        symptom: item.symptom, // 症状状态标识 -2 消退(无) -1 减轻 0 无变化 1 新增(有) 2 加重
        description: item.description,
        ...this.$route.params // 文案内容
      });
    });
    const {
      data: { msg }
    } = await service.skinTagEdit(this.queryData);
    if (msg === '操作成功') {
      this.$notify({
        title: '保存成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.closeConfirm();
    }
  }
}
