import request from '@/utils/request';

const service = {
  query(params: any, data: any) {
    return request.post(`/api/skin/v1/question/group/draft/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`, data);
  },
  created(data: any) {
    return request.post('/api/cms/v1/banner/add', data);
  },
  update(data: any) {
    return request.post('/api/cms/v1/banner/update', data);
  },
  detail(id: any) {
    return request.get(`/api/cms/v1/banner/getById/${id}`);
  },
  getSCList(params: any, data: any) {
    return request.post(
      `/api/cms/v1/banner/sm/page?pageNum=${params.pageNum}&pageSize=${params.pageSize}&searchParam=${params.searchParam}`,
      data
    );
  },
  //   题组下拉列表
  getQuestionGroup() {
    return request.post('/api/skin/v1/question/group/draft/downList');
  },
  //   查询皮肤问题类型
  getByDimension(params: any) {
    return request.get(`/api/skin/v1/target/getByDimension?dimension=${params.dimension}`);
  },
  //   查询题组内其他的题目
  getOtherInGroup(params: any) {
    return request.get(
      `/api/skin/v1/question/draft/getOtherInGroup?questionGroupId=${params.questionGroupId}&questionId=${params.questionId}`
    );
  },
  //   保存题目
  saveDraft(data: any) {
    return request.post('/api/skin/v1/question/draft/save', data);
  },
  //   查询题目
  getDraft(id: any) {
    return request.get(`/api/skin/v1/question/draft/getById/${id}`);
  },
  //   删除题目变更记录
  delChangeRecord(id: any, data: any) {
    return request.post(`/api/skin/v1/question/draft/delChangeRecord?id=${id}`, data);
  },
  //   发布题组
  publishGroup(id: any, data: any) {
    return request.post(`/api/skin/v1/question/group/draft/publish?id=${id}`, data);
  },
  //   撤销发布题组
  unPublishGroup(id: any, data: any) {
    return request.post(`/api/skin/v1/question/group/draft/unPublish?id=${id}`, data);
  },
  //   取消排序
  cancelSort(id: any, data: any) {
    return request.post(`/api/skin/v1/question/group/draft/cancelSort?id=${id}`, data);
  },
  //  题目排序
  questionSort(data: any) {
    return request.post('/api/skin/v1/question/group/draft/questionSort', data);
  },
  //  查询题目列表
  getQuestionList(id: any) {
    return request.get(`/api/skin/v1/question/draft/getQuestionList?questionGroupId=${id}`);
  },
  //  能否删除题目
  canDelQuestion(id: any) {
    return request.get(`/api/skin/v1/question/draft/canDelQuestion?id=${id}`);
  },
  //  题目是否被文案关联
  isAssociatedText(id: any, data: any) {
    return request.post(`/api/skin/v1/question/draft/isAssociatedText?questionId=${id}`, data);
  },
  //  删除题目
  delQuestion(id: any, data: any) {
    return request.post(`/api/skin/v1/question/draft/delQuestion?id=${id}`, data);
  },
  //  已发布题目
  questionList(params: any, data: any) {
    return request.post(`/api/skin/v1/question/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`, data);
  },
  //  删除已发布题目
  delQuestionPub(id: any, data: any) {
    return request.post(`/api/skin/v1/question/delQuestion?id=${id}`, data);
  },
  //  已发布题组
  groupAllList(data: any) {
    return request.post('/api/skin/v1/question/group/allList', data);
  },
  //  已发布题组排序
  groupSort(ids: any) {
    return request.post(`/api/skin/v1/question/group/sort?ids=${ids}`);
  },
  //  已发布题组排序
  groupQuestionList(id: any) {
    return request.get(`/api/skin/v1/question/getQuestionList?questionGroupId=${id}`);
  },
  //  已发布题组是否有更改
  groupHasChange(ids: any) {
    return request.post(`/api/skin/v1/question/group/draft/hasChange?id=${ids}`);
  }
};

export default service;
