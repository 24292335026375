import request from '@/utils/request';

const headers = {
  'Content-Type': 'multipart/form-data'
};
const service = {
  query(data: any) {
    return request({
      method: 'POST',
      url: '/api/ota/v1/version/list',
      headers,
      data
    });
  },
  create(data: any) {
    return request({
      method: 'POST',
      url: '/api/ota/v1/version/add',
      headers,
      data
    });
  },
  update(data: any) {
    return request({
      method: 'POST',
      url: '/api/ota/v1/version/scope/update',
      headers,
      data
    });
  },
  remove(id: any) {
    return request({
      method: 'get',
      url: `/api/ota/v1/version/delete/${id}`,
      headers
    });
  },
  getBatchList(model: any) {
    return request.get(`/api/ota/v1/batch/list/${model}`);
  },
  getModelList() {
    return request.get('/api/ota/v1/model/list');
  },
  getDevicesList(data: any) {
    return request.post('/api/device/v1/device/list', data);
  },
  getVersionScope(id: any) {
    return request({
      method: 'GET',
      url: `/api/ota/v1/version/scope/${id}`,
      headers
    });
  }
};

export default service;
