
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import service from '@/api/banner';
import serviceC from '@/api/consumerUser';
import { Table } from 'element-ui';
import BannerTable from './component/table.vue';
import OSS from 'ali-oss';
@Component({ components: { BannerTable } })
export default class BannerDetail extends Mixins(MixinTable) {
  @Ref('multipleTable') readonly $multipleTable!: Table;
  title = '新增Banner';
  queryForm = {
    desc: '',
    type: '', // 素材类型
    status: '', // 是否使用
    tagName: '' // 用户画像
  };

  params = {
    pageNum: 1,
    pageSize: 10
  };

  submitForm = {
    searchTime: [] as string[],
    searchParam: ''
  };

  list = [] as any;
  pageTotal = 0;
  status = '' as any;
  isWarning = false;
  isWarningDate = false;
  loading = false;
  warnText = '';
  warnDateText = '';
  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  selectionRow = {} as any;
  pickerOptions = {
    timeType: 'select',
    timeOptions: [
      { value: '00:00', label: '00:00' },
      { value: '08:00', label: '08:00' },
      { value: '12:00', label: '12:00' },
      { value: '14:00', label: '14:00' },
      { value: '18:00', label: '18:00' },
      { value: '20:00', label: '20:00' }
    ],
    disabledDate: (time: any) => {
      const curDate = new Date();
      const curDateYear = curDate.setFullYear(curDate.getFullYear() + 1); // 加1年
      const oneYear = new Date(curDateYear).getTime(); // 字符串转为时间戳

      if (curDate.getHours() >= 20) {
        return time.getTime() < Date.now() || time.getTime() > oneYear;
      }

      return time.getTime() < Date.now() - 1 * 8.64e7 || time.getTime() > oneYear;
    }
  };

  selectionChange(selection: any) {
    this.submitForm.searchParam = selection.number;
    if (this.submitForm.searchParam && this.submitForm.searchParam.length !== 14) {
      this.isWarning = true;
      this.warnText = '素材编号选择有误，请检查后重选';
    } else if (this.submitForm.searchParam && this.submitForm.searchParam.length === 14) {
      this.isWarning = false;
    }
  }

  async verifyName() {
    if (!this.submitForm.searchParam) {
      this.isWarning = true;
      this.warnText = '请填写或选择素材编号';
    } else {
      this.isWarning = false;
    }
  }

  startTimeBol = false;
  endTimeBol = false;
  verify() {
    if (!this.list.some((item: any) => item.number === this.submitForm.searchParam)) {
      this.isWarning = true;
      this.warnText = '素材编号选择有误，请检查后重选';
      return;
    } else {
      this.isWarning = false;
    }
    this.verifyName();
    this.selectionChange({ number: this.submitForm.searchParam });
    this.isWarningDate = !this.submitForm.searchTime?.length;
    const date = new Date();
    const currentDate = this.$moment(date).format('yyyy-MM-DD');
    if (currentDate === this.$moment(this.submitForm.searchTime[0]).format('yyyy-MM-DD') && date.getHours() >= 20) {
      this.isWarningDate = true;
      this.warnDateText = '今日已无可选时间节点,请重新选择';
      return;
    }
    const timeArr = ['00:00', '08:00', '12:00', '14:00', '18:00', '20:00'];
    this.startTimeBol = !timeArr.includes(this.$moment(this.submitForm.searchTime[0]).format('HH:mm'));
    this.endTimeBol = !timeArr.includes(this.$moment(this.submitForm.searchTime[1]).format('HH:mm'));
    if (!this.submitForm.searchTime?.length) {
      this.warnDateText = '请选择日期范围';
    }

    if (this.startTimeBol || this.endTimeBol) {
      this.isWarningDate = true;
      this.warnDateText = '请选择规定的时间节点';
    }
    if (!this.startTimeBol && !this.endTimeBol) {
      this.isWarningDate = false;
    }
  }

  async save() {
    this.verify();
    if (this.isWarning || this.isWarningDate || this.startTimeBol || this.endTimeBol) return;
    let message = '';
    const params = {
      startTime: this.$moment(this.submitForm.searchTime[0]).format('yyyy-MM-DD HH:mm:ss'), // 生效时间
      endTime: this.$moment(this.submitForm.searchTime[1]).format('yyyy-MM-DD HH:mm:ss'), // 失效时间
      smNumber: this.submitForm.searchParam // 素材编号
    };
    if (this.$route.params.bannerId) {
      const updateParams = {
        id: this.$route.params.bannerId,
        status: this.status,
        ...params
      };
      const {
        data: { msg }
      } = await service.update(updateParams);
      message = msg;
    } else {
      const {
        data: { msg }
      } = await service.created(params);
      message = msg;
    }
    if (message === '操作成功') {
      this.$notify({
        title: '保存成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.$router.push('/banner/index');
      this.submitForm.searchParam = '';
      this.submitForm.searchTime = [];
    }
  }

  handlePageNum(value: number) {
    this.params.pageNum = value;
    this.getList();
  }

  async getList() {
    this.loading = true;
    const { searchParam } = this.submitForm;
    const {
      data: { data }
    } = await service.getSCList(Object.assign({ searchParam }, this.params), this.queryForm);
    this.pageTotal = data.total;
    if (data.records.length) {
      this.list = data.records;
      this.list.forEach(async (item: any) => {
        if (item.status === 1) {
          item.status = '未使用';
        } else if (item.status === 2) {
          item.status = '已使用';
        }
        if (item.type === 1) {
          item.type = '日常';
        } else if (item.type === 2) {
          item.type = '活动';
        } else {
          item.type = '个性化';
        }
        if (!item.tagName) {
          item.tagName = '所有用户';
          item.tagColor = '';
        }
        if (this.submitForm.searchParam === item.number) {
          this.selectionRow = item;
        } else {
          this.selectionRow = {};
        }
        const res = await serviceC.getOSSToken('user/material/upload');
        if (res.data.data) {
          const client = new OSS({
            region: 'oss-cn-shanghai',
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: res.data.data.accessKeyId,
            accessKeySecret: res.data.data.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: res.data.data.securityToken,
            // 填写Bucket名称。
            bucket: res.data.data.bucketName
          });
          if (item.smUrl.includes('user/material/upload')) {
            const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + item.smUrl, {
              process: 'image/resize,w_200' // 设置图片处理参数。
            });
            item.smUrl = url;
          } else {
            item.smUrl = '';
          }
        }
      });
      this.isWarning = false;
    } else {
      this.isWarning = true;
      this.warnText = '素材编号选择有误，请检查后重选';
      this.list = [];
    }
    this.loading = false;
  }

  async activated() {
    this.params.pageNum = 1;
    if (this.$route.params.bannerId) {
      this.title = '编辑Banner';
      const {
        data: { data }
      } = await service.detail(this.$route.params.bannerId);

      this.submitForm.searchTime = [data.startTime, data.endTime];
      this.submitForm.searchParam = data.smNumber;
      this.status = data.status;
    } else {
      this.submitForm.searchParam = '';
    }
    this.getList();
  }

  get tableColumns() {
    const data: ColumnItem<materialItem>[] = [
      {
        prop: 'actions',
        label: 'actions',
        type: 'selection'
      },
      {
        slot: 'smUrl',
        prop: 'smUrl',
        label: '预览图',
        minWidth: 160
      },
      { slot: 'number', label: '素材编号', prop: 'number' },
      { label: '素材类型', prop: 'type' },
      { slot: 'status', label: '是否使用', prop: 'status' },
      { label: '创建日期', prop: 'createdOn' },
      { slot: 'tagName', label: '用户画像', prop: 'tagName' }
    ];
    return data;
  }
}
