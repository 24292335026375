
import { Component, Vue, Ref } from 'vue-property-decorator';
import serviceC from '@/api/consumerUser';
import service from '@/api/banner/material';
import { ElForm } from 'element-ui/types/form';
import YkWarn from '@/components/Yk_Warn/index.vue';
import OSS from 'ali-oss';

@Component({
  components: { YkWarn }
})
export default class MaterialDetail extends Vue {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  title = '新增素材';
  queryForm = {
    smUrl: '',
    type: '', // 素材类型1:日常 2:活动 3:个性
    tagName: '',
    tagColor: '',
    iosUrl: '',
    miniAppUrl: '',
    androidUrl: '',
    desc: ''
  } as any;

  loading = false;
  isWarnings = false;
  isWarn = false;
  isWarningType = false;
  isWarningTag = false;
  isWarningA = false;
  isWarningI = false;
  isWarningM = false;
  warnText = '';
  materialType = [] as any;

  srcList = [] as string[];

  tagList = [] as any;
  beforeUpload(file: any) {
    this.loading = true;
    const size = file.size / 1024 / 1024 < 5;
    const typeArr = ['image/jpeg', 'image/png', 'image/jpg'];
    const typeBol = typeArr.includes(file.type);

    if (!size) {
      this.isWarn = true;
      this.warnText = '图片大小不能超过5MB';
      this.loading = false;
      return false;
    } else if (!typeBol) {
      this.isWarn = true;
      this.warnText = '图片格式错误，支持jpg、png、jpeg格式';
      this.loading = false;
      return false;
    } else {
      const pixel = new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
          const valid = Math.floor(img.width / 3.5) === img.height || Math.round(img.width / 3.5) === img.height;
          valid ? resolve() : reject(new Error('图片比例不正确'));
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.isWarn = true;
          this.warnText = '图片比例不正确';
          this.loading = false;
          return Promise.reject(new Error('图片比例不正确'));
        }
      );
      return pixel;
    }
  }

  async uploadOk(val: any) {
    const {
      data: { data }
    } = await serviceC.getOSSToken('user/material/upload');
    if (data) {
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: data.securityToken,
        // 填写Bucket名称。
        bucket: data.bucketName
      });
      const url = client.signatureUrl(data.pathPrefix + val.file.name, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
      const pathPrefix = data.pathPrefix;
      const index = pathPrefix.indexOf('/');
      this.queryForm.smUrl = pathPrefix.substring(index) + val.file.name;
      // try {
      // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
      // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
      // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
      const result = await client.put(pathPrefix + val.file.name, val.file);
      if (result && this.srcList.length) {
        this.loading = false;
      }
    }
  }

  viewpic() {
    const Show = document.querySelector('.el-image__inner') as HTMLElement | null;
    Show!.click();
  }

  removepic(index: any) {
    this.srcList.splice(index, 1);
    this.loading = false;
  }

  changeType(val: any) {
    if (val !== 3) {
      this.queryForm.tagName = '';
      this.queryForm.tagColor = '';
    }
  }

  changeTag(val: any) {
    this.tagList.forEach((item: any) => {
      if (item.id === val) {
        this.queryForm.tagColor = item.color;
        this.queryForm.tagName = item.name;
      }
    });
  }

  async getTagList() {
    const queryForm = {
      likeName: null,
      orders: [
        {
          column: 'modified_on',
          asc: false
        }
      ]
    };
    const params = {
      pageNum: 1,
      pageSize: 10000
    };
    const {
      data: { data }
    } = await serviceC.getTagList(params, queryForm);
    this.tagList = data.records;
    this.tagList = this.tagList.filter((item: any) => item.type === 1);
    this.tagList.forEach((item: any) => {
      item.label = item.name;
      item.value = item.id;
    });
  }

  async getType() {
    const {
      data: { data }
    } = await service.getType();
    if (data) {
      this.materialType = data;
    } else {
      this.materialType = [];
    }
  }

  async created() {
    this.getTagList();
    this.getType();
  }

  async getDetail(id: any) {
    this.srcList = [];
    const {
      data: { data }
    } = await service.detail(id);
    Object.keys(this.queryForm).forEach((key: any) => {
      this.queryForm[key] = data[key];
    });

    if (+this.queryForm.type === 1) {
      this.queryForm.type = '1';
    } else if (+this.queryForm.type === 2) {
      this.queryForm.type = '2';
    } else {
      this.queryForm.type = '3';
    }
    const res = await serviceC.getOSSToken('user/material/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + this.queryForm.smUrl, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
    }
  }

  async activated() {
    if (this.$route.params.materialId) {
      this.getDetail(this.$route.params.materialId);
      this.title = '编辑素材';
    }
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
    }
  }

  verify() {
    let bol = false;
    this.queryForm.smUrl ? (this.isWarnings = false) : (this.isWarnings = true);
    this.queryForm.type ? (this.isWarningType = false) : (this.isWarningType = true);
    this.queryForm.androidUrl ? (this.isWarningA = false) : (this.isWarningA = true);
    this.queryForm.iosUrl ? (this.isWarningI = false) : (this.isWarningI = true);
    this.queryForm.miniAppUrl ? (this.isWarningM = false) : (this.isWarningM = true);
    bol = !this.isWarnings && !this.isWarningType && !this.isWarningA && !this.isWarningM && !this.isWarningI;
    if (this.queryForm.type === '3') {
      this.queryForm.tagName ? (this.isWarningTag = false) : (this.isWarningTag = true);
      bol =
        !this.isWarnings && !this.isWarningTag && !this.isWarningType && !this.isWarningA && !this.isWarningM && !this.isWarningI;
    }
    return bol;
  }

  async save() {
    if (!this.verify()) return;
    let message = '';
    if (!this.queryForm.smUrl) {
      this.isWarnings = true;
    } else {
      this.isWarnings = false;
      if (this.$route.params.materialId) {
        const params = {
          id: this.$route.params.materialId,
          ...this.queryForm
        };
        const {
          data: { msg }
        } = await service.update(params);
        message = msg;
      } else {
        const {
          data: { msg }
        } = await service.create(this.queryForm);
        message = msg;
      }
      if (message === '操作成功') {
        this.$notify({
          title: '保存成功',
          message: '',
          type: 'success',
          position: 'bottom-right'
        });
        this.$router.push('/banner/index/SC');
        this.$queryForm.resetFields();
        this.srcList = [];
      }
    }
  }
}
