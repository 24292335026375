import request from '@/utils/request';

const service = {
  query(params: any, data: any) {
    return request.post(
      `/api/cms/v1/banner/sm/page?pageNum=${params.pageNum}&pageSize=${params.pageSize}&searchParam=${params.searchParam}`,
      data
    );
  },
  create(data: any) {
    return request.post('/api/cms/v1/banner/sm/add', data);
  },
  update(data: any) {
    return request.post('/api/cms/v1/banner/sm/update', data);
  },
  remove(id: any) {
    return request.post(`/api/cms/v1/banner/sm/del/${id}`);
  },
  detail(id: any) {
    return request.get(`/api/cms/v1/banner/sm/getById/${id}`);
  },
  getType() {
    return request.get('/api/sys/v1/codes/sm_type');
  },
  getByNumber(id: any) {
    return request.get(`/api/cms/v1/banner/sm/getByNumber/${id}`);
  }
};
export default service;
