
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import Sortable from 'sortablejs';
import drawer from './components/drawer.vue';
import YkWarn from '@/components/Yk_Warn/index.vue';
import extendedDetail from './extendedDetail.vue';
import service from '@/api/questions';

interface ExtendedQuestion {
  ageMinCondition: null | number; // 年龄开始时间
  ageMaxCondition: null | number; // 年龄结束时间
  ask: string; // 题目名称
  childSize: null | any; // 题目名称
  classify: string | number; // 题目分类
  code: string; //
  createBy: string; //
  createOn: string; //
  extendedQuestions: null | any;
  genderCondition: string | number; // 性别条件 0 无 1 男 2 女
  id: string; //
  isDel: number; //
  isParent: number; //
  modifiedBy: string; //
  modifiedOn: string; //
  optionSize: number;
  optionType: string | number; // 选项类型 1单选 2多选
  optionalSize: number;
  optionalStatus: null | any;
  parentId: string; //
  parentQuestion: {} | any;
  questionGroupId: string; // 题组编号
  questionOptionDrafts: [] | any; // 题目选项
  questionOptionNames: [] | any; // 题目选项
  questionType: string; // 延展题设置
  seq: number; // 延展题设置
  skinTargetIds: [] | any; // 延展题设置
  [skinTargetNames: string]: any; // 肌肤问题类型
  // [questionOptionDraft: number]: any; // 肌肤问题类型
  questionOptionList: [] | any; // 肌肤问题类型
}

@Component({
  components: { drawer, YkWarn, extendedDetail }
})
export default class QuestionExtendedDetail extends Vue {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  title = '编辑题目';
  questionInfo = '题目信息';
  queryForm = {
    id: '',
    ask: '', // 题目名称
    questionGroupId: '', // 题组编号
    optionType: '单选', // 选项类型 1单选 2多选
    genderCondition: '全部', // 性别条件 0 无 1 男 2 女
    ageMinCondition: null, // 年龄开始时间
    ageMaxCondition: null, // 年龄结束时间
    classify: '生活建议', // 题目分类
    skinTargetNames: [] as any, // 肌肤问题类型
    questionOptionDrafts: [] as any, // 题目选项
    parentQuestion: '无', // 延展题设置
    extendedQuestions: [] as any, // 主题下的所有延展题数据
    questionType: '' as any,
    isParent: '' as any
  } as queryForm;

  formdata = {
    id: '',
    ask: '', // 题目名称
    questionGroupId: '', // 题组编号
    optionType: '单选', // 选项类型 1单选 2多选
    genderCondition: '全部', // 性别条件 0 无 1 男 2 女
    ageMinCondition: null, // 年龄开始时间
    ageMaxCondition: null, // 年龄结束时间
    classify: '生活建议', // 题目分类
    skinTargetIds: '' as any, // 肌肤问题类型
    questionOptionDrafts: '' as any, // 题目选项
    parentQuestion: '无'
  } as any;

  extendedQuestions = [] as ExtendedQuestion[]; // 主题下的所有延展题数据

  QuestionGroupList = [] as any; // 题组Id列表数据
  skinTypeList = [] as any; // 肌肤问题类型列表数据
  drawerList = [] as any; // 延展题设置的列表数据
  drawerQuestionInfo = {} as Record<string, any>; // 延展题设置的列表数据
  letterList = [] as any; // 26位字母列表数据
  questionContentL = [] as any; // 主题下的延展题的主题选项
  isShowDrawer = false;
  disabledQ = false;
  isDisabledAge = false;
  ConfirmFlag = false;
  questionTypeNum = 0; // 记录题组内题目数量
  isWarn = false; // 是否超过3道题目
  isWarningContent = false; // 题目选项是否为空
  isWarningSkinType = false; // 肌肤问题类型是否为空
  isWarningAsk = false; // 题目名称是否为空
  isWarningGroup = false; // 题组ID是否为空
  isDisabledGender = false; // 是否禁用性别
  genderCondition = ''; // 延展题性别条件--显示
  parentQuestionContent = { code: '', content: [] as string[] }; // 显示主题选项--延展题显示
  parentQuestion = {
    id: '',
    questionOptionDrafts: [] as any
  } as any; // 主题选项集合--延展题

  get extendedList() {
    if (this.queryForm.extendedQuestions.length) {
      this.questionInfo = '主题信息';
      this.extendedQuestions = [...(this.queryForm.extendedQuestions as ExtendedQuestion[])];
      this.extendedQuestions.forEach((item: any) => {
        if (item.classify === 2) {
          item.classify = '生活建议';
        } else if (item.classify === 1) {
          item.classify = '护肤建议';
        } else if (item.classify === 4) {
          item.classify = '医美/就医建议';
        }
        if (item.optionType === 1) {
          item.optionType = '单选';
        } else if (item.optionType === 2) {
          item.optionType = '多选';
        }
        if (item.genderCondition === 0) {
          item.genderCondition = '全部';
        } else if (item.genderCondition === 1) {
          item.genderCondition = '男';
        } else if (item.genderCondition === 2) {
          item.genderCondition = '女';
        }
        // 延展题的题目选项
        item.questionOptionDrafts.forEach((q: any, index: any) => {
          q.number = this.letterList[index];
        });
        item.isWarningContentItem = !item.questionOptionDrafts.every((q: any) => q.content);
        //  // 主题下的所有延展题的选择的主题选项
        item.parentQuestion.questionOptionDrafts = item.parentQuestion.questionOptionDrafts.filter((q: any, index: any) => {
          item.questioncode = item.parentQuestion.code;
          q.text = this.letterList[index] + ' ' + q.content;
          return q.jumpQuestionId === item.id;
        });
        if (item.parentQuestion) {
          item.parentQuestionText = '设置';
        }
        if (item.ask) {
          item.isWarningItemAsk = false;
        } else if (!item.ask) {
          item.isWarningItemAsk = true;
        }
      });
      this.$nextTick(() => {
        this.extendedQuestions.forEach((item: any) => {
          item.skinTargetNames = [] as string[];
          this.skinTypeList.forEach((skin: any) => {
            item.skinTargetIds.forEach((id: any) => {
              if (item.skinTargetNames !== null) {
                if (skin.id === id) {
                  item.skinTargetNames.push(skin.name);
                }
              }
            });
          });
        });
      });
      this.$nextTick(() => {
        for (let i = 0; i < this.extendedQuestions.length; i++) {
          this.initSortableQuesList('.caseAdd' + i + ' .el-table__body-wrapper tbody', i);
        }
      });

      return this.extendedQuestions;
    } else {
      this.questionInfo = '题目信息';
    }
  }

  @Watch('queryForm.parentQuestion')
  onChangeParentQ(newVal: any) {
    if (newVal === '无') {
      this.isDisabledGender = false;
      this.isDisabledAge = false;
    }
  }

  // 校验题组ids是否为空
  changeGroupId() {
    if (!this.queryForm.questionGroupId) {
      this.isWarningGroup = true;
      this.disabledQ = true;
    } else {
      this.isWarningGroup = false;
      this.disabledQ = false;
    }
    // if (this.queryForm.questionType === 3) {
    this.parentQuestionContent.code = '';
    this.parentQuestionContent.content = [];
    this.queryForm.parentQuestion = '无';
    this.getOtherInGroup();
    // }
  }

  // 肌肤问题类型修改
  handleSelect(value: any) {
    this.formdata.skinTargetIds = [] as any;
    this.skinTypeList.forEach((item: any) => {
      value.forEach((val: any) => {
        if (item.name === val) {
          this.formdata.skinTargetIds.push(item.id);
        }
      });
    });
  }

  // 校验题目名称是否为空
  verifyAsk() {
    if (!this.queryForm.ask) {
      this.isWarningAsk = true;
    } else {
      this.isWarningAsk = false;
    }
  }

  // 校验题目选项是否为空
  verifyContent() {
    const bol = this.queryForm.questionOptionDrafts.every((item: any) => item.content);
    if (!bol) {
      this.isWarningContent = true;
    } else {
      this.isWarningContent = false;
    }
  }

  // 主题下的延展题肌肤问题类型修改
  handleSelectQuesList(index: any) {
    this.extendedList![index].skinTargetIds = [];
    this.skinTypeList.forEach((item: any) => {
      this.extendedList![index].skinTargetNames.forEach((val: any) => {
        if (item.name === val) {
          this.extendedList![index].skinTargetIds.push(item.id);
        }
      });
    });
  }

  // 主题下的延展题修改是否属于问题成因
  changeCheck(edx: any, index: any, isProblemCauses: any) {
    let list = [...this.extendedList![edx].questionOptionDrafts];
    list = list.map((item: any, i: any) => {
      if (index === i) {
        item.isProblemCauses = isProblemCauses;
      }
      return item;
    });
    this.extendedList![edx].questionOptionDrafts = JSON.parse(JSON.stringify(list)); // 解决数据更新视图不更新
  }

  // 主题下的延展题添加选项
  handleAddQues(index: any, isAssociatedText: any) {
    if (!isAssociatedText) {
      let res = '' as any;
      if (this.extendedList![index].questionOptionDrafts.length) {
        this.letterList.filter((item: any, lindex: any) => {
          if (
            this.extendedList![index].questionOptionDrafts[this.extendedList![index].questionOptionDrafts.length - 1].number ===
            item
          ) {
            res = lindex + 1;
          }
        });
        if (res === this.letterList.length) {
          res = 0;
        }
      } else {
        res = 0;
      }
      const row = {
        number: this.letterList[res],
        content: '',
        isCauses: 0,
        actions: ''
      };
      if (this.extendedList!.length) {
        this.extendedList![index].questionOptionDrafts.push(row);
      }
    }
  }

  handleAdd() {
    let res = '' as any;
    if (this.queryForm.questionOptionDrafts.length) {
      this.letterList.filter((item: any, index: any) => {
        if (this.queryForm.questionOptionDrafts[this.queryForm.questionOptionDrafts.length - 1].number === item) {
          res = index + 1;
        }
      });
      if (res === this.letterList.length) {
        res = 0;
      }
    } else {
      res = 0;
    }

    const row = {
      number: this.letterList[res],
      content: '',
      isProblemCauses: 0,
      actions: ''
    };
    this.queryForm.questionOptionDrafts.push(row);
  }

  showDrawer(info: any) {
    if (info === 'add') {
      this.drawerQuestionInfo = {};
    } else {
      this.drawerQuestionInfo = this.parentQuestion;
    }

    if (!this.disabledQ) {
      this.isShowDrawer = true;
    }
  }

  saveDrawer(list: any) {
    this.parentQuestion.questionOptionDrafts = [];
    this.parentQuestionContent.content = [];
    list.forEach((item: any) => {
      item.questionOptionDrafts.forEach((i: any) => {
        if (i.status) {
          this.parentQuestion.id = item.id;
          this.parentQuestion.questionOptionDrafts.push({
            id: i.id,
            content: i.content
          });
          this.parentQuestionContent.code = item.code;
          this.parentQuestionContent.content.push(i.text);
          if (item.genderCondition === 0) {
            this.genderCondition = '全部';
          } else if (item.genderCondition === 1) {
            this.genderCondition = '男';
          } else if (item.genderCondition === 2) {
            this.genderCondition = '女';
          }
          this.queryForm.ageMaxCondition = item.ageMaxCondition;
          this.queryForm.ageMinCondition = item.ageMinCondition;
        }
      });
    });
    // // 是延展题的情况 需要获取主题设置，并且禁用
    this.queryForm.parentQuestion = '设置';
    this.queryForm.genderCondition = this.genderCondition;
    this.isDisabledGender = true;
    this.isDisabledAge = true;
    this.isShowDrawer = false;
  }

  isWarnContentList = [] as any;
  isWarnAskList = [] as any;
  isWarnSkinTypeList = [] as any;
  questionOptionDraftsList = [] as any;

  async save() {
    this.isWarnContentList = [];
    this.isWarnAskList = [];
    this.isWarnSkinTypeList = [];
    this.questionOptionDraftsList = [];
    this.queryForm.skinTargetIds = this.formdata.skinTargetIds;
    Object.keys(this.formdata).forEach((key: any) => {
      this.formdata[key] = this.queryForm[key as keyof queryForm];
      if (key === 'classify' && this.formdata[key] === '生活建议') {
        this.formdata[key] = 2;
      } else if (key === 'classify' && this.formdata[key] === '护肤建议') {
        this.formdata[key] = 1;
      } else if (key === 'classify' && this.formdata[key] === '医美/就医建议') {
        this.formdata[key] = 4;
      }
      if (key === 'genderCondition' && this.formdata[key] === '男') {
        this.formdata[key] = 1;
      } else if (key === 'genderCondition' && this.formdata[key] === '女') {
        this.formdata[key] = 2;
      } else if (key === 'genderCondition' && this.formdata[key] === '全部') {
        this.formdata[key] = 0;
      }
      if (key === 'optionType' && this.formdata[key] === '多选') {
        this.formdata[key] = 2;
      } else if (key === 'optionType' && this.formdata[key] === '单选') {
        this.formdata[key] = 1;
      }
      if (key === 'parentQuestion' && (this.formdata[key] === '无' || this.formdata[key] === '主题回答后，回答本题')) {
        this.formdata[key] = null;
      } else if (key === 'parentQuestion' && this.formdata[key] === '设置') {
        this.formdata[key] = { ...this.parentQuestion };
      }
      if (key === 'questionOptionDrafts' && this.formdata[key]) {
        this.formdata[key] = this.formdata[key].map((item: any) => {
          return {
            id: item.id ? item.id : null,
            content: item.content,
            isProblemCauses: item.isProblemCauses ? 1 : 0
          };
        });
      }
    });

    if (this.queryForm.isParent === 1) {
      const list = [
        'id',
        'ask',
        'questionGroupId',
        'optionType',
        'genderCondition',
        'ageMinCondition',
        'ageMaxCondition',
        'classify',
        'skinTargetIds',
        'questionOptionDrafts',
        'parentQuestion'
      ];
      const qList = ['id', 'content'];
      if (this.extendedList) {
        const extendedQuestions = JSON.parse(JSON.stringify(this.extendedList!));
        extendedQuestions!.forEach((item: any) => {
          item.isWarningSkinType = !item.skinTargetIds.length;
          item.isWarningContentItem = !item.questionOptionDrafts.length;
        });
        this.queryForm.extendedQuestions! = JSON.parse(JSON.stringify(extendedQuestions)); // 解决数据更新视图不更新
        const extendedList = JSON.parse(JSON.stringify(this.extendedList!));
        extendedList.forEach((item: any) => {
          Object.keys(item).forEach((key: any) => {
            if (!list.includes(key)) {
              delete item[key];
            }

            if (key === 'classify' && item[key] === '生活建议') {
              item[key] = 2;
            } else if (key === 'classify' && item[key] === '护肤建议') {
              item[key] = 1;
            } else if (key === 'classify' && item[key] === '医美/就医建议') {
              item[key] = 4;
            }
            if (key === 'genderCondition' && item[key] === '男') {
              item[key] = 1;
            } else if (key === 'genderCondition' && item[key] === '女') {
              item[key] = 2;
            } else if (key === 'genderCondition' && item[key] === '全部') {
              item[key] = 0;
            }
            if (key === 'optionType' && item[key] === '多选') {
              item[key] = 2;
            } else if (key === 'optionType' && item[key] === '单选') {
              item[key] = 1;
            }
            if (key === 'parentQuestion' && item[key] === '无') {
              item[key] = null;
            } else if (key === 'parentQuestion' && item[key]) {
              item[key].questionOptionDrafts.forEach((q: any) => {
                Object.keys(q).forEach((qkey: any) => {
                  if (!qList.includes(qkey)) {
                    delete q[qkey];
                  }
                });
              });
              item[key] = {
                id: item[key].id,
                questionOptionDrafts: [...item[key].questionOptionDrafts]
              };
            }
            if (key === 'questionOptionDrafts' && item[key]) {
              item[key] = item[key].map((q: any) => {
                return {
                  id: q.id ? q.id : null,
                  content: q.content,
                  isProblemCauses: q.isCauses ? 1 : 0
                };
              });
            }
          });

          this.isWarnContentList.push(item.questionOptionDrafts.every((q: any) => q.content));
          this.isWarnAskList.push(item.ask);
          this.isWarnSkinTypeList.push(item.skinTargetIds.length);
          this.questionOptionDraftsList.push(item.questionOptionDrafts.length);
        });
        this.formdata.extendedQuestions = extendedList;
      }
    }
    this.verifyAsk();
    this.verifyContent();
    this.isWarningSkinType = !this.queryForm.skinTargetIds.length;
    this.isWarningContent =
      !this.formdata.questionOptionDrafts.length || !this.formdata.questionOptionDrafts.every((item: any) => item.content);

    if (this.isWarningGroup || this.isWarningSkinType || this.isWarningAsk || this.isWarningContent) return;
    if (this.questionTypeNum >= 3 && this.formdata.parentQuestion === null) {
      this.isWarn = true;
      return;
    }
    // 当前编辑不是主题
    if (this.queryForm.isParent !== 1) {
      this.ConfirmFlag = true;
    }
    // 当前编辑是主题
    if (
      this.queryForm.isParent === 1 &&
      this.isWarnContentList.every((item: any) => item) &&
      this.isWarnAskList.every((item: any) => item) &&
      this.isWarnSkinTypeList.every((item: any) => item > 0) &&
      this.questionOptionDraftsList.every((item: any) => item > 0)
    ) {
      this.ConfirmFlag = true;
    }
  }

  async confirmDone() {
    const {
      data: { msg }
    } = await service.saveDraft(this.formdata);
    if (msg === '操作成功') {
      this.$notify({
        title: '保存成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.closeConfirm();
      this.$queryForm.resetFields();
      this.queryForm.ageMinCondition = null;
      this.queryForm.ageMaxCondition = null;
    }
  }

  closeConfirm() {
    this.ConfirmFlag = false;
    this.$router.go(-1);
  }

  async activated() {
    this.getQuestionGroup();
    this.getByDimension();
    this.questionTypeNum = 0;
    this.$queryForm.resetFields();
    this.queryForm.ageMinCondition = null;
    this.queryForm.ageMaxCondition = null;
    if (this.$route.params.id) {
      await this.getDraft(this.$route.params.id);
      this.getOtherInGroup();
    }
  }

  // 题组下拉列表
  async getQuestionGroup() {
    const {
      data: { data }
    } = await service.getQuestionGroup();
    this.QuestionGroupList = data;
  }

  dimension = 0;
  // 查询皮肤问题类型
  async getByDimension() {
    const {
      data: { data }
    } = await service.getByDimension({ dimension: this.dimension });
    this.skinTypeList = data;
  }

  otherInGroup = {
    questionGroupId: '',
    questionId: ''
  };

  // 查询题组内其他的题目
  async getOtherInGroup() {
    this.questionTypeNum = 0;
    this.otherInGroup.questionGroupId = this.queryForm.questionGroupId;
    this.otherInGroup.questionId = this.queryForm.id;
    const {
      data: { data }
    } = await service.getOtherInGroup(this.otherInGroup);

    this.drawerList = data;
    this.drawerList.forEach((item: any) => {
      if (item.questionOptionDrafts.length) {
        item.questionOptionDrafts.forEach((i: any, index: any) => {
          i.text = this.letterList[index] + ' ' + i.content;
        });
      }
      if (item.questionType !== 3) {
        this.questionTypeNum++;
      }
    });
  }

  // 查询题目
  async getDraft(id: any) {
    const {
      data: { data }
    } = await service.getDraft(id);
    const res = { ...data };
    Object.keys(res).forEach((key: any) => {
      if (key === 'classify' && res[key] === 2) {
        res[key] = '生活建议';
      } else if (key === 'classify' && res[key] === 1) {
        res[key] = '护肤建议';
      } else if (key === 'classify' && res[key] === 4) {
        res[key] = '医美/就医建议';
      }
      if (key === 'optionType' && res[key] === 1) {
        res[key] = '单选';
      } else if (key === 'optionType' && res[key] === 2) {
        res[key] = '多选';
      }
      if (key === 'genderCondition' && res[key] === 0) {
        res[key] = '全部';
      } else if (key === 'genderCondition' && res[key] === 1) {
        res[key] = '男';
      } else if (key === 'genderCondition' && res[key] === 2) {
        res[key] = '女';
      }
      if (key === 'skinTargetIds' && res[key] !== null) {
        this.skinTypeList.forEach((item: any) => {
          data[key].forEach((id: any) => {
            if (item.id === id) {
              this.queryForm.skinTargetNames.push(item.name);
            }
          });
        });
      }

      this.queryForm[key as keyof queryForm] = res[key] || this.queryForm[key as keyof queryForm];
    });
    this.queryForm.questionOptionDrafts.forEach((item: any, index: any) => {
      item.number = this.letterList[index];
      item.isProblemCauses = item.isProblemCauses === 1;
    });
    if (this.queryForm.extendedQuestions.length) {
      this.queryForm.extendedQuestions.forEach((item: any) => {
        item.questionOptionDrafts.forEach((q: any) => {
          q.isCauses = q.isProblemCauses === 1;
        });
      });
    }
    this.formdata.skinTargetIds = this.queryForm.skinTargetIds;
    this.parentQuestionContent.code = '';
    this.parentQuestionContent.content = [];
    if (data.questionType === 3) {
      this.parentQuestionContent.content = [];
      this.queryForm.parentQuestion = '设置';
      data.parentQuestion.questionOptionDrafts.forEach((item: any, index: any) => {
        if (data.id === item.jumpQuestionId) {
          this.parentQuestionContent.code = data.parentQuestion.code;
          item.text = this.letterList[index] + ' ' + item.content;
          this.parentQuestionContent.content.push(item.text);
          this.parentQuestion.id = data.parentQuestion.id;
          this.parentQuestion.questionOptionDrafts.push({ id: item.id, content: item.content });
        }
      });
    }
  }

  mounted() {
    this.initSortable();
  }

  // 创建sortable实例
  initSortableQuesList(docSelect: any, index: any) {
    const tbody = document.querySelector(docSelect);
    // 创建拖拽实例
    Sortable.create(tbody, {
      animation: 150, // 动画
      disabled: false, // 拖拽不可用? false 启用（刚刚渲染表格的时候起作用，后面不起作用）
      handle: '.moveQues', // 指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
      filter: '.disabled', // 指定不可拖动的类名（el-table中可通过row-class-number设置行的cAs）
      dragClass: 'dragClass', // 设置拖拽样式类名
      ghostClass: 'ghostClass', // 设置拖拽停靠样式类名
      chosenClass: 'chosenClass', // 设置选中样式类名
      // 开始拖动事件
      onStart: () => {
        // console.log('开始拖动moveQues ');
      },
      onEnd: (event: any) => {
        // 修改排序后的数据
        if (this.extendedList) {
          const tempTableData = [...this.extendedList![index].questionOptionDrafts]; // 先存一份临时变量表头数据
          let temp = '' as any;
          temp = tempTableData[event.oldIndex]; //
          tempTableData.splice(event.oldIndex, 1);
          tempTableData.splice(event.newIndex, 0, temp);
          this.extendedList![index].questionOptionDrafts = [];
          this.$nextTick(() => {
            this.extendedList![index].questionOptionDrafts = tempTableData.map((item: any, index: any) => {
              item.number = this.letterList[index];
              return item;
            });
          });
        }
      }
    });
  }

  // 创建sortable实例
  initSortable() {
    const ele = document.querySelector('.el-table__body > tbody');
    // 创建拖拽实例
    Sortable.create(ele, {
      animation: 150, // 动画
      disabled: false, // 拖拽不可用? false 启用（刚刚渲染表格的时候起作用，后面不起作用）
      handle: '.move', // 指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
      filter: '.disabled', // 指定不可拖动的类名（el-table中可通过row-class-number设置行的cAs）
      dragClass: 'dragClass', // 设置拖拽样式类名
      ghostClass: 'ghostClass', // 设置拖拽停靠样式类名
      chosenClass: 'chosenClass', // 设置选中样式类名
      // 开始拖动事件
      onStart: () => {
        // console.log('开始拖动');
      },
      onEnd: (event: any) => {
        const tempTableData = [...this.queryForm.questionOptionDrafts]; // 先存一份临时变量表头数据
        let temp = '' as any;
        temp = tempTableData[event.oldIndex]; //
        tempTableData.splice(event.oldIndex, 1);
        tempTableData.splice(event.newIndex, 0, temp);
        this.queryForm.questionOptionDrafts = [];
        this.$nextTick(() => {
          this.queryForm.questionOptionDrafts = tempTableData.map((item: any, index: any) => {
            item.number = this.letterList[index];
            return item;
          });
        });
      }
    });
  }

  // 删除行
  handleDelete(index: any) {
    this.queryForm.questionOptionDrafts.splice(index, 1);
  }

  handleDeleteQesList(edx: any, index: any, isAssociatedText: any) {
    if (!isAssociatedText) {
      this.extendedList![edx].questionOptionDrafts.splice(index, 1);
    }
  }

  created() {
    for (let i = 65; i <= 90; i++) {
      this.letterList.push(String.fromCharCode(i));
    }
  }
}
