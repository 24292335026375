
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
@Component({ components: {} })
export default class QuestionDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  drawer!: boolean;

  @Prop({ type: [Object], default: () => ({}) })
  appendReportContent?: Record<string, any>;

  @Prop({ type: String, required: true })
  title!: '';

  @Prop({ type: [Array], required: true })
  letterList!: Record<string, any>[];

  content = {} as any;
  isDrawer = false;
  @Watch('drawer')
  onChangeDrawer(newVal: boolean) {
    this.isDrawer = newVal;
  }

  @Watch('appendReportContent', { deep: true })
  onChangeId(newVal: boolean) {
    if (newVal) {
      Object.keys(this.appendReportContent!).forEach((key: any) => {
        this.content[key] = this.appendReportContent![key];
      });
      Object.keys(this.content).forEach((key: any) => {
        if (key === 'appendReportTextContents') {
          this.content[key].forEach((item: any) => {
            if (item.questionAndOptions !== null) {
              item.questionAndOptions.forEach((Options: any) => {
                Options.questionOption = Options.questionOptionSeqs.map((seq: any) => {
                  seq = this.letterList[seq - 1];
                  return seq;
                });

                Options.option = Options.questionOption.join('/');
              });
            }
          });
        }
      });
    }
  }
}
