
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/reportFile';
@Component({ components: {} })
export default class QuestionDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  drawer!: boolean;

  @Prop({ type: [Object], default: () => ({}) })
  details?: Record<string, any>;

  @Prop({ type: [Object], default: () => ({}) })
  textInfo?: Record<string, any>;

  @Prop({ type: [Object], default: () => ({}) })
  questionParams?: Record<string, any>;

  @Prop({ type: [Array], required: true })
  letterList!: Record<string, any>[];

  @Prop({ type: String, required: true })
  title!: '';

  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    questionName: null // 问题名称
  };

  rowkeys = [] as any;
  isWarning = false;
  list = [] as questionList[];
  description = '' as any;
  codeList = [] as any; // 选中选项数据
  pitchList = [] as any; // 处理后的选中选项数据 页面
  oppointQuestion = [] as any; // 处理后的选中选项数据 接口
  saveOppointQuestion = [] as any; // 处理后的选中选项数据 接口
  isDrawer = false;
  params = {} as any;
  appointParams = {
    textId: '', // 文案ID
    eventType: '', // 0 补充 1追加 2替换
    appedReportTextId: '', // 追加文案ID
    description: '', // 文案内容
    appointQuestion: [] // 题目
  } as appointParams; // 指定问题接口参数

  pitchOption = [] as any; // 记录已勾选过的id
  @Watch('drawer')
  onChangeDrawer(newVal: boolean) {
    this.isDrawer = newVal;
    this.rowkeys = [];
    this.description = this.textInfo!.description;
    this.queryForm.questionName = null;
    this.params.codeOrAskLike = '';
  }

  @Watch('textInfo', { deep: true })
  onChangetextInfo(newVal: any) {
    this.pitchList = [] as any;
    this.oppointQuestion = [] as any;
    if (newVal) {
      if (this.textInfo && this.textInfo.options !== null && this.textInfo.options !== undefined) {
        this.textInfo!.options.forEach((item: any) => {
          this.pitchList.push({
            code: item.code,
            optionNames: item.optionNames
          });
          this.oppointQuestion.push({
            questionId: item.questionId,
            optionIds: item.optionIds
          });
        });
      }
      if (this.pitchList.length) {
        this.isWarning = false;
      }
    }
  }

  @Watch('questionParams', { deep: true })
  onChangeParams(newVal: boolean) {
    if (newVal) {
      this.getQuestion(newVal);
    }
  }

  @Watch('list', { deep: true })
  onChangeId(newVal: boolean) {
    this.codeList = [];
    if (newVal) {
      this.list.forEach((item: any) => {
        item.questionOptionDrafts.forEach((q: any) => {
          if (q.status) {
            this.codeList.push({
              code: item.code,
              optionId: q.id, // 选项id
              questionId: item.id, // 题目id
              option: q.letter,
              seqs: q.seq
            });
          }
        });
      });
    }
  }

  @Watch('codeList', { deep: true })
  onChangeCode(newVal: boolean) {
    if (newVal) {
      this.oppointQuestion = [] as any;
      this.pitchList = [] as any;
      this.saveOppointQuestion = [] as any;
      this.codeList.forEach((item: any) => {
        const newOptions = this.oppointQuestion.find((i: any) => i.questionId === item.questionId);
        if (!newOptions) {
          this.oppointQuestion.push({ questionId: item.questionId, optionIds: [item.optionId] });
        } else if (newOptions) {
          newOptions.optionIds.push(item.optionId);
        }
        const saveOptions = this.saveOppointQuestion.find((i: any) => i.questionId === item.questionId);
        if (!saveOptions) {
          this.saveOppointQuestion.push({
            questionId: item.questionId,
            code: item.code,
            optionIds: [item.optionId],
            questionOptionSeqs: [item.seqs]
          });
        } else if (saveOptions) {
          saveOptions.questionOptionSeqs.push(item.seqs);
          saveOptions.optionIds.push(item.optionId);
        }

        const newCode = this.pitchList.find((i: any) => i.code === item.code);
        if (!newCode) {
          this.pitchList.push({ code: item.code, optionNames: [item.option] });
        } else if (newCode) {
          newCode.optionNames.push(item.option);
        }
      });
    }
  }

  changeCheckList(e: any, row: any, index: any) {
    // 修改选中项
    this.list.forEach((item: any) => {
      item.questionOptionDrafts.forEach((q: any) => {
        if (q.id === row.id) {
          q.status = e;
        }
        if (q.status) {
          this.pitchOption.push(q.id);
        }
        if (!q.status) {
          this.pitchOption = this.pitchOption.filter((option: any) => option !== q.id);
        }
      });
    });

    this.$set(this.list, index!, this.list[index!]);
    this.rowkeys[index] = true;
    this.pitchOption = [...new Set(this.pitchOption)];
  }

  onQuery() {
    this.params = this.questionParams;
    this.params.codeOrAskLike = this.queryForm.questionName;
    this.getQuestion(this.params);
  }

  async getQuestion(params: any) {
    const {
      data: { data }
    } = await service.changeReportQuestion(params);
    this.list = data;
    this.list.forEach((item: any) => {
      item.questionOptionDrafts.forEach((q: any, index: any) => {
        q.optionStatus = q.optionalStatus;
        this.pitchOption.forEach((option: any) => {
          if (option === q.id) {
            q.optionStatus = -1;
          }
        });
        if (this.oppointQuestion.length) {
          this.oppointQuestion.forEach((options: any) => {
            if (q.questionId === options.questionId) {
              options.optionIds.forEach((ids: any) => {
                if (ids === q.id) {
                  q.optionStatus = 0;
                  q.status = true;
                }
              });
            }
          });
        }

        q.letter = this.letterList[index];
        q.text = this.letterList[index] + ' ' + q.content;
      });
    });
  }

  async save() {
    this.appointParams.textId = this.textInfo!.textId;
    this.appointParams.eventType = this.textInfo!.eventType;
    this.appointParams.appedReportTextId = this.textInfo!.appedReportTextId;
    this.appointParams.description = this.description;
    this.appointParams.appointQuestion = this.oppointQuestion.length ? this.oppointQuestion : null;
    const saveData = { ...this.appointParams } as any;
    saveData.textNum = this.textInfo!.textNum;
    saveData.appointQuestion = this.saveOppointQuestion;
    this.isWarning = !this.saveOppointQuestion.length;

    if (!this.saveOppointQuestion.length) return;

    if (!this.textInfo!.appedReportTextId) {
      this.$emit('save', saveData);
    } else {
      const {
        data: { msg }
      } = await service.changeQuestionAppoint(this.appointParams);

      if (msg === '操作成功') {
        this.$emit('save', saveData);
      }
    }
  }

  activated() {
    this.isWarning = false;
    this.pitchOption = [];
  }
}
