import { render, staticRenderFns } from "./index.vue?vue&type=template&id=71c3b336&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=71c3b336&prod&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&id=71c3b336&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c3b336",
  null
  
)

export default component.exports