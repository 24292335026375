import request from '@/utils/request';

const service = {
  query(params: any, data: any) {
    return request.post(`/api/user/v1/user/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`, data);
  },
  remove(id: any) {
    return request.post(`/api/user/v1/user/del?ids=${id}`);
  },
  detail(id: any) {
    return request.post(`/api/user/v1/user/${id}`);
  },
  updateUserRole(data: any) {
    return request.post('/api/user/v1/user/saveRoles', data);
  },
  getTreeDep() {
    return request.post('api/user/v1/department/treeList');
  },
  getAllRole() {
    return request({
      method: 'POST',
      url: '/api/user/v1/roles/all',
      headers: {
        'accept-language': 'zh-Hans-CN',
        from: 1
      }
    });
  },
  userCanDelCheck(id: any) {
    return request({
      method: 'POST',
      url: `/api/user/v1/user/canDelCheck?id=${id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default service;
