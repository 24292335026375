
import { Component, Vue, Watch } from 'vue-property-decorator';
import serviceC from '@/api/consumerUser';
import service from '@/api/banner';
import OSS from 'ali-oss';
import drawer from './component/drawer.vue';

interface BannerItem {
  id: number;
  baNumber: string;
  description: string;
  startTime: string;
  endTime: string;
  tagName: string;
  tagColor: number;
  status: 0 | 1 | 2;
  smType: 1 | 2 | 3;
}

interface BannerItemCalendar extends BannerItem {
  width: number;
  isPassed?: boolean;
}

const timeOptions = [
  { value: '00:00', label: '00:00' },
  { value: '08:00', label: '08:00' },
  { value: '12:00', label: '12:00' },
  { value: '14:00', label: '14:00' },
  { value: '18:00', label: '18:00' },
  { value: '20:00', label: '20:00' }
];

const optsWeek = '一二三四五六日'.split('');

@Component({
  components: { drawer }
})
export default class PageCalendar extends Vue {
  queryForm = {
    searchStartTime: null,
    searchEndTime: null,
    tagName: null,
    status: null
  } as any;

  isEmpty = false;
  @Watch('tagName')
  onTagNameChange(newVal: any) {
    if (newVal === '所有用户') {
      this.userTag = [];
      this.queryForm.tagName = null;
      this.getList();
    } else if (newVal === '指定用户画像') {
      this.getList();
    }
  }

  @Watch('value')
  onValueChange(newVal: any) {
    const currentMon = new Date();
    if (
      newVal &&
      this.$moment(currentMon).format('yyyy-MM-DD').split('-')[1] !== this.$moment(newVal).format('yyyy-MM-DD').split('-')[1]
    ) {
      const firstDate = newVal;
      firstDate.setDate(1);
      firstDate.setHours(0, 0, 0);

      const endDate = new Date(firstDate);
      endDate.setMonth(firstDate.getMonth() + 1);
      endDate.setDate(0);
      endDate.setHours(23, 59, 59);
      this.queryForm.searchStartTime = this.$moment(firstDate).format('yyyy-MM-DD HH:mm:ss');
      this.queryForm.searchEndTime = this.$moment(endDate).format('yyyy-MM-DD HH:mm:ss');
      this.getList();
    }
  }

  isShowDrawer = false;
  BannerDetail = {} as any;
  srcList = [] as any;
  userTag = [] as any;
  isSelected = false;
  calendar: BannerItem[] = [];

  tagName = '所有用户';
  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  tagList = [] as any;

  value = new Date();
  currentDate = new Date();
  currentDay = new Date();
  optsWeek = optsWeek;
  get currentDayString() {
    return this.$moment(this.currentDate).format('YYYY-MM-DD');
  }

  get currentMonthString() {
    return this.$moment(this.currentDay).format('YYYY年MM月');
  }

  get monthFistDay() {
    return this.$moment(this.currentDay).clone().date(1).format('YYYY-MM-DD');
  }

  onDateChange(str: string) {
    this.currentDay = this.$moment(str).toDate();
  }

  onMonthChange(offset: 1 | -1) {
    this.currentDay = this.$moment(this.currentDay).clone().add(offset, 'month').startOf('month').toDate();

    this.queryForm.searchEndTime = this.$moment(this.monthFistDay).endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.queryForm.searchStartTime = this.$moment(this.monthFistDay).format('YYYY-MM-DD HH:mm:ss');
    this.getList();
  }

  get calendarData() {
    if (this.calendarWeekList.length === 0 || this.calendar.length === 0) return [];

    const firstDay = this.$moment(this.calendarWeekList[0][0].data.day);
    const lastDay = this.$moment(this.calendarWeekList[this.calendarWeekList.length - 1][6].data.day);

    const timeShorts = timeOptions.map(item => +item.value.slice(0, 2));
    // 在天为单位内部拆分，必须有0点
    if (timeShorts[0] !== 0) timeShorts.unshift(0);

    // 将 banner 数据拆分到每天的6个时段中
    /**
     * dayMap: {
     *   '2022-02-02': {
     *     '0': [Banner1,Banner2,Banner3], // 0点-8点 时段 banner 的显示顺序，优先级从高到低
     *     '8': [Banner2,Banner3,Banner4],
     *     '12': [Banner2,Banner4,Banner5],
     *     '14': [Banner2,Banner4,Banner5],
     *     '18': [Banner2,Banner4,Banner5],
     *     '20': [Banner2,Banner4,Banner5],
     *   },
     *   '2022-02-03': {
     *     '0': [Banner2,Banner4,Banner5],
     *     '8': [Banner2,Banner4,Banner5],
     *     '12': [Banner2,Banner4,Banner5],
     *     '14': [Banner2,Banner4,Banner5],
     *     '18': [Banner2,Banner4,Banner5],
     *     '20': [Banner2,Banner4,Banner5],
     *   },
     * }
     */
    const dayMap: Record<string, Record<string, BannerItem[]>> = {};
    this.calendar.forEach(bItem => {
      const startDate = this.$moment.max(this.$moment(bItem.startTime), firstDay);
      const endDate = this.$moment.min(this.$moment(bItem.endTime), lastDay);

      const dayOffset = Math.ceil(endDate.diff(startDate, 'days', true)) - 1;
      let dayOffsetIndex = 0;
      // 如果 banner 连续好几天，那么中间几天就不必比较日期
      // 基本上第一天和最后一天需要比较
      let compareSafe = false;
      while (compareSafe || startDate.isBefore(endDate)) {
        const dayStr = startDate.format('YYYY-MM-DD');
        const dayItem = dayMap[dayStr] || {};
        dayMap[dayStr] = dayItem;

        if (compareSafe) {
          timeShorts.forEach(time => {
            const timeList = dayItem[time] || [];
            dayItem[time] = timeList;
            timeList.push(bItem);
          });
        } else {
          const timeIndex = timeShorts.findIndex(time => time === startDate.hour());
          const timeShortsPart = ~timeIndex ? timeShorts.slice(timeIndex) : [];
          let time = timeShortsPart.pop();
          while (time !== undefined) {
            startDate.hour(time);
            if (startDate.isBefore(endDate)) {
              const timeList = dayItem[time] || [];
              dayItem[time] = timeList;
              timeList.push(bItem);
            }
            time = timeShortsPart.pop();
          }
          startDate.hour(0);
        }

        dayOffsetIndex += 1;
        compareSafe = dayOffsetIndex > 0 && dayOffsetIndex < dayOffset;
        startDate.add(1, 'day');
      }
    });

    const timeMap = timeShorts.map((time, index, self) => ({ time, offset: (self[index + 1] ?? 24) - time }));
    /**
     * 返回数据的结构
     * [
     *   [ // 第一周 banner 的显示情况
     *     [Banner1,Banner2], // 第一行
     *     [Banner2,Banner3,Banner4], // 第二行
     *     [Banner3,Banner4,Banner5] // 第三行
     *   ],
     *   [ // 第二周 banner 的显示情况
     *     [Banner...], // 第一行
     *     [Banner...], // 第二行
     *     [Banner...] // 第三行
     *   ],
     * ]
     */
    return this.calendarWeekList.map(week => {
      const lineList: BannerItemCalendar[][] = [[], [], []];

      week.forEach(({ data: { day } }) => {
        const dayItem = dayMap[day];
        const isPassed = day < this.currentDayString;
        const isToday = day === this.currentDayString;
        timeMap.forEach(timeItem => {
          const timeList = dayItem?.[timeItem.time] ?? [];

          lineList.forEach((bList, lIndex) => {
            const lastBItem = bList[bList.length - 1];
            const currentBItem = timeList[lIndex];

            if (
              (isToday && timeItem.time === 0) ||
              lastBItem?.id !== currentBItem?.id ||
              (currentBItem ?? lastBItem) === undefined
            ) {
              bList.push({ ...(currentBItem ?? {}), width: timeItem.offset, isPassed });
            } else {
              lastBItem.width += timeItem.offset;
            }
          });
        });
      });
      return lineList;
    });
  }

  get calendarWeekList() {
    const weekList: Record<string, any>[][] = [];
    const activeDay = this.$moment(this.monthFistDay);
    const dayStartMonth = activeDay.clone().startOf('month');
    const firstDay = dayStartMonth.clone().startOf('isoWeek');
    const dayEndMonth = activeDay.clone().endOf('month');
    const lastDay = dayEndMonth.clone().endOf('isoWeek');
    const weekday = firstDay.clone();
    let currentWeek: any[] = [];

    while (weekday.isSameOrBefore(lastDay)) {
      if (weekday.weekday() === 1) {
        currentWeek = [];
        weekList.push(currentWeek);
      }

      currentWeek.push({
        data: {
          day: weekday.format('YYYY-MM-DD'),
          isSelected: false,
          type: weekday.isBefore(dayStartMonth) ? 'prev-month' : weekday.isAfter(dayEndMonth) ? 'next-month' : 'current-month'
        }
      });

      weekday.add('day', 1);
    }

    return weekList;
  }

  async ShowDrawer(row: any) {
    this.srcList = [];
    this.isShowDrawer = true;
    const {
      data: { data }
    } = await service.getByBaNumber(row.baNumber);
    this.BannerDetail = { ...data };
    if (this.BannerDetail.baStatus === 0) {
      this.BannerDetail.baStatus = '待上线';
    } else if (this.BannerDetail.baStatus === 1) {
      this.BannerDetail.baStatus = '已上线';
    } else {
      this.BannerDetail.baStatus = '已下线';
    }
    if (this.BannerDetail.type === 1) {
      this.BannerDetail.type = '日常';
    } else if (this.BannerDetail.type === 2) {
      this.BannerDetail.type = '活动';
    } else {
      this.BannerDetail.type = '个性化';
    }
    if (this.BannerDetail.smStatus === 1) {
      this.BannerDetail.smStatus = '未使用';
    } else if (this.BannerDetail.smStatus === 2) {
      this.BannerDetail.smStatus = '已使用';
    }

    if (!this.BannerDetail.tagName) {
      this.BannerDetail.tagName = '所有用户';
      this.BannerDetail.tagColor = '';
    }
    const res = await serviceC.getOSSToken('user/material/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + this.BannerDetail.smUrl, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
    }
  }

  handleSelect(value: any) {
    if (value.length && value.length > 1) {
      this.queryForm.tagName = value.toString();
    } else if (value.length && value.length === 1) {
      this.queryForm.tagName = value[0];
    } else {
      this.queryForm.tagName = null;
    }
    this.getList();
  }

  getDefaultTime() {
    const firstDate = new Date();
    firstDate.setDate(1);
    firstDate.setHours(0, 0, 0);

    const endDate = new Date(firstDate);
    endDate.setMonth(firstDate.getMonth() + 1);
    endDate.setDate(0);
    endDate.setHours(23, 59, 59);

    this.queryForm.searchStartTime = this.$moment(firstDate).format('yyyy-MM-DD HH:mm:ss');
    this.queryForm.searchEndTime = this.$moment(endDate).format('yyyy-MM-DD HH:mm:ss');
  }

  async getTagList() {
    const queryForm = {
      likeName: null,
      orders: [
        {
          column: 'modified_on',
          asc: false
        }
      ]
    };
    const params = {
      pageNum: 1,
      pageSize: 10000
    };
    const {
      data: { data }
    } = await serviceC.getTagList(params, queryForm);
    this.tagList = data.records;
    this.tagList = this.tagList.filter((item: any) => item.type === 1);
    this.tagList.forEach((item: any) => {
      item.label = item.name;
      item.value = item.id;
    });
  }

  async getList() {
    service
      .getCalendar(this.queryForm)
      .then((data: any) => {
        this.calendar = data.data.data;
        this.calendar.sort(function (a: any, b: any) {
          const order = [3, 2, 1];
          return order.indexOf(a.smType) - order.indexOf(b.smType);
        });
        this.calendar.sort(function (a: any, b: any) {
          return a.sort - b.sort;
        });
        this.isEmpty = false;
      })
      .catch(() => {
        this.isEmpty = true;
      });
  }

  activated() {
    this.tagName = '所有用户';
    this.currentDate = this.$moment(this.currentDate).format('yyyy-MM-DD') as any;
    this.currentDay = new Date();
    this.value = new Date();
    this.getDefaultTime();
    this.getList();
    this.getTagList();
  }
}
