
import { Component, Vue, Ref } from 'vue-property-decorator';
import service from '@/api/consumerTag';
import { ElForm } from 'element-ui/types/form';
@Component({ components: {} })
export default class TagDetail extends Vue {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  title = '新增画像';
  queryForm = {
    name: '', // 角色名称
    type: 2, // 类型 1系统 2人工
    description: '' // 说明
  };

  isWarning = false;
  warnText = '';
  regEn = /[`~!@#$%^&*()_+<>?:"{},./;'[\]]/im;
  regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

  async verifyName() {
    if (this.queryForm.name) {
      const {
        data: { data }
      } = await service.existed({
        name: this.queryForm.name,
        id: this.$route.params?.portraitId ? this.$route.params?.portraitId : ''
      });
      this.isWarning = true;
      if (data) {
        this.warnText = '该画像名称已存在, 请修改后重新输入';
      } else if (
        this.queryForm.name.length < 2 ||
        this.queryForm.name.length > 10 ||
        this.regEn.test(`${this.queryForm.name}`) ||
        this.regCn.test(`${this.queryForm.name}`)
      ) {
        this.warnText = '请使用2-10位汉字、数字或英文';
      } else {
        this.isWarning = false;
      }
    } else {
      this.warnText = '请填写必填项';
      this.isWarning = true;
    }
  }

  async created() {
    if (this.$route.params.portraitId) {
      this.title = '编辑画像';
      const {
        data: { data }
      } = await service.detail(this.$route.params.portraitId);
      this.queryForm = { ...data };
    }
  }

  async save() {
    let message = '';
    if (this.queryForm.name && !this.isWarning) {
      if (this.$route.params?.portraitId) {
        const {
          data: { msg }
        } = await service.update({ ...this.queryForm, id: this.$route.params.portraitId });
        message = msg;
      } else {
        const {
          data: { msg }
        } = await service.create(this.queryForm);
        message = msg;
      }
      if (message === '操作成功') {
        this.$notify({
          title: '保存成功',
          message: '',
          type: 'success',
          position: 'bottom-right'
        });
        this.$router.go(-1);
        this.$queryForm.resetFields();
      }
    } else {
      this.isWarning = true;
      this.warnText = '请填写必填项';
    }
  }

  activated() {
    this.isWarning = false;
  }
}
