
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class UserDialog extends Vue {
  @Prop({}) dialogFlag!: false;
  @Prop({ type: String, required: false, default: '画像设置' })
  title!: string;

  @Prop({ type: Array, required: true })
  tagList!: [];

  @Prop({ type: Array, required: false })
  tags!: [];

  checkList = [] as any;
  @Watch('tags', { immediate: true })
  onChangetags(newVal: any) {
    if (newVal) {
      newVal.forEach((item: any) => {
        this.checkList.push(item.name);
      });
    }
  }

  async save() {
    const list = [...new Set(this.checkList)];
    this.$emit('done', list);
  }

  handleClose() {
    this.$emit('close');
    this.checkList = [];
    this.tags.forEach((item: any) => {
      this.checkList.push(item.name);
    });
  }
}
