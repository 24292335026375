
import { Component, Vue, Watch } from 'vue-property-decorator';
import service from '@/api/reportFile';
import cardRT from './components/cardRT.vue';
import cardRB from './components/cardRB.vue';
import { getSkinType, removeSkinType } from '@/utils/cookies';
import { NavigationGuardNext, Route } from 'vue-router';
@Component({
  components: { cardRT, cardRB }
})
export default class PageReportFile extends Vue {
  radio = '首次报告';
  commandName = '全部';
  planTitle = '解决方案';
  questionTypeList = [] as any;
  changeSkinId = ['8', '9', '10', '11', '12', '14'];
  firstSkinId = ['13', '15', '16'];
  skinQuestionType = '';
  skinTargetId = '';
  apply = 1; // 详情因素需要  0 首次报告 1变化报告
  type = 1; // 获取面部类型列表 0全部 1变化报告 2 首次报告
  skinTagInfo = {
    targetId: '1' as any,
    apply: 1
  };

  mode = '';
  @Watch('skinQuestionType')
  onChangeSkinId(newVal: any) {
    if (newVal) {
      const res = this.questionTypeList.filter((item: any) => item.name === newVal);
      if (res.length) {
        this.skinTagInfo.targetId = res[0].id;
      }
    }
  }

  commandEvent(command: string) {
    if (command === 'first') {
      this.commandName = '首次报告';
      this.mode = '首次报告';
      this.planTitle = '首次报告解决方案';
      this.type = 2;
      this.apply = 0;
    } else if (command === 'contrast') {
      this.commandName = '变化报告';
      this.mode = '变化报告';
      this.planTitle = '变化报告解决方案';
      this.type = 3;
      this.apply = 1;
    } else if (command === 'all') {
      this.commandName = '全部';
      this.mode = '全部';
      this.planTitle = '解决方案';
      this.type = 1;
    }
    this.getByType();
    this.skinQuestionType = this.questionTypeList[0].name;
  }

  changeMode(mode: any, targetId: any) {
    this.commandName = mode;
    if (mode === '全部') {
      this.type = 1;
    } else if (mode === '变化报告') {
      this.type = 3;
    } else if (mode === '首次报告') {
      this.type = 2;
    }
    this.getByType();
    this.questionTypeList.forEach((item: any) => {
      if (item.id === targetId) {
        this.skinQuestionType = item.name;
      }
    });
  }

  async getByType() {
    const {
      data: { data }
    } = await service.getByType(this.type);
    this.questionTypeList = data;
  }

  async activated() {
    if (getSkinType()) {
      // console.log('JSON.parse(getSkinType()!)', getSkinType()!.mode);
      this.skinTagInfo.targetId = JSON.parse(getSkinType()!).targetId;
      this.skinQuestionType = JSON.parse(getSkinType()!).targetName;
      this.commandName = JSON.parse(getSkinType()!).mode ? JSON.parse(getSkinType()!).mode : '全部';
    } else {
      this.skinTagInfo.targetId = 1;
      this.skinQuestionType = '痘痘';
      this.commandName = '全部';
    }
    if (this.commandName === '全部') {
      this.type = 1;
    } else if (this.commandName === '变化报告') {
      this.type = 3;
    } else if (this.commandName === '首次报告') {
      this.type = 2;
    }
    await this.getByType();
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    if (!from.path.includes('reportFile')) {
      removeSkinType();
    }
    next();
  }
}
