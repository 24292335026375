
import { Component, Mixins, Watch, Prop, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import { UserModule } from '@/store/modules/user';
import service from '@/api/role';
import { getUserPath } from '@/utils/cookies';
import { ApiGetUserinfo } from '@/api/login/index'; // 导入接口
@Component({ components: {} })
export default class RoleDetail extends Mixins(MixinTable) {
  @Prop({}) rolePer?: Record<string, any>;
  @Ref('tree') readonly $refTree!: any;
  Form = {
    name: '',
    menus: ''
  };

  title = '新增角色';
  initRole = [] as any;
  isNameWarnings = false;
  isMenuWarnings = false;
  nameWarnings = '';
  menuWarnings = '';
  menus = [] as any;
  initMenus = [] as any;
  // 默认展开的id组
  defaultShowNodes = [] as any;
  defaultProps = {
    children: 'children',
    label: 'label'
  };

  regEn = /[`~!@#$%^&*()_+<>?:"{},./;'[\]]/im;
  regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

  get userInfo() {
    return UserModule.useData ?? ({} as any);
  }

  @Watch('rolePer', { deep: true })
  async onroleIdChange(val: any) {
    if (val) {
      this.Form.name = val.name;
      this.$refTree.setCheckedKeys(val.permissionIds, true);
      this.title = '编辑角色';
    } else {
      this.Form.name = '';
      this.$refTree.setCheckedKeys([], false);
      this.title = '新增角色';
    }
  }

  @Watch('Form.menus', { deep: true })
  async onFormMenusChange(val: any) {
    this.$refTree.filter(val);
  }

  filterNode(value: any, data: any) {
    if (!value) return true;
    return data.label.indexOf(value) !== -1;
  }

  handleCheckChange() {
    // 获取所有选中的子节点 start
    const childNode = this.$refTree.getCheckedKeys();
    // 获取半选的节点
    const halfSelected = this.$refTree.getHalfCheckedKeys();
    this.menus = [...childNode, ...halfSelected];

    this.verifyMenus();
  }

  async verifyName() {
    if (this.Form.name) {
      const {
        data: { data }
      } = await service.existed({ name: this.Form.name, id: this.rolePer?.id ? this.rolePer?.id : '' });
      this.isNameWarnings = true;

      if (data) {
        this.nameWarnings = '该角色名称已存在，请修改后重新输入';
      } else if (
        this.Form.name.length < 2 ||
        this.Form.name.length > 10 ||
        /[0-9]/.test(`${this.Form.name}`) ||
        this.regEn.test(`${this.Form.name}`) ||
        this.regCn.test(`${this.Form.name}`)
      ) {
        this.nameWarnings = '请使用2-10位汉字或英文';
      } else {
        this.isNameWarnings = false;
      }
    } else {
      this.nameWarnings = '请填写必填项';
      this.isNameWarnings = true;
    }
  }

  verifyMenus() {
    if (this.menus.length) {
      this.isMenuWarnings = false;
    } else {
      this.isMenuWarnings = true;
      this.menuWarnings = '请勾选必选项';
    }
  }

  async created() {
    await this.getTreePer();
    await this.getAllRole();
    // 第1个是默认展开几级 第2个是源数据
    this.getDefaultShowNodes(1, this.initMenus);
  }

  getDefaultShowNodes(num: any, children: any) {
    --num;
    if (num >= 0) {
      for (let i = 0; i < children.length; i++) {
        this.defaultShowNodes.push(children[i].id);
        if (children[i].children) {
          this.getDefaultShowNodes(num, children[i].children);
        }
      }
    }
  }

  async getAllRole() {
    const {
      data: { data }
    } = await service.getAllRole();
    this.initRole = data;
  }

  async getTreePer() {
    const {
      data: { data }
    } = await service.getTreePer();
    this.initMenus = this.changTree(data);
  }

  // 处理树形children为null
  changTree(data: any) {
    data.map((item: any) => {
      if (item.children == null) {
        delete item.children;
        item.label = item.name;
      }
      if (item.children) {
        this.changTree(item.children);
        item.label = item.name;
      }
    });
    return data;
  }

  async save() {
    const data = {} as any;
    if (!this.menus.length && !this.Form.name) {
      this.isMenuWarnings = true;
      this.isNameWarnings = true;
      this.nameWarnings = '请填写必填项';
      this.menuWarnings = '请勾选必选项';
    } else if (!this.Form.name) {
      this.isNameWarnings = true;
      this.nameWarnings = '请填写必填项';
    } else if (!this.menus.length) {
      this.isMenuWarnings = true;
      this.menuWarnings = '请勾选必选项';
    }
    if (!this.isNameWarnings && !this.isMenuWarnings) {
      if (this.rolePer) {
        data.id = this.rolePer.id;
      }
      data.name = this.Form.name;
      data.permissionIds = this.menus;
      const {
        data: { msg }
      } = await service.addOrUpdate(data);
      if (msg === '操作成功') {
        this.$notify({
          title: '保存成功',
          message: '',
          type: 'success',
          position: 'bottom-right'
        });
        if (this.rolePer) {
          this.userInfo.roles.forEach(async (item: any) => {
            if (item.name === this.Form.name) {
              if (this.$router.currentRoute.path !== getUserPath()!.replace(/"/g, '')) {
                this.$router.push(`${getUserPath()!.replace(/"/g, '')}`);
              }
              const {
                data: { data }
              } = await ApiGetUserinfo();

              UserModule.setUserData(data);
            } else {
              this.$emit('done');
            }
          });
        }
        this.$emit('done');
        this.Form.name = '';
      }
    }
  }
}
