var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-container"},[_c('el-card',{staticClass:"h-full overflow-auto"},[_c('div',{staticClass:"flex justify-between",attrs:{"slot":"header"},slot:"header"},[_c('p',{staticClass:"font-600"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"flex mr-3 text-[#cccccc] items-center"},[_c('YkIcon',{attrs:{"icon":"ph:info-bold"}}),_c('span',{staticClass:"text-sm ml-2 text-[#999999]"},[_vm._v("题目顺序调整后，请在\"发布中心\"中发布")])],1)]),_c('div',{staticClass:"h-160"},[_c('questionTable',{ref:"table",staticClass:"h-full",attrs:{"loading":_vm.loading,"columns":_vm.tableColumns,"list":_vm.list,"queryForm":_vm.queryForm,"height":"100%","type":"expand","unfold":"未发布题目"},on:{"autoRequest":function($event){return _vm.getList()}},scopedSlots:_vm._u([{key:"code",fn:function(scope){return [_c('div',[_c('span',[_vm._v(_vm._s(scope.row.code))]),_c('span',{staticClass:"bg-[#dbddf7] ml-2 p-1 text-[#646ccf]",class:{
                'bg-[#DBDDF7]': scope.row.questionType === '普通题',
                'text-[#646CCf]': scope.row.questionType === '普通题',
                'bg-[#d7f1fD]': scope.row.questionType === '主题',
                'text-[#429cc4]': scope.row.questionType === '主题',
                'bg-[#EBF7DB]': scope.row.questionType === '延展题',
                'text-[#8CD729]': scope.row.questionType === '延展题'
              }},[_vm._v(_vm._s(scope.row.questionType))])])]}},{key:"sort",fn:function(scope){return [(scope.row.questionType !== '延展题')?_c('div',{staticClass:"sort move"},[_c('yk-icon',{staticClass:"mr-1",attrs:{"icon":"ph:list-bold"}})],1):_vm._e()]}},{key:"actions",fn:function(scope){return [_c('div',{staticClass:"actions"},[_c('el-button',{attrs:{"type":"danger","plain":""},on:{"click":function($event){return _vm.del(scope.row)}}},[_c('YkIcon',{staticClass:"text-xs mr-1 align-bottom",attrs:{"icon":"ep:delete-filled"}}),_vm._v("删除")],1)],1)]}}])})],1)]),_c('ConfirmDialog',{attrs:{"dialogFlag":_vm.ConfirmFlag,"content":"是否确定删除题目?","details":"删除将不影响已回答该题组的用户问答结果","detailText":"删除题目次日生效"},on:{"close":function($event){_vm.ConfirmFlag = false},"confirmDone":_vm.confirmDone}}),_c('YkWarn',{attrs:{"warnTitle":"删除失败","warnText":"请先修改或删除主题关联的延展题后，重新删除","isWarn":_vm.isWarn},on:{"close":function($event){_vm.isWarn = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }