
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({ components: {} })
export default class UserDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  drawer!: boolean;

  @Prop({ type: String, required: true })
  title!: string;

  userDrawer = false;
  @Watch('drawer')
  onChangeDrawer(newVal: boolean) {
    this.userDrawer = newVal;
  }
}
