
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({ components: {} })
export default class BannerDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  drawer!: boolean;

  @Prop({ type: [Object], default: () => ({}) })
  details!: Record<string, any>;

  @Prop({ type: Array, required: false })
  tagColor!: any[];

  @Prop({ type: Array, required: false })
  srcList!: [];

  @Prop({ type: String, required: true })
  title!: '';

  isDrawer = false;
  @Watch('drawer')
  onChangeDrawer(newVal: boolean) {
    this.isDrawer = newVal;
  }

  viewpic() {
    const Show = document.querySelector('.el-image__inner') as HTMLElement | null;
    Show!.click();
  }
}
