
import { Component, Mixins } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import topicGroup from './components/topicGroup.vue';
import topic from './components/topic.vue';
import publish from './components/publish.vue';

@Component({
  components: { topicGroup, topic, publish }
})
export default class PageQuestions extends Mixins(MixinTable) {
  pageTotal = 0;
  title = '发布中心';
  isPublish = 0;
  changeTotal(value: any) {
    this.pageTotal = value;
  }

  created() {
    this.isPublish = 0;
  }

  activated() {
    this.isPublish++;
  }
}
