
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import service from '@/api/consumerPush';
import { ElForm } from 'element-ui/types/form';
@Component({ components: {} })
export default class PushDetail extends Vue {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  title = '推送通知';
  queryForm = {
    title: null,
    content: null,
    pushTime: null
  } as any;

  params = {
    baNumber: '',
    id: '',
    onOff: true
  };

  surplusNum = 11;
  isEdit = false;
  pushTime = '' as any;
  info = {} as any;
  timeWarn = false;
  titleWarn = false;
  timeWarnings = '';
  contentWarn = false;
  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  minDate = '' as any;
  maxDate = '' as any;
  pickerOptions = {
    disabledDate: this.disabledDate
  };

  disabledDate(time: any) {
    const minDate = new Date(this.minDate).getTime(); // 字符串转为时间戳
    const maxDate = new Date(this.maxDate).getTime(); // 字符串转为时间戳
    return time.getTime() < minDate - 1 * 8.64e7 || time.getTime() > maxDate;
  }

  @Watch('queryForm.title')
  async onChangeTitle(newVal: any) {
    if (newVal) {
      this.surplusNum = 11 - newVal.length;
    } else {
      this.surplusNum = 11;
    }
  }

  @Watch('params.onOff')
  async onChangeSwitch(newVal: any) {
    if (!newVal) {
      this.$queryForm.resetFields();
      this.timeWarn = false;
      this.titleWarn = false;
    } else {
      this.queryForm.pushTime = this.pushTime;
    }
  }

  async verifyTitle() {
    this.titleWarn = !this.queryForm.title;
  }

  async verifyContent() {
    this.contentWarn = !this.queryForm.content;
  }

  async activated() {
    this.isEdit = false;
    if (this.$route.params.baNumber) {
      await this.getDetail();
      this.info.onOff ? (this.params.onOff = true) : (this.params.onOff = false);
    }
  }

  async getDetail() {
    const {
      data: { data }
    } = await service.detail(this.$route.params.baNumber);
    this.params.baNumber = data.baNumber;
    this.params.id = data.id;
    this.minDate = data.startTime;
    this.maxDate = data.endTime;
    this.info = data;

    Object.keys(this.queryForm).forEach((key: any) => {
      this.queryForm[key] = data[key];
      if (data.pushTime === null) {
        this.queryForm.pushTime = data.startTime;
        this.pushTime = data.startTime;
      } else {
        this.isEdit = true;
        this.pushTime = data.pushTime;
      }
    });
  }

  async save() {
    let params = {} as any;
    let message = '';
    if (!this.params.onOff) {
      params = {
        id: this.params.id,
        baNumber: this.params.baNumber,
        onOff: 0,
        ...this.queryForm
      };
      const {
        data: { msg }
      } = await service.update(params);
      if (msg === '操作成功') {
        this.$notify({
          title: '保存成功',
          message: '设置已生效',
          type: 'success',
          position: 'bottom-right'
        });
        this.$router.push('/consumerPush/index');
      }
      return;
    }
    const date = new Date(); // 1. js获取当前时间
    const min = date.getMinutes(); // 2. 获取当前分钟
    date.setMinutes(min + 5); // 3. 设置当前时间+5分钟：把当前分钟数+5后的值重新设置为date对象的分钟数
    const currentdate = this.$moment(this.queryForm.pushTime).format('yyyy-MM-DD HH:mm:ss').toString();
    const bol = this.tab(date, currentdate);
    if (bol) {
      this.timeWarn = true;
      this.timeWarnings = '请选择当前时间5分钟以后的时间';
    } else {
      this.timeWarn = false;
    }
    this.verifyTitle();
    this.verifyContent();
    if (currentdate < this.minDate || currentdate > this.maxDate) {
      this.timeWarn = true;
      this.timeWarnings = '推送时间不在Banner有效期内请重新设置';
    } else if (currentdate > this.minDate && currentdate < this.maxDate) {
      if (currentdate > this.maxDate) {
        this.timeWarn = false;
      }
    }

    if (this.timeWarn || this.titleWarn || this.contentWarn) return;

    this.queryForm.pushTime = currentdate;
    if (this.isEdit) {
      params = {
        id: this.params.id,
        onOff: 1,
        ...this.queryForm
      };
      const {
        data: { msg }
      } = await service.update(params);
      message = msg;
    } else {
      params = {
        baNumber: this.params.baNumber,
        ...this.queryForm
      };
      const {
        data: { msg }
      } = await service.created(params);

      message = msg;
    }
    if (message === '操作成功') {
      this.$notify({
        title: '保存成功',
        message: '设置已生效',
        type: 'success',
        position: 'bottom-right'
      });
      this.$router.push('/consumerPush/index');
    }
  }

  tab(date1: any, date2: any) {
    const date = new Date(date1);
    const currentDate = new Date(date2);
    if (date.getTime() > currentDate.getTime()) {
      return true;
    } else if (date.getTime() < currentDate.getTime()) {
      return false;
    } else if (date.getTime() === currentDate.getTime()) {
      // console.log('一样大');
    }
  }
}
