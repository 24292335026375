
import SpPagination, { PageInfo } from '@c/YK_Table/YkPagination.vue';
import SpTableButton from '@c/YK_Table/YkTableButton.vue';
import SpTablePoptip from '@c/YK_Table/YkTablePoptip.vue';

import { Vue, Prop, PropSync, Watch, Component, Emit, Ref } from 'vue-property-decorator';
import { RESPONSE_CONFIG } from '@/utils/request';
import { Table } from 'element-ui';

export type ColumnItem<T extends Record<string, any>> =
  | {
      slot: 'action';
      fixed?: 'left' | 'right' | boolean;
      label?: string;
      minWidth?: number;
      width?: number;
      listeners?: {
        remove?: YkFunction<Promise<void>>;
        edit?: YkFunction<void>;
        detail?: YkFunction;
      };
      type?: 'selection' | 'index' | 'expand';
      formatter?: (row: T, column: ColumnItem<T>, cellValue: any, index: number) => any;
      sortable?: boolean | string;
      tooltip?: boolean;
    }
  | {
      prop: keyof T;
      type?: 'selection' | 'index' | 'expand';
      label: string;
      slot?: string;
      align?: 'center' | 'left' | 'right';
      fixed?: 'left' | 'right' | boolean;
      minWidth?: number;
      width?: number;
      className?: string;
      formatter?: (row: T, column: ColumnItem<T>, cellValue: any, index: number) => any;
      showOverflowTooltip?: boolean;
      selectable?: YkFunction;
      sortable?: boolean | string;
      tooltip?: boolean;
    };

@Component({
  inheritAttrs: false,
  components: { SpPagination, SpTablePoptip, SpTableButton }
})
export default class YkTable extends Vue {
  // 请求接口
  @Prop({ type: [Function, Array], required: true })
  list!: YkFunction<Promise<any>> | Record<string, any>[];

  @Prop({ type: [Array], required: true })
  columns!: Array<ColumnItem<any>>;

  @Prop({ type: Boolean, default: true })
  autoRequest!: boolean;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  // 每页显示几条数据
  @Prop({ type: Number, required: false })
  pageSize?: number;

  @Prop({ type: Boolean, required: false, default: true })
  fullPage?: boolean;

  @Prop({ type: Number, required: false })
  total?: number;

  @Prop({ type: Number, required: false })
  currentPage?: number;

  @Prop({ type: [Object], default: () => ({}) })
  pageOptions!: Record<string, any>;

  @Prop({ type: [Object], default: () => ({}) })
  queryForm?: Record<string, any>;

  @Prop({ type: [Object], default: () => ({}) })
  selectionRow?: Record<string, any>;

  @Prop({ type: String, required: false })
  type?: string;

  @Prop({ type: Boolean, required: false })
  isWarning?: boolean;

  // 单选 | 多选
  @Prop({
    type: String,
    default: 'multi',
    validator(value: 'single' | 'multi' = 'multi') {
      return ['single', 'multi'].includes(value);
    }
  })
  checkMode!: 'single' | 'multi';

  // 无数据文案
  @Prop({ type: String, default: '暂无数据', required: false })
  emptyText?: string;

  // 表格是否加边框
  @Prop({ type: Boolean, required: false })
  border?: boolean;

  @Prop({ type: Function, required: false })
  columnStyle!: () => {};

  // clone row data
  @PropSync('selection', { type: Array, required: false })
  private _selection?: Record<string, any>[];

  @Prop({ type: [Boolean, Function], default: true })
  selectable!: boolean | ((row: any, rowIndex: number) => boolean);

  get selectableFn() {
    return typeof this.selectable === 'function' ? this.selectable : undefined;
  }

  get maxHeight() {
    let value = this.$attrs['max-height'] as string | number | undefined;
    value = value?.toString().endsWith('vh') ? (+value.toString().slice(0, -2) / 100) * window.innerHeight : value;
    return value;
  }

  dataLoading = false;
  dataList = [] as Record<string, any>[];
  params = {} as Record<string, any>;

  @Watch('list')
  onListChange() {
    if (Array.isArray(this.list)) {
      this.dataList = this.list;

      this.$nextTick(() => {
        if (this.selectionRow!.id) {
          this.refTable!.toggleRowSelection(this.dataList[0], true);
        }
      });
    }
  }

  @Watch('isWarning')
  onWarnChange() {
    if (this.isWarning) {
      this.refTable!.clearSelection();
    }
  }

  @Prop({ type: Boolean, required: false })
  disableCheck?: boolean;

  // 除 action 之外的 slots
  get columnSlots(): Array<any> {
    const columns = this.cols.filter(item => item.slot && item.slot !== 'action' /* && item.listeners */);
    return columns;
  }

  get actionCol() {
    const { slot, ...action } = this.cols.find(item => item.slot === 'action') ?? {};
    return slot ? action : null;
  }

  // get actionPermits() {
  //   const action = this.cols.find((item) => item.slot === 'action')
  //   return action?.permits || {}
  // }
  private get cols(): ColumnItem<any>[] {
    let cols = this.columns.slice(0).map(item => {
      let { width, minWidth, label, slot } = item;
      if (!width && !minWidth && label && slot !== 'action') {
        minWidth = label.length * 13 + 20;
      }
      return { ...item, minWidth };
    });
    cols = this.colsWithFixed(cols) as (ColumnItem<any> & {
      minWidth: number;
    })[];
    // cols = this.colsWithPermits(cols)
    return cols;
  }

  colsWithFixed(columns: ColumnItem<any>[]) {
    // 操作列
    const action = columns.find(item => item.slot === 'action');
    if (action) {
      const defAction = { title: '操作', width: '200', align: 'center', fixed: 'right' };
      action.fixed
        ? columns.splice(columns.indexOf(action), 1, Object.assign(defAction, action))
        : Reflect.deleteProperty(action, 'fixed');
    }
    // 可选列
    if (this.selectable && this._selection) {
      const selection = columns.find(item => item.type === 'selection');
      selection && columns.splice(columns.indexOf(selection), 1);
      columns.unshift({
        width: '38',
        type: 'selection',
        selectable: this.selectableFn,
        align: 'center',
        fixed: 'left',
        className: this.checkMode === 'single' ? 'sp-table-check-single' : '',
        ...(selection ?? {})
      } as ColumnItem<any>);
    }

    return columns;
  }

  colsWithPermits(columns: ColumnItem<any>[]) {
    const action = columns.find(item => item.slot === 'action');
    // const innerPermits = Object.values(this.actionPermits) as string[]

    // 操作列权限
    if (action && /* !this.hasPermit(innerPermits) && */ !this.$scopedSlots?.action) {
      columns.splice(columns.indexOf(action), 1);
    }

    // 可选列权限
    const selection = columns.find(item => item.type === 'selection');
    if (selection && !this.selectable) {
      columns.splice(columns.indexOf(selection), 1);
    }

    return columns;
  }

  get showPagination() {
    return (this.pageSize ?? 0) < Number.MAX_SAFE_INTEGER && !Array.isArray(this.list);
  }

  private plainRow(item: object) {
    if (item === null || typeof item !== 'object') return item;
    item = Object.assign({}, item);
    Object.keys(item).forEach(key => key.startsWith('_') && Reflect.deleteProperty(item, key));
    return item;
  }

  // 翻页时是否记忆选中的行
  @Prop({ type: Boolean, default: false })
  reserveSelection!: boolean;

  selectObj = {} as any;
  onSelectionChange(selection: Array<Record<string, any>>) {
    if (selection.length > 1) {
      // 当选中两个以上的选项时
      this.refTable!.clearSelection();
      // 再把第一次选中的勾选上
      this.refTable!.toggleRowSelection(selection[selection.length - 1]);
      // selectObj 用来保存勾选的那一项内容
      this.selectObj = selection[selection.length - 1];
      //   this.$emit('selectionChange', this.selectObj);
    } else if (selection.length === 1) {
      this.selectObj = selection[0];

      this.$emit('selectionChange', this.selectObj);
    }
  }

  private getList(params: YkFunction<Promise<any>> | Record<string, any>, queryForm: any) {
    if (typeof this.list === 'function') {
      return this.list(params, queryForm);
    } else {
      return Promise.resolve({ data: this.list });
    }
  }

  mergePageInfo(pageInfo: PageInfo) {
    // 详情跳回列表时，定位 pageIndex
    const curRoute = this.$route.matched[this.$route.matched.length - 1];
    const curRoutePageIndex = window.sessionStorage.getItem(`${curRoute.path}-pageIndex`) || 0;
    const curRoutePageSize = window.sessionStorage.getItem(`${curRoute.path}-pageSize`);
    if (!Reflect.has(pageInfo, 'pageIndex')) pageInfo.pageIndex = +curRoutePageIndex || 1;
    if (!Reflect.has(pageInfo, 'pageSize') && curRoutePageSize) pageInfo.pageSize = +curRoutePageSize;
    window.sessionStorage.removeItem(`${curRoute.path}-pageIndex`);
    window.sessionStorage.removeItem(`${curRoute.path}-pageSize`);

    const { pageSize, pageIndex } = this.pageInfo;
    return Object.assign({ pageSize, pageIndex }, pageInfo);
  }

  @Emit('on-success')
  async request(params = {} as Record<string, any>, pageInfo = {}, isReset = false) {
    // 翻页时带上次查询的条件
    Object.keys(params).forEach(key => {
      let value = params[key];
      if (value === '') Reflect.deleteProperty(params, key);
      else if (Array.isArray(value)) {
        value.length || Reflect.deleteProperty(params, key);
      } else if (value?.constructor === Object) {
        params[key] = value = Object.assign({}, value);
        Object.keys(value).forEach(subkey => value[subkey] === '' && Reflect.deleteProperty(value, subkey));
        Object.keys(value).length || Reflect.deleteProperty(params, key);
      }
    });
    this.params = params ?? this.params;
    params = Object.assign({}, this.params, this.pageSize === Number.MAX_SAFE_INTEGER ? {} : this.mergePageInfo(pageInfo));
    params.pageNum = params.pageIndex;
    Reflect.deleteProperty(params, 'pageIndex');
    this.dataLoading = true;
    const result = { ...this.queryForm } as any;
    Object.keys(result).forEach((key: any) => {
      if (result[key] === '') {
        result[key] = null;
      }
    });
    if (isReset) {
      this.pageInfo.pageIndex = 1;
    }

    return this.getList(params, result)
      .then((res: any) => {
        const { page = params?.page, data = {} } = res;
        if ((this.pageSize ?? 0) < Number.MAX_SAFE_INTEGER) {
          if (res.data.data) {
            this.pageTotalMixin = res.data.data[RESPONSE_CONFIG.TOTAL] ?? 0;
          }
          this.pageInfo.pageIndex = page || 0;
          this.pageInfo.pageSize = params?.pageSize || 0;
        }
        if (data.data?.records) {
          data.data.records.forEach((item: any) => {
            if (item.roles) {
              item.roles.forEach((role: any) => {
                item.roleName += role.name + '、';
              });
              item.roleName = item.roleName.slice(0, -1).replace('undefined', '');
              if (item.departments) {
                item.departmentName = item.departments[0].name;
              }
              if (item.phoneNumber.includes('+86')) {
                item.phoneNumber = item.phoneNumber.replace('+86', '');
              }
            }
          });
        }

        this.dataList = data.data?.records;

        !this.reserveSelection && this.selectable && this.onSelectionChange([]);
        return data.data?.records;
      })
      .finally(() => {
        this.dataLoading = false;
      });
  }

  // 分组选择时候的级联
  onSelectionCascade(newValue: any[], oldValue: any[] = []) {
    if (!('tree-props' in this.$attrs)) return newValue;
    const { children } = this.$attrs['tree-props'] as unknown as {
      children: string;
    };
    const list = newValue.slice(0);
    const newSet = new Set(newValue.map(item => item.id));
    const oldSet = new Set(oldValue.map(item => item.id));
    // 新选的 rows
    newValue
      .filter(item => !oldSet.has(item.id))
      .forEach(item => {
        if (children in item) {
          item[children].forEach((si: any) => {
            newSet.has(si.id) || list.push(this.plainRow(si));
          });
        } else {
          const itemParent = this.dataList.find(i => i[children].some((si: any) => si.id === item.id));
          itemParent?.[children].every((si: any) => newSet.has(si.id)) && list.push(this.plainRow(itemParent));
        }
      });
    // 删除的
    oldValue
      .filter(item => !newSet.has(item.id))
      .forEach(item => {
        if (children in item) {
          item[children].forEach((si: any) => {
            if (newSet.has(si.id)) {
              const index = list.findIndex(i => i.id === si.id);
              list.splice(index, 1);
            }
          });
        } else {
          const itemParent = this.dataList.find(i => i[children].some((si: any) => si.id === item.id));
          if (itemParent?.[children].some((si: any) => !newSet.has(si.id))) {
            const index = list.findIndex(i => i.id === itemParent.id);
            list.splice(index, 1);
          }
        }
      });
    return list.length !== newValue.length ? list : newValue;
  }

  @Ref('table') refTable?: Table;

  pageInfo = {} as any;
  pageInfoMixin = {
    pageIndex: 1,
    pageSize: 20
  };

  pageTotalMixin = 0;

  private created() {
    this.pageInfo = Object.assign({}, this.pageOptions, this.pageInfoMixin);
    this.pageSize && (this.pageInfo.pageSize = this.pageSize);
    this.autoRequest && this.request();
    !this.fullPage && this.$emit('autoRequest');
  }

  indexMethod(index: number) {
    return index + 1;
  }
}
