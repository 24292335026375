
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import service from '@/api/questions';
import questionTable from './table.vue';
import Sortable from 'sortablejs';
@Component({
  components: { questionTable }
})
export default class PageTopicGroup extends Mixins(MixinTable) {
  @Prop({ type: String, required: false })
  title?: string;

  sortIds = [] as any;
  loading = false;
  list = [] as any;
  tagList = [] as any;
  moduleNameList = [] as any;
  operatorType = [] as any;
  @Watch('title')
  onChangeTitle(newVal: any) {
    if (newVal === '题目') {
      this.getList();
    }
  }

  // 创建sortable实例
  initSortable() {
    const ele = document.querySelector('.el-table__body > tbody');
    // 创建拖拽实例
    Sortable.create(ele, {
      animation: 150, // 动画
      disabled: false, // 拖拽不可用? false 启用（刚刚渲染表格的时候起作用，后面不起作用）
      handle: '.move', // 指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
      filter: '.disabled', // 指定不可拖动的类名（el-table中可通过row-class-name设置行的class）
      dragClass: 'dragClass', // 设置拖拽样式类名
      ghostClass: 'ghostClass', // 设置拖拽停靠样式类名
      chosenClass: 'chosenClass', // 设置选中样式类名
      // 开始拖动事件
      onStart: () => {
        // console.log('开始拖动');
      },
      onEnd: (event: any) => {
        const tempTableData = [...this.list]; // 先存一份临时变量表头数据
        let temp = '' as any;
        temp = tempTableData[event.oldIndex]; //
        tempTableData.splice(event.oldIndex, 1);
        tempTableData.splice(event.newIndex, 0, temp);
        this.list = [];
        this.$nextTick(async () => {
          this.sortIds = [];
          this.list = tempTableData;
          this.list.forEach((item: any) => {
            this.sortIds.push(item.id);
          });
          this.sortIds = this.sortIds.toString();
          const {
            data: { msg }
          } = await service.groupSort(this.sortIds);
          if (msg === '操作成功') {
            this.getList();
          }
        });
      }
    });
  }

  mounted() {
    this.initSortable();
  }

  async getList() {
    this.loading = true;
    const {
      data: { data }
    } = await service.groupAllList({});
    if (data !== null) {
      this.list = data;
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  // table
  // tableRequest = service.query;
  get tableColumns() {
    const data: ColumnItem<questionItem>[] = [
      {
        slot: 'code',
        prop: 'code',
        label: '题组编号'
      },
      { label: '组内题数', prop: 'questionSize' },
      {
        slot: 'sort',
        prop: 'seq',
        label: '题组排序'
      }
    ];
    return data;
  }
}
