
import { Component, Vue } from 'vue-property-decorator';

import service from '@/api/user';
import Roleservice from '@/api/role';

@Component({ components: {} })
export default class UserDetail extends Vue {
  Form = {
    userRole: '',
    type: [],
    userRoles: [] as string[]
  };

  userInfo = {
    externalId: '',
    fullname: '',
    phoneNumber: '',
    departmentName: ''
  };

  queryForm = {
    name: '',
    orders: [
      {
        column: 'member_num',
        asc: false
      }
    ]
  };

  params = {
    pageNum: 1,
    pageSize: 100
  };

  Role = [] as any;

  isWarning = false;
  userRoles = [] as string[];
  roleId = [] as number[];

  handleSelect(value: any) {
    this.roleId = [] as number[];
    if (value.length) {
      this.isWarning = false;
      this.Role.forEach((item: any) => {
        value.forEach((val: any) => {
          if (item.name === val) {
            this.roleId.push(item.id);
          }
        });
      });
    } else {
      this.roleId = [] as number[];
      this.isWarning = true;
    }
  }

  get isEdit() {
    return 'userId' in this.$route.params;
  }

  async getList() {
    const {
      data: { data }
    } = await Roleservice.query(this.params, this.queryForm);
    if (data !== null) {
      data.records.forEach((item: any) => {
        item.value = item.name;
      });
      this.Role = [...data.records];
    } else {
      this.Role = [];
    }
  }

  async created() {
    this.getList();
    if (this.isEdit) {
      const {
        data: { data }
      } = await service.detail(this.$route.params.userId);
      if (data !== null) {
        if (data.departments) {
          data.departmentName = data.departments[0].name;
        }
        if (data.phoneNumber.includes('+86')) {
          data.phoneNumber = data.phoneNumber.replace('+86', '');
        }
        if (data.roles !== null) {
          data.roles.forEach((item: any) => {
            this.Form.userRoles.push(item.name);
            this.roleId.push(item.id);
          });
        }

        this.userInfo = data;
      }
    }
  }

  async save() {
    if (this.roleId.length) {
      const data = {
        userId: this.$route.params.userId,
        roleIds: this.roleId
      };
      const {
        data: { msg }
      } = await service.updateUserRole(data);
      if (msg === '操作成功') {
        this.$notify({
          title: '保存成功',
          message: '',
          type: 'success',
          position: 'bottom-right'
        });
        this.$router.push('/user/index');
      }
    }
  }
}
