
import { Component, Mixins, Ref, Prop, Watch } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/banner';
import serviceC from '@/api/consumerUser';
import serviceS from '@/api/banner/material';
import OSS from 'ali-oss';
import drawer from './component/drawer.vue';
@Component({
  components: { drawer }
})
export default class PageMaterial extends Mixins(MixinTable) {
  @Prop({ type: Array, required: true })
  tagList!: [];

  @Prop({ type: String, required: true })
  title!: '';

  @Ref('queryForm') readonly $queryForm!: ElForm;
  searchTime = [] as Date[];
  queryForm = {
    baNumber: null,
    smNumber: null,
    smType: null,
    searchStartTime: null as null | string,
    searchEndTime: null as null | string,
    status: null,
    tagName: null
  };

  params = {
    pageNum: 1,
    pageSize: 10,
    searchParam: ''
  };

  list = [] as any;
  pageTotal = 0;
  currentPage = 0;
  currentSize = 0;
  loading = false;
  ConfirmFlag = false;
  isShowDrawer = false;
  BannerDetail = {} as any;
  srcList = [] as any;
  status = [
    {
      value: '0',
      label: '待上线'
    },
    {
      value: '1',
      label: '已上线'
    },
    {
      value: '2',
      label: '已下线'
    }
  ];

  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  @Watch('title', { immediate: true })
  onChangeTitle(val: any) {
    if (val === '首页Banner') {
      this.getList();
    }
  }

  drawerTitle = 'Banner详情';
  // srcList: string[] = [];
  drawerDetails = {} as any;

  async BaShowDrawer(row: any) {
    this.srcList = [];
    this.isShowDrawer = true;
    this.drawerTitle = 'Banner详情';
    const {
      data: { data }
    } = await service.getByBaNumber(row.baNumber);
    this.drawerDetails = { ...data };
    if (this.drawerDetails.baStatus === 0) {
      this.drawerDetails.baStatus = '待上线';
    } else if (this.drawerDetails.baStatus === 1) {
      this.drawerDetails.baStatus = '已上线';
    } else {
      this.drawerDetails.baStatus = '已下线';
    }
    if (this.drawerDetails.type === 1) {
      this.drawerDetails.type = '日常';
    } else if (this.drawerDetails.type === 2) {
      this.drawerDetails.type = '活动';
    } else {
      this.drawerDetails.type = '个性化';
    }
    if (this.drawerDetails.smStatus === 1) {
      this.drawerDetails.smStatus = '未使用';
    } else if (this.drawerDetails.smStatus === 2) {
      this.drawerDetails.smStatus = '已使用';
    }

    if (!this.drawerDetails.tagName) {
      this.drawerDetails.tagName = '所有用户';
      this.drawerDetails.tagColor = '';
    }
    const res = await serviceC.getOSSToken('user/material/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + this.drawerDetails.smUrl, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
    }
  }

  async SmShowDrawer(row: any) {
    this.srcList = [];
    this.isShowDrawer = true;
    this.drawerTitle = '素材详情';
    const {
      data: { data }
    } = await serviceS.getByNumber(row.smNumber);
    this.drawerDetails = { ...data };
    if (this.drawerDetails.status === 1) {
      this.drawerDetails.status = '未使用';
    } else if (this.drawerDetails.status === 2) {
      this.drawerDetails.status = '已使用';
    }
    if (this.drawerDetails.type === 1) {
      this.drawerDetails.type = '日常';
    } else if (this.drawerDetails.type === 2) {
      this.drawerDetails.type = '活动';
    } else {
      this.drawerDetails.type = '个性化';
    }
    if (!this.drawerDetails.tagName) {
      this.drawerDetails.tagName = '所有用户';
      this.drawerDetails.tagColor = '';
    }
    const res = await serviceC.getOSSToken('user/material/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + this.drawerDetails.smUrl, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
    }
  }

  changeTag(val: any) {
    this.tagList.forEach((item: any) => {
      if (item.id === val) {
        this.queryForm.tagName = item.name;
      }
    });
    if (!val) {
      this.queryForm.tagName = null;
    }
  }

  bannerId = '';
  baCurrent = {} as any;
  del(row: any) {
    this.ConfirmFlag = true;
    this.baCurrent = row;
  }

  async confirmDone() {
    const params = {
      id: this.baCurrent.id,
      status: 2,
      startTime: this.baCurrent.startTime,
      endTime: this.baCurrent.endTime,
      offlineTime: this.$moment(new Date()).format('yyyy-MM-DD HH:mm:ss')
    };
    const {
      data: { msg }
    } = await service.update(params);
    if (msg === '操作成功') {
      if (this.params.pageNum > 1 && this.list.length <= 1) {
        this.params.pageNum--;
      }
      this.ConfirmFlag = false;
      this.getList();
      this.$notify({
        title: '关闭成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
    }
  }

  getDefaultTime() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    start.setHours(0, 0, 0);
    end.setHours(23, 59, 59);
    this.searchTime = [start, end];
  }

  // table 每页显示条数改变
  tablePageSizeChange(pageSize: number) {
    this.currentSize = pageSize;
    this.change({ pageSize, pageNum: 1 });
  }

  // table 当前显示页改变
  tablePageChange(pageNum: number) {
    this.currentPage = pageNum;
    this.change({ pageNum });
  }

  // 触发表格刷新
  change(newPageInfo: any) {
    this.params = Object.assign({}, this.params, newPageInfo);
    this.getList();
  }

  async getList() {
    this.loading = true;
    if (this.searchTime !== null && this.searchTime.length > 1) {
      this.queryForm.searchStartTime = this.$moment(this.searchTime[0]).format('yyyy-MM-DD HH:mm:ss') as any;
      this.queryForm.searchEndTime = this.$moment(this.searchTime[1].setHours(23, 59, 59)).format('yyyy-MM-DD HH:mm:ss') as any;
    } else {
      this.queryForm.searchStartTime = null;
      this.queryForm.searchEndTime = null;
    }
    const {
      data: { data }
    } = await service.query(this.params, this.queryForm);
    this.pageTotal = data.total;
    if (data.records !== null) {
      this.list = data.records;
      this.list.forEach((item: any) => {
        if (item.status === 0) {
          item.status = '待上线';
        } else if (item.status === 1) {
          item.status = '已上线';
        } else {
          item.status = '已下线';
        }
        if (item.smType === 1) {
          item.smType = '日常';
        } else if (item.smType === 2) {
          item.smType = '活动';
        } else {
          item.smType = '个性化';
        }
        if (!item.tagName) {
          item.tagName = '所有用户';
          item.tagColor = '';
        }
        item.tikeTime = item.startTime + '~' + item.endTime;
      });
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  onReset() {
    this.currentSize = 10;
    this.currentPage = 1;
    this.params = {
      pageNum: 1,
      pageSize: 10,
      searchParam: ''
    };
    this.searchTime = [];
    this.$queryForm.resetFields();
    this.getList();
  }

  activated() {
    this.getList();
  }

  // table
  get tableColumns() {
    const data: ColumnItem<bannerItem>[] = [
      { slot: 'baNumber', label: 'Banner编号', prop: 'baNumber' },
      { slot: 'smNumber', label: '素材编号', prop: 'smNumber', minWidth: 130 },
      { label: '素材类型', prop: 'smType' },
      { slot: 'status', label: '状态', prop: 'status', width: 90 },
      { label: '生效日期', prop: 'tikeTime', minWidth: 270 },
      { slot: 'tagName', label: '用户画像', prop: 'tagName' },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 200
      }
    ];
    return data;
  }
}
