
import { Component, Vue } from 'vue-property-decorator';
import YkWarn from '@/components/Yk_Warn/index.vue';
import service from '@/api/devices';
import { UserModule } from '@/store/modules/user';
@Component({ components: { YkWarn } })
export default class DeviceDetail extends Vue {
  deviceInfo = {} as any;
  userInfo = [] as any;
  FirmwareInfo = [] as any;
  isWarn = false;
  get deviceId() {
    return 'deviceId' in this.$route.params;
  }

  queryUser(item: any) {
    // 匹配code 查看本账号是否有【C端用户管理】权限
    if (UserModule.useData?.permissionCodes) {
      const bol = UserModule.useData.permissionCodes.some((item: any) => item === '300ARgbgfizn');
      if (bol) {
        this.$router.push({ path: `/consumerUser/detail/${item.userId}` });
      } else {
        this.isWarn = true;
      }
    }
  }

  async getDeviceDetail() {
    const form = new FormData();
    form.append('clientId', this.$route.params.deviceId!);
    service
      .info(form)
      .then((data: any) => {
        this.deviceInfo = data.data.data;
        if (this.deviceInfo.status === 1) {
          this.deviceInfo.devStatus = '在线';
        } else if (this.deviceInfo.status === 0) {
          this.deviceInfo.devStatus = '离线';
        } else if (this.deviceInfo.status === -1) {
          this.deviceInfo.devStatus = '网络异常';
        }
        Object.keys(this.deviceInfo).forEach((key: any) => {
          if (this.deviceInfo[key] === null) {
            this.deviceInfo[key] = '-';
          }
        });
        if (this.deviceInfo.id) {
          this.getFirmware(this.deviceInfo.id);
        }
      })
      .catch(() => {
        this.$router.push('/devices/index');
      });
  }

  async getBindUser() {
    const {
      data: { data }
    } = await service.getBindUser(this.$route.params.deviceId);
    if (data) {
      this.userInfo = data;
      this.userInfo.forEach((item: any) => {
        Object.keys(item).forEach((key: any) => {
          if (item[key] === null) {
            item[key] = '-';
          }
        });
      });
    } else {
      this.userInfo = [
        {
          userId: '-',
          binding: '-'
        }
      ];
    }
  }

  async getFirmware(id: any) {
    const {
      data: { data }
    } = await service.getFirmware(id);
    this.FirmwareInfo = data;
  }

  async created() {
    this.getDeviceDetail();
    this.getBindUser();
  }
}
