import request from '@/utils/request';

const service = {
  query(params: any, data: any) {
    return request.post(`/api/user/v1/roles?pageNum=${params.pageNum}&pageSize=${params.pageSize}`, data);
  },
  remove(ids: any) {
    return request({
      method: 'post',
      url: `/api/user/v1/roles/del?ids=${ids}`,
      headers: {
        'accept-language': 'zh-Hans-CN',
        from: 1
      }
    });
  },
  detail(id: any) {
    return request({
      method: 'get',
      url: `api/user/v1/roles/detail/${id}`,
      headers: {
        'accept-language': 'zh-Hans-CN',
        from: 1
      }
    });
  },
  existed(params: any) {
    return request.post(`/api/user/v1/roles/existed?name=${params.name}&id=${params.id}`);
  },
  addOrUpdate(data: any) {
    return request({
      method: 'POST',
      url: '/api/user/v1/roles/addOrUpdate',
      headers: {
        'accept-language': 'zh-Hans-CN',
        from: 1
      },
      data
    });
  },
  getTreePer() {
    return request.post('api/user/v1/permissions/selectTree', {});
  },
  getAllRole() {
    return request({
      method: 'POST',
      url: '/api/user/v1/roles/all',
      headers: {
        'accept-language': 'zh-Hans-CN',
        from: 1
      }
    });
  },
  roleCanDelCheck(data: any) {
    return request({
      method: 'POST',
      url: `/api/user/v1/roles/canDelCheck?id=${data.id}`,
      headers: {
        'Accept-Language': 'zh-CN',
        from: 1
      },
      data
    });
  }
};

export default service;
