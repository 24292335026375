
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/versions';
import { setDeviceVersion, removeDeviceVersion } from '@/utils/cookies';
@Component({ components: {} })
export default class PageVersions extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    param: '',
    otaType: ''
  };

  otaType = [
    {
      value: 1,
      label: 'IOS'
    },
    {
      value: 2,
      label: 'Android'
    },
    {
      value: 3,
      label: '固件'
    }
  ];

  params = {
    pageNum: 1,
    pageSize: 10
  };

  list = [] as any;
  pageTotal = 0;
  ConfirmFlag = false;
  versionId = null;
  loading = false;

  edit(row: any) {
    setDeviceVersion(row);
    this.$router.push({ path: `/devices/versions/detail/${row.id}` });
  }

  del(row: any) {
    this.versionId = row.id;
    this.ConfirmFlag = true;
  }

  // 删除完成
  async confirmDone() {
    const {
      data: { msg }
    } = await service.remove(this.versionId);
    if (msg === '操作成功') {
      if (this.params.pageNum > 1 && this.list.length <= 1) {
        this.params.pageNum--;
      }
      this.ConfirmFlag = false;
      this.getList();
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
    }
  }

  handlePageNum(value: number) {
    this.params.pageNum = value;
    this.getList();
  }

  onReset() {
    this.params = {
      pageNum: 1,
      pageSize: 10
    };
    this.$queryForm.resetFields();
    this.getList();
  }

  async getList() {
    const params = { ...this.queryForm, ...this.params } as any;
    this.loading = true;
    const formData = new FormData();
    Object.keys(params).forEach((key: any) => {
      formData.set(key, params[key]);
    });

    const {
      data: { data }
    } = await service.query(formData);
    if (data.records !== null) {
      this.pageTotal = data.total;
      this.list = data.records;
      this.list.forEach((item: any) => {
        item.upgrdType === 'all' ? (item.upgrdType = '全量') : (item.upgrdType = '差分');
      });
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  activated() {
    this.onReset();
    removeDeviceVersion();
  }

  // table
  get tableColumns() {
    const data: ColumnItem<VersionsItem>[] = [
      { label: '设备型号', prop: 'deviceModel' },
      { label: '升级包类型', prop: 'upgrdType' },
      { label: '升级对象', prop: 'prgType' },
      { label: '版本号', prop: 'versionNum' },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 200
      }
    ];
    return data;
  }
}
