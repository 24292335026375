
import { Component, Vue } from 'vue-property-decorator';
import { getUserData } from '@/utils/cookies';
@Component({ components: {} })
export default class PersonalInfo extends Vue {
  userInfo = {
    externalId: '',
    fullname: '',
    phoneNumber: '',
    departmentName: '',
    roles: '',
    lastLoginTime: '',
    headPortrait: ''
  };

  roles = '';
  created() {
    if (getUserData()) {
      const userData = JSON.parse(getUserData()!);
      this.userInfo = userData;
      if (userData.departments) {
        userData.departmentName = userData.departments[0].name;
      }
      if (userData.phoneNumber.includes('+86')) {
        userData.phoneNumber = userData.phoneNumber.replace('+86', '');
      }
      userData.roles.forEach((item: any) => {
        this.roles += item.name + '、';
      });
      this.userInfo.roles = this.roles.slice(0, -1);

      this.userInfo = userData;
    }
  }
}
