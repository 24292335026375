
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import YKDialog from '@/components/YK_Dialog/index.vue';
import YkWarn from '@/components/Yk_Warn/index.vue';

@Component({
  components: { YKDialog, YkWarn }
})
export default class YkUpload extends Vue {
  dialogImageUrl = '';
  dialogVisible = false;
  disabled = false;
  isWarn = false;
  isShow = false;
  handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
  }

  handlePictureCardPreview(file: any) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }

  /**
   * 最大允许上传个数
   */
  @Prop({ type: Number, required: false })
  limit!: 3;

  /**
   * 文件列表的类型
   */
  @Prop({ type: String, required: false })
  listType!: 'text' | 'picture' | 'picture-card';

  /**
   * 是否清空已上传的文件列表
   */
  @Prop({ type: Boolean, required: false })
  clearFiles!: boolean;

  /**
   * 表单的ref
   */
  @Ref() readonly UploadRef!: any;
  /**
   * 上传的文件列表
   */
  fileList = [] as any;

  @Watch('clearFiles', { immediate: true })
  onClearFiles(newVal: any) {
    if (newVal) {
      this.UploadRef.clearFiles();
    }
  }

  handlePreview(file: any) {
    console.log(file);
  }

  handleExceed() {
    // this.$message.warning('上传图片失败，一条备注不超过3张图片，单张不超过2M!');
    // this.isWarn = true;
  }

  beforeRemove(file: any, fileList: any) {
    return this.$confirm(`确定移除 ${file.name}？`);
  }

  // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
  async beforeAvatarUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2;

    const typeArr = ['image/jpeg', 'image/png', 'image/jpg'];
    const typeBol = typeArr.includes(file.type);
    if (!isLt2M || !typeBol) {
      // this.$message.error('上传图片失败，一条备注不超过3张图片，单张不超过2M!');
      this.isWarn = true;
      return false;
    } else {
      this.isWarn = false;
    }

    return isLt2M && typeBol;
  }

  // 上传成功
  uploadOk(val: any) {
    // console.log('this.isWarn', this.isWarn);

    if (!this.isWarn) {
      this.$emit('uploadOk', val);
    }
  }
}
