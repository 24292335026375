import request from '@/utils/request';

const service = {
  query(params: any, data: any) {
    return request.post(`/api/cms/v1/banner/push/page?pageNum=${params.pageNum}&pageSize=${params.pageSize}`, data);
  },
  created(data: any) {
    return request.post('/api/cms/v1/banner/push/add', data);
  },
  update(data: any) {
    return request.post('/api/cms/v1/banner/push/update', data);
  },
  detail(id: any) {
    return request.get(`/api/cms/v1/banner/push/getByBaNumber/${id}`);
  },
  getByBaNumber(id: any) {
    return request.get(`/api/cms/v1/banner/getByBaNumber/${id}`);
  }
};

export default service;
