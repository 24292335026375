import { render, staticRenderFns } from "./topicGroupDetailUp.vue?vue&type=template&id=65593dcc&scoped=true&"
import script from "./topicGroupDetailUp.vue?vue&type=script&lang=ts&"
export * from "./topicGroupDetailUp.vue?vue&type=script&lang=ts&"
import style0 from "./topicGroupDetailUp.vue?vue&type=style&index=0&id=65593dcc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_sqwtrzbl6s4ypz4dmnoirtpj7i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65593dcc",
  null
  
)

export default component.exports