
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import Sortable from 'sortablejs';
import drawer from './components/drawer.vue';
import YkWarn from '@/components/Yk_Warn/index.vue';
import service from '@/api/questions';
@Component({
  components: { drawer, YkWarn }
})
export default class QuestionCreated extends Vue {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  title = '新增题目';
  queryForm = {
    ask: '', // 题目名称
    questionGroupId: '', // 题组编号
    optionType: '单选', // 选项类型 1单选 2多选
    genderCondition: '全部', // 性别条件 0 无 1 男 2 女
    ageMinCondition: null, // 年龄开始时间
    ageMaxCondition: null, // 年龄结束时间
    classify: '生活建议', // 题目分类
    skinTargetIds: '' as any, // 肌肤问题类型
    questionOptionDrafts: '' as any, // 题目选项
    parentQuestion: '无' // 延展题设置
  } as any;

  skinTypeList = [] as any; // 肌肤问题类型列表
  QuestionGroup = [] as { id: string; code: string }[]; // 题组列表
  tableData = [] as { number: string; content: string; isProblemCauses: number | boolean; actions: string }[]; // 题目选项列表
  letterList = [] as string[]; // 字母列表
  drawerList = [] as any; // 抽屉显示列表(主题、延展题)
  skinTargetIds = [] as any; // 肌肤问题类型--显示
  genderCondition = ''; // 延展题性别条件--显示
  questionTypeNum = 0; // 记录普通题和主题数量
  isDisabledGender = false; // 是否禁用性别
  isDisabledAge = false; // 是否禁用年龄
  disabledQ = true; // 是否禁用延展题设置(未选择题组时禁用)
  isShowDrawer = false; // 是否显示延展题抽屉
  isWarningGroup = false; // 是否提示题组标题警告
  isWarningSkinType = false; // 是否提示 肌肤问题类型警告
  isWarningAsk = false; // 是否提示题目警告
  isWarningContent = false; // 是否提示选项列表警告
  isWarn = false; // 是否提示题目超出数量警告
  warnText = '';
  parentQuestionContent = { code: '', content: [] as string[] }; // 显示主题选项--延展题显示
  // questionOptionDrafts = [] as any;
  parentQuestion = {
    id: '',
    questionOptionDrafts: [] as any
  } as any; // 主题选项集合--延展题

  @Watch('queryForm', { deep: true })
  onChangeEnd(newVal: any) {
    if (newVal.parentQuestion === '无') {
      this.parentQuestionContent = { code: '', content: [] as string[] };
    }
  }

  @Watch('tableData', { deep: true })
  onChangeTableData(newVal: any) {
    if (!newVal.length) {
      this.isWarningContent = false;
    }
  }

  // 题目选项添加行
  handleAdd() {
    let res = '' as any;
    if (this.tableData.length) {
      this.letterList.filter((item: any, index: any) => {
        if (this.tableData[this.tableData.length - 1].number === item) {
          res = index + 1;
        }
      });
      if (res === this.letterList.length) {
        res = 0;
      }
    } else {
      res = 0;
    }

    const row = {
      number: this.letterList[res],
      content: '',
      isProblemCauses: 0,
      actions: ''
    };
    this.tableData.push(row);
  }

  // 删除行
  handleDelete(index: any) {
    this.tableData.splice(index, 1);
  }

  // 创建sortable实例
  initSortable() {
    const ele = document.querySelector('.el-table__body > tbody');
    // 创建拖拽实例
    Sortable.create(ele, {
      animation: 150, // 动画
      disabled: false, // 拖拽不可用? false 启用（刚刚渲染表格的时候起作用，后面不起作用）
      handle: '.move', // 指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
      filter: '.disabled', // 指定不可拖动的类名（el-table中可通过row-class-number设置行的cAs）
      dragClass: 'dragClass', // 设置拖拽样式类名
      ghostClass: 'ghostClass', // 设置拖拽停靠样式类名
      chosenClass: 'chosenClass', // 设置选中样式类名
      // 开始拖动事件
      onStart: () => {
        // console.log('开始拖动');
      },
      onEnd: (event: any) => {
        const tempTableData = [...this.tableData]; // 先存一份临时变量表头数据
        let temp = '' as any;
        temp = tempTableData[event.oldIndex]; //
        tempTableData.splice(event.oldIndex, 1);
        tempTableData.splice(event.newIndex, 0, temp);
        this.tableData = [];
        this.$nextTick(() => {
          this.tableData = tempTableData.map((item: any, index: any) => {
            item.number = this.letterList[index];
            return item;
          });
        });
      }
    });
  }

  // 校验题组ids是否为空
  changeGroupId() {
    if (!this.queryForm.questionGroupId) {
      this.isWarningGroup = true;
      this.disabledQ = true;
    } else {
      this.isWarningGroup = false;
      this.disabledQ = false;
      this.getOtherInGroup();

      this.parentQuestion = { id: '', questionOptionDrafts: [] as any } as any;
      this.parentQuestionContent = { code: '', content: [] as string[] } as any;
      this.queryForm.parentQuestion = '无';
      this.genderCondition = '';
      this.isDisabledGender = false;
      this.isDisabledAge = false;
    }
  }

  // 校验题目名称是否为空
  verifyAsk() {
    if (!this.queryForm.ask) {
      this.isWarningAsk = true;
    } else {
      this.isWarningAsk = false;
    }
  }

  // 校验选项是否为空
  verifyContent() {
    const bol = this.tableData.every((item: any) => item.content);

    if (!bol) {
      this.isWarningContent = true;
    } else {
      this.isWarningContent = false;
    }
  }

  showDrawer() {
    if (!this.disabledQ) {
      this.isShowDrawer = true;
    }
  }

  saveDrawer(list: any) {
    list.forEach((item: any) => {
      item.questionOptionDrafts.forEach((i: any) => {
        if (i.status) {
          this.parentQuestion.id = item.id;
          this.parentQuestion.questionOptionDrafts.push({
            id: i.id,
            content: i.content
          });
          this.parentQuestionContent.code = item.code;
          this.parentQuestionContent.content.push(i.text);
          if (item.genderCondition === 0) {
            this.genderCondition = '全部';
          } else if (item.genderCondition === 1) {
            this.genderCondition = '男';
          } else if (item.genderCondition === 2) {
            this.genderCondition = '女';
          }
          this.queryForm.ageMaxCondition = item.ageMaxCondition;
          this.queryForm.ageMinCondition = item.ageMinCondition;
        }
      });
    });
    // 是延展题的情况 需要获取主题设置，并且禁用
    this.queryForm.parentQuestion = '设置';
    this.queryForm.genderCondition = this.genderCondition;
    this.isDisabledGender = true;
    this.isDisabledAge = true;
    this.isShowDrawer = false;
  }

  async save() {
    this.verifyAsk();
    // this.verifyContent();
    this.isWarningGroup = !this.queryForm.questionGroupId;
    this.isWarningSkinType = !this.queryForm.skinTargetIds.length;
    this.isWarningContent = !this.tableData.length || !this.tableData.every((item: any) => item.content);

    if (this.isWarningGroup || this.isWarningSkinType || this.isWarningAsk || this.isWarningContent) return;

    const params = { ...this.queryForm } as any;
    if (this.queryForm.genderCondition === '全部') {
      params.genderCondition = '0';
    } else if (this.queryForm.genderCondition === '男') {
      params.genderCondition = '1';
    } else if (this.queryForm.genderCondition === '女') {
      params.genderCondition = '2';
    }
    if (this.queryForm.classify === '生活建议') {
      params.classify = '2';
    } else if (this.queryForm.classify === '护肤建议') {
      params.classify = '1';
    } else if (this.queryForm.classify === '医美/就医建议') {
      params.classify = '4';
    }
    if (this.queryForm.optionType === '单选') {
      params.optionType = '1';
    } else if (this.queryForm.optionType === '多选') {
      params.optionType = '2';
    }
    if (this.queryForm.parentQuestion === '无' || this.queryForm.parentQuestion === '主题回答后，回答本题') {
      params.parentQuestion = null;
    } else {
      params.parentQuestion = { ...this.parentQuestion };
    }
    params.questionOptionDrafts = [] as any;
    if (this.tableData.length) {
      this.tableData.forEach((item: any) => {
        params.questionOptionDrafts.push({
          id: null,
          content: item.content,
          isProblemCauses: item.isProblemCauses ? 1 : 0
        });
      });
    } else {
      params.questionOptionDrafts = null;
    }

    if (this.questionTypeNum >= 3 && !this.parentQuestion.id) {
      this.isWarn = true;
    } else if (!this.isWarningGroup && !this.isWarningAsk && !this.isWarningContent) {
      const {
        data: { msg }
      } = await service.saveDraft(params);
      if (msg === '操作成功') {
        this.$notify({
          title: '保存成功',
          message: '',
          type: 'success',
          position: 'bottom-right'
        });
        this.$router.go(-1);
        this.$queryForm.resetFields();
        this.tableData = [];
        this.skinTargetIds = [];
        this.queryForm.ageMinCondition = null;
        this.queryForm.ageMaxCondition = null;
      }
    }
  }

  // 肌肤问题类型修改
  handleSelect(value: any) {
    this.queryForm.skinTargetIds = [] as any;
    this.skinTypeList.forEach((item: any) => {
      value.forEach((val: any) => {
        if (item.name === val) {
          this.queryForm.skinTargetIds.push(item.id);
        }
      });
    });
  }

  // 题组下拉列表
  async getQuestionGroup() {
    const {
      data: { data }
    } = await service.getQuestionGroup();
    this.QuestionGroup = data;
  }

  dimension = 0;
  // 查询皮肤问题类型
  async getByDimension() {
    const {
      data: { data }
    } = await service.getByDimension({ dimension: this.dimension });
    this.skinTypeList = data;
  }

  otherInGroup = {
    questionGroupId: '',
    questionId: ''
  };

  // 查询题组内其他的题目
  async getOtherInGroup() {
    this.questionTypeNum = 0;
    this.otherInGroup.questionGroupId = this.queryForm.questionGroupId;
    const {
      data: { data }
    } = await service.getOtherInGroup(this.otherInGroup);
    this.drawerList = data;
    this.drawerList.forEach((item: any) => {
      if (item.questionOptionDrafts !== null) {
        item.questionOptionDrafts.forEach((i: any, index: any) => {
          i.text = this.letterList[index] + ' ' + i.content;
        });
      }

      if (item.questionType !== 3) {
        this.questionTypeNum++;
      }
      if (item.questionType === 1) {
        if (item.genderCondition === 0) {
          this.genderCondition = '全部';
        } else if (item.genderCondition === 1) {
          this.genderCondition = '男';
        } else if (item.genderCondition === 2) {
          this.genderCondition = '女';
        }
      }
    });
  }

  // 查询题目
  async getDraft(id: any) {
    const {
      data: { data }
    } = await service.getDraft(id);
    Object.keys(this.queryForm).forEach((key: any) => {
      if (key === 'classify' && data[key] === 2) {
        data[key] = '生活建议';
      } else if (key === 'classify' && data[key] === 1) {
        data[key] = '护肤建议';
      } else if (key === 'classify' && data[key] === 4) {
        data[key] = '医美/就医建议';
      }
      if (key === 'optionType' && data[key] === 1) {
        data[key] = '单选';
      } else if (key === 'optionType' && data[key] === 2) {
        data[key] = '多选';
      }
      if (key === 'genderCondition' && data[key] === 0) {
        data[key] = '全部';
      } else if (key === 'genderCondition' && data[key] === 1) {
        data[key] = '男';
      } else if (key === 'genderCondition' && data[key] === 2) {
        data[key] = '女';
      }
      if (key === 'skinTargetIds' && data[key].length) {
        this.skinTypeList.forEach((item: any) => {
          data[key].forEach((id: any) => {
            if (item.id === id) {
              this.skinTargetIds.push(item.name);
            }
          });
        });
      }

      this.queryForm[key] = data[key];
    });
    this.tableData = this.queryForm.questionOptionDrafts;
    this.tableData.forEach((item: any, index: any) => {
      item.number = this.letterList[index];
    });
    // console.log(this.queryForm.skinTargetIds);
  }

  mounted() {
    this.initSortable();
  }

  created() {
    for (let i = 65; i <= 90; i++) {
      this.letterList.push(String.fromCharCode(i));
    }
  }

  activated() {
    this.getQuestionGroup();
    this.getByDimension();
    this.getOtherInGroup();
    this.$queryForm.resetFields();
    this.tableData = [];
    this.skinTargetIds = [];
    this.queryForm.ageMinCondition = null;
    this.queryForm.ageMaxCondition = null;
    this.isDisabledGender = false;
    this.isDisabledAge = false;
    if (this.$route.params.id) {
      this.getDraft(this.$route.params.id);
    }
    this.isWarningGroup = false;
    this.isWarningAsk = false;
    this.isWarningContent = false;
  }
}
