
import { Component, Mixins, Ref, Prop, Watch } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/questions';
import YkWarn from '@/components/Yk_Warn/index.vue';
import questionTable from './table.vue';

@Component({
  components: { questionTable, YkWarn }
})
export default class PageTopic extends Mixins(MixinTable) {
  @Prop({ type: String, required: false })
  title?: string;

  @Prop({ type: Number, required: false })
  isPublishCen?: number;

  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {};
  params = {
    pageNum: 1,
    pageSize: 10
  };

  pageTotal = 0;
  list = [] as any;
  ConfirmFlag = false;
  Confirmcontent = '';
  Confirmdetails = '';
  isPublish = false;
  isUnPublish = false;
  isCancelSort = false;
  isDel = false;
  isWarn = false;
  loading = false;
  id = 0;
  warnTitle = '';
  warnText = '';

  @Watch('isPublishCen')
  onChange(newVal: any) {
    if (newVal) {
      this.getList();
    }
  }

  async editPublish(row: any) {
    const {
      data: { data }
    } = await service.isAssociatedText(row.questionId, {});
    if (data) {
      this.isWarn = true;
      this.warnTitle = '编辑失败';
      this.warnText = '已关联文案的题目无法编辑，请解除关联后重试';
    } else {
      this.$router.push(`/questions/detail/${row.questionId}`);
    }
  }

  delPublish(row: any) {
    if (row.isParent === 1) {
      this.isWarn = true;
      this.warnTitle = '删除失败';
      this.warnText = '请先修改或删除主题关联的延展题后，重新删除';
    } else {
      this.id = row.questionId;
      this.isPublish = false;
      this.isUnPublish = false;
      this.isCancelSort = false;
      this.isDel = true;
      this.ConfirmFlag = true;
      this.Confirmcontent = '是否确定删除题目?';
      this.Confirmdetails =
        '删除将不影响已回答该题组的用户问答结果，并于次日0点生效。关联本题的文案将自动解除与本题的关联，所有变更的题目删除后，将清除待发布的记录';
    }
  }

  // 发布
  publish(row: any) {
    this.id = row.id;
    this.isPublish = true;
    this.isUnPublish = false;
    this.isCancelSort = false;
    this.isDel = false;
    this.ConfirmFlag = true;
    this.Confirmcontent = '是否确定发布题组?';
    this.Confirmdetails = '发布后将不影响已回答该题组的用户问答结果';
  }

  // 撤销
  unPublish(row: any) {
    this.id = row.id;
    this.isUnPublish = true;
    this.isPublish = false;
    this.isCancelSort = false;
    this.isDel = false;
    this.Confirmcontent = '是否确定撤销已发布待生效的变更?';
    this.Confirmdetails = '变更将恢复到待发布状态';
    this.ConfirmFlag = true;
  }

  // 取消排序
  cancelSort(row: any) {
    this.id = row.id;
    this.isCancelSort = true;
    this.isUnPublish = false;
    this.isPublish = false;
    this.isDel = false;
    this.Confirmcontent = '是否取消题目顺序变更，并删除本条发布记录?';
    this.Confirmdetails = '将删除本条待发布的记录';
    this.ConfirmFlag = true;
  }

  // confirmDone确认完成
  async confirmDone() {
    let message = '';
    let title = '';
    let messageText = '';
    // 发布
    if (this.isPublish) {
      const {
        data: { msg }
      } = await service.publishGroup(this.id, {});
      message = msg;
      title = '发布成功';
    } else if (this.isUnPublish) {
      // 撤销
      const {
        data: { msg }
      } = await service.unPublishGroup(this.id, {});
      message = msg;
      title = '撤销成功';
      messageText = '已恢复成待发布状态';
    } else if (this.isCancelSort) {
      // 取消排序
      const {
        data: { msg }
      } = await service.cancelSort(this.id, {});
      message = msg;
      title = '取消排序成功';
    } else if (this.isDel) {
      // 删除题目
      const {
        data: { msg }
      } = await service.delChangeRecord(this.id, {});
      message = msg;
      title = '删除成功';
    }
    if (message === '操作成功') {
      if (this.params.pageNum > 1 && this.list.length <= 1) {
        this.params.pageNum--;
      }
      this.$notify({
        title: title,
        message: messageText,
        type: 'success',
        position: 'bottom-right'
      });
      this.getList();
      this.ConfirmFlag = false;
    }
  }

  handlePageNum(value: number) {
    this.params.pageNum = value;
    this.getList();
  }

  async getList() {
    this.loading = true;
    const {
      data: { data }
    } = await service.query(this.params, this.queryForm);
    if (data) {
      this.$emit('changeTotal', data.total);
      this.pageTotal = data.total;
      this.list = data.records;
      this.list.forEach((item: any) => {
        if (item.modifyType === 1) {
          item.modifyType = '新题组';
        } else if (item.modifyType === 2) {
          item.modifyType = '题数增加';
        } else if (item.modifyType === 3) {
          item.modifyType = '题目顺序调整';
        }
        if (item.status === 1) {
          item.status = '待发布';
        } else if (item.status === 2) {
          item.status = '待生效';
        } else if (item.status === 3) {
          item.status = '已生效';
        }

        item.questionChangeRecords.forEach((question: any) => {
          if (question.questionType === 1) {
            question.questionType = '主题';
          } else if (question.questionType === 2) {
            question.questionType = '普通题';
          } else if (question.questionType === 3) {
            question.questionType = '延展题';
          }
        });
      });
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  // tableRequest = service.questionList;
  get tableColumns() {
    const data: ColumnItem<questionItem>[] = [
      {
        slot: 'code',
        prop: 'code',
        label: '题组编号',
        width: 200
      },
      { label: '组内题数', prop: 'questionSize' },
      { label: '修改类型', prop: 'modifyType' },
      { slot: 'status', label: '状态', prop: 'status', minWidth: 80 },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 250
      }
    ];
    return data;
  }
}
