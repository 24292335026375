
import { Component, Ref, Mixins, Watch } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import service from '@/api/versions';
import serviceC from '@/api/consumerUser';
import OSS from 'ali-oss';
import { getDeviceVersion } from '@/utils/cookies';
import YkWarn from '@/components/Yk_Warn/index.vue';
import md5 from 'js-md5';
@Component({ components: { YkWarn } })
export default class VersionDetail extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  @Ref('upload') readonly $upload!: any;
  queryForm = {
    otaType: '',
    versionNum: '', // 版本号--必填
    url: '', // 升级包地址--必填
    upgrdWay: '手动', // 升级方式 1:手动 2:自动--必填
    model: '', // 设备型号--必填
    illustrate: '', // 版本描述
    upgrdType: '', // 升级类型
    timing: '', // 定时时间
    scope: '', // 授权范围类型：1 设备范围 2:批次
    scopeState: '', // 设备范围(YekerId按逗号分割)
    batch: '', // 批次范围
    md5: '',
    isForce: '非强更'
  } as any;

  title = '版本新增';
  otaType = [
    {
      id: 1,
      name: 'IOS'
    },
    {
      id: 2,
      name: 'Android'
    },
    {
      id: 3,
      name: '固件'
    }
  ];

  upgrdType = [
    {
      id: 1,
      name: '全量'
    },
    {
      id: 2,
      name: '差分'
    }
  ];

  versionScope = [
    {
      id: 1,
      name: '设备范围'
    },
    {
      id: 2,
      name: '以批次授权'
    },
    {
      id: 3,
      name: '无限定范围'
    }
  ];

  scopename = '';
  isdisabled = false;
  isWarn = false;
  isSave = true;
  warnText = '';
  deviceVal = [];
  deviceList = [] as any;
  deviceModel = [] as any;
  deviceBatch = [] as any;
  checkBatchList = [];
  list = [] as any;

  versionScopeList = [] as any; // 接口返回的限定范围
  defaultTime = new Date(2023, 1, 2, 0, 0, 0);
  @Watch('queryForm', { deep: true })
  onChangeQueryForm(newVal: any) {
    if (newVal) {
      if (this.queryForm.upgrdWay === '自动') {
        this.queryForm.isForce = '强更';
      }
      if (
        this.queryForm.versionNum &&
        this.queryForm.upgrdType &&
        this.queryForm.illustrate &&
        this.file &&
        (this.queryForm.upgrdWay === '手动' || (this.queryForm.upgrdWay === '自动' && this.queryForm.timing))
      ) {
        if (
          (this.queryForm.scope === '设备范围' && this.queryForm.scopeState) ||
          (this.queryForm.scope === '以批次授权' && this.queryForm.batch) ||
          this.queryForm.scope === '无限定范围'
        ) {
          this.isSave = false;
        } else {
          this.isSave = true;
        }
      } else {
        this.isSave = true;
      }
      if (this.queryForm.otaType !== '固件' && this.queryForm.versionNum && this.queryForm.illustrate) {
        if ((this.queryForm.otaType === 'Android' && this.file) || this.queryForm.otaType === 'IOS') {
          this.queryForm.scope = '';
          this.isSave = false;
        }
      }
    }
  }

  handleSelectScope(val: any) {
    if (val === '设备范围') {
      this.queryForm.model = '';
      this.deviceBatch = [];
    } else if (val === '以批次授权') {
      this.deviceVal = [];
    }
  }

  handleSelectModel(val: any) {
    if (val) {
      this.getBatchList(val);
    }

    if (!val) {
      this.checkBatchList = [];
    }
  }

  handleScopeChange(val: any) {
    if (val) {
      this.queryForm.scopeState = this.deviceVal.toString();
    }
  }

  changeBatch(val: any) {
    if (val) {
      this.queryForm.batch = this.checkBatchList.toString();
    }
  }

  beforeUpload(file: any) {
    const size = (file.size / 1024 / 1024).toFixed(2) + 'MB';
    if (file.name.endsWith('zip')) {
      this.list = [
        {
          fileName: file.name,
          fileSize: size,
          progress: 0
        }
      ];
      return true;
    } else if (file.name.endsWith('.swu')) {
      this.list = [
        {
          fileName: file.name,
          fileSize: size,
          progress: 0
        }
      ];
      return true;
    } else if (file.name.endsWith('.apk')) {
      this.list = [
        {
          fileName: file.name,
          fileSize: size,
          progress: 0
        }
      ];
      return true;
    } else {
      this.isWarn = true;
      this.warnText = '请检查上传文件类型';
      this.list = [];
      return false;
    }
  }

  file = '' as any;
  // 上传成功
  async uploadOk(val: any) {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(val.file);
    fileReader.onload = e => {
      this.queryForm.md5 = md5(e.target!.result);
    };

    this.file = val.file;
  }

  async submit() {
    if (this.title === '版本新增') {
      const addData = { ...this.queryForm };
      if (this.queryForm.otaType !== '固件') {
        Object.keys(addData).forEach((key: any) => {
          if (key !== 'otaType' && key !== 'versionNum' && key !== 'illustrate') {
            addData[key] = '';
          }
        });
        addData.otaType === 'IOS' ? (addData.otaType = '1') : (addData.otaType = '2');
        addData.md5 = '';
      } else {
        if (addData.upgrdType === '全量') {
          addData.upgrdType = 'all';
        } else if (addData.upgrdType === '差分') {
          addData.upgrdType = 'patch';
        }
        addData.upgrdWay === '手动' ? (addData.upgrdWay = 1 as any) : (addData.upgrdWay = 2 as any);
        addData.isForce === '非强更' ? (addData.isForce = 0 as any) : (addData.isForce = 1 as any);
        if (addData.scope === '设备范围') {
          addData.scope = 1 as any;
        } else if (addData.scope === '以批次授权') {
          addData.scope = 2 as any;
        } else {
          addData.scope = '' as any;
        }
        addData.otaType = '3';
      }

      if (this.queryForm.otaType !== 'IOS') {
        const {
          data: { data }
        } = await serviceC.getOSSToken('device/version/upload');
        if (data) {
          const client = new OSS({
            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
            region: 'oss-cn-shanghai',
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: data.accessKeyId,
            accessKeySecret: data.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: data.securityToken,
            // 填写Bucket名称。
            bucket: data.bucketName
          });
          client
            .multipartUpload(data.pathPrefix + this.file.name, this.file, {
              progress: percent => {
                this.list[0].progress = (percent * 100) as any;
              },
              partSize: 1024 * 1024 * 50
            })
            .then(async res => {
              addData.url = res.name as any;
              const formData = new FormData();
              Object.keys(addData).forEach((key: any) => {
                formData.append(key, addData[key] as any);
              });
              const resData = await service.create(formData);
              if (resData.data.msg === '操作成功') {
                this.$notify({
                  title: '保存成功',
                  message: '',
                  type: 'success',
                  position: 'bottom-right'
                });
                this.$router.go(-1);
              }
            });
        }
      } else {
        const formData = new FormData();
        Object.keys(addData).forEach((key: any) => {
          formData.append(key, addData[key] as any);
        });
        const resData = await service.create(formData);
        if (resData.data.msg === '操作成功') {
          this.$notify({
            title: '保存成功',
            message: '',
            type: 'success',
            position: 'bottom-right'
          });
          this.$router.go(-1);
        }
      }
    }
    // 更新
    if (this.title === '版本编辑') {
      const res = {
        versionId: this.$route.params.versionId,
        scopeType: this.queryForm.scope === '设备范围' ? 1 : 2,
        scopeState: this.deviceVal.length ? this.deviceVal.toString() : '',
        model: this.queryForm.model,
        batch: this.checkBatchList.toString()
      } as any;
      const resformData = new FormData();
      Object.keys(res).forEach((key: any) => {
        resformData.append(key, res[key] as any);
      });
      const {
        data: { msg }
      } = await service.update(resformData);
      if (msg === '操作成功') {
        this.$router.go(-1);
      }
    }
  }

  async getBatchList(model: any) {
    const {
      data: { data }
    } = await service.getBatchList(model);
    this.deviceBatch = data;
  }

  async getModelList() {
    const {
      data: { data }
    } = await service.getModelList();
    this.deviceModel = data;
  }

  async getDevicesList() {
    const params = {
      param: '',
      pageNum: 1,
      pageSize: 100000
    };
    const {
      data: { data }
    } = await service.getDevicesList(params);
    if (data.records) {
      data.records.forEach((item: any) => {
        item.label = item.clientId;
        item.key = item.clientId;
      });
      this.deviceList = data.records;
    } else {
      this.deviceList = [];
    }
  }

  async created() {
    this.getModelList();
    this.getDevicesList();
  }

  async activated() {
    this.$queryForm.resetFields();
    this.isdisabled = false;
    this.title = '版本新增';
    this.queryForm.model = '';
    this.queryForm.md5 = '';
    this.checkBatchList = [];
    this.deviceVal = [];
    this.list = [];
    this.$upload.clearFiles();

    if (getDeviceVersion()) {
      this.title = '版本编辑';
      this.isdisabled = true;
      const params = JSON.parse(getDeviceVersion() as any);
      const url = params.url.split('/');
      this.tableColumns.forEach((item: any, index: any) => {
        if (item.label === '文件大小') {
          this.tableColumns.splice(index, 1);
        }
      });
      this.list = [
        {
          fileName: url[url.length - 1],
          progress: 100
        }
      ];
      Object.keys(params).forEach((key: any) => {
        this.queryForm[key] = params[key] || null;
      });
      this.queryForm.upgrdWay === 1 ? (this.queryForm.upgrdWay = '手动') : (this.queryForm.upgrdWay = '自动');
      this.queryForm.isForce === 1 ? (this.queryForm.isForce = '强更') : (this.queryForm.isForce = '非强更');
      if (this.queryForm.otaType === 1) {
        this.queryForm.otaType = 'IOS';
      } else if (this.queryForm.otaType === 2) {
        this.queryForm.otaType = 'Android';
      } else if (this.queryForm.otaType === 3) {
        this.queryForm.otaType = '固件';
      }
      const {
        data: { data }
      } = await service.getVersionScope(params.id);
      if (data) {
        if (data.scopeType === 1) {
          this.queryForm.scope = '设备范围';
        } else if (data.scopeType === 2) {
          this.queryForm.scope = '以批次授权';
        }
        if (data.scopeModel.length) {
          this.queryForm.model = data.scopeModel[0];
          this.getBatchList(data.scopeModel[0]);
        }
        if (data.scopeBatch) {
          this.checkBatchList = data.scopeBatch;
        }
        if (data.clientId && data.scopeType === 1) {
          this.deviceVal = data.clientId;
        }
      } else {
        this.queryForm.scope = '无限定范围';
      }
    }
  }

  get tableColumns() {
    const data: ColumnItem<VersionsDetItem>[] = [
      { label: '文件名称', prop: 'fileName' },
      { label: '文件大小', prop: 'fileSize' },
      {
        slot: 'actions',
        prop: 'actions',
        label: '上传进度'
      }
    ];
    return data;
  }
}
