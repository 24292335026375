import request from '@/utils/request';

const service = {
  //   查询面部问题类型
  getByType(type: any) {
    return request.get(`/api/skin/v1/target/getByType/${type}`);
  },
  //   变化报告--编辑标签
  skinTagEdit(data: any) {
    return request.post('/api/skin/v1/tag/draft/edit', data);
  },
  //   变化报告--标签详情
  skinTagDetail(params: any) {
    return request.post(`/api/skin/v1/tag/draft/${params.targetId}/${params.apply}`);
  },
  //   形成因素编辑
  causeEdit(params: any, data: any) {
    return request.post(`/api/skin/v1/report/cause/draft/edit/${params.targetId}`, data);
  },
  //   形成因素详情
  causeDetail(params: any) {
    return request.get(`/api/skin/v1/report/cause/draft/detail/${params.targetId}`);
  },
  //   解决方案-首次报告编辑
  firstReportEdit(data: any) {
    return request.post('/api/skin/v1/first/report/draft/edit', data);
  },
  //   解决方案-首次报告详情
  firstReportDetail(data: any) {
    return request.post('/api/skin/v1/first/report/draft/detail', data);
  },
  //   解决方案-变化报告编辑
  changeReportEdit(data: any) {
    return request.post('/api/skin/v1/change/report/draft/edit', data);
  },
  //   解决方案-变化报告详情
  changeReportDetail(data: any) {
    return request.post('/api/skin/v1/change/report/draft/detail', data);
  },
  //   解决方案-追加文案下拉列表
  appendContentDownList(params: any) {
    return request.get(
      `/api/skin/v1/report/reportTextDraft/appendContentDownList?skinTargetId=${params.skinTargetId}&symptom=${params.symptom}&type=${params.type}&textType=${params.textType}`
    );
  },
  //   解决方案-列表
  getSolution(params: any) {
    return request.get(
      `/api/skin/v1/report/reportTextDraft/getSolution?skinTargetId=${params.skinTargetId}&symptom=${params.symptom}&type=${params.type}&textType=${params.textType}&appendTextContentId=${params.appendTextContentId}`
    );
  },
  //   解决方案-获取文案详情列表
  getReportTextDetailList(id: any) {
    return request.get(`/api/skin/v1/report/reportTextDraft/getReportTextDetailList?reportTextContentId=${id}`);
  },
  //   解决方案-变化报告  指定文案问题
  changeQuestionAppoint(data: any) {
    return request.post('/api/skin/v1/append/text/question/appoint', data);
  },
  //   解决方案-变化报告  题目列表
  changeReportQuestion(data: any) {
    return request.post('/api/skin/v1/change/report/draft/question/list', data);
  },
  //   解决方案-变化报告  已改善/已恢复 查询
  getGoodSolution(params: any) {
    return request.get(
      `/api/skin/v1/report/reportTextDraft/getGoodSolution?skinTargetId=${params.skinTargetId}&symptom=${params.symptom}`
    );
  },
  //   解决方案-变化报告  已改善/已恢复 保存
  saveGoodSolution(data: any) {
    return request.post('/api/skin/v1/report/reportTextDraft/saveGoodSolution', data);
  }
};

export default service;
