
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import RoleDetail from './detail.vue';
import YkWarn from '@/components/Yk_Warn/index.vue';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/role';
@Component({
  components: { RoleDetail, YkWarn }
})
export default class PageRole extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    name: '',
    orders: [
      {
        column: 'member_num',
        asc: false
      }
    ]
  };

  params = {
    pageNum: 1,
    pageSize: 10
  };

  list = [] as any;
  pageTotal = 0;
  roleFlag = false;
  isRotate = false;
  rolePer = null;
  isWarn = false;
  warnTitle = '';
  warnText = '';
  ConfirmFlag = false;
  loading = false;
  roleId = null;
  add() {
    this.roleFlag = true;
    this.rolePer = null;
  }

  async edit(row = {} as any) {
    if (row.name === '超级管理员' || row.name === '未授权用户') {
      this.isWarn = true;
      this.warnTitle = '编辑删除失败';
      this.warnText = '默认角色无法修改';
    } else {
      this.roleFlag = true;
      const {
        data: { data }
      } = await service.detail(row.id);
      this.rolePer = data;
    }
  }

  done() {
    this.roleFlag = false;
    this.getList();
  }

  async del(row: any) {
    this.roleId = row.id;
    service
      .roleCanDelCheck(row)
      .then(() => {
        this.ConfirmFlag = true;
        this.roleFlag = false;
      })
      .catch((err: any) => {
        this.isWarn = true;
        if (err.data.msg === '默认角色不能修改') {
          this.warnTitle = '编辑删除失败';
        } else {
          this.warnTitle = '删除失败';
        }
        this.warnText = err.data.msg;
      });
  }

  async confirmDone() {
    this.ConfirmFlag = false;
    const {
      data: { msg }
    } = await service.remove(this.roleId);
    if (msg === '操作成功') {
      if (this.params.pageNum > 1 && this.list.length <= 1) {
        this.params.pageNum--;
      }
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.getList();
    }
  }

  onReset() {
    this.params.pageNum = 1;
    this.$queryForm.resetFields();
    this.roleFlag = false;
    this.getList();
  }

  handlePageNum(value: number) {
    this.params.pageNum = value;
    this.getList();
  }

  sortChange(val: any) {
    if (val.order === 'descending' || val.order === null) {
      this.queryForm.orders[0].asc = false;
    } else if (val.order === 'ascending') {
      this.queryForm.orders[0].asc = true;
    }
    this.getList();
  }

  async getList() {
    this.loading = true;
    const {
      data: { data }
    } = await service.query(this.params, this.queryForm);
    this.pageTotal = data.total;
    if (data.records !== null) {
      this.list = data.records;
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  activated() {
    this.onReset();
  }

  // table
  removeRequest = service.remove;
  get tableColumns() {
    const data: ColumnItem<RoleItem>[] = [
      { label: '角色名称', prop: 'name' },
      { label: '成员数量', prop: 'memberNum', sortable: 'custom' },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 200
      }
    ];
    return data;
  }
}
