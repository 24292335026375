
import { Component, Mixins, Ref, Prop, Watch } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/banner/material';
import serviceC from '@/api/consumerUser';
import drawer from './component/drawer.vue';
import BannerTable from './component/table.vue';
import OSS from 'ali-oss';

@Component({
  components: { drawer, BannerTable }
})
export default class PageMaterial extends Mixins(MixinTable) {
  @Prop({ type: Array, required: true })
  tagList!: [];

  @Prop({ type: String, required: true })
  title!: '';

  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    desc: '',
    type: '', // 素材类型
    status: '', // 是否使用
    tagName: '' // 用户画像
  };

  params = {
    pageNum: 1,
    pageSize: 10,
    searchParam: ''
  };

  list = [] as any;
  pageTotal = 0;
  currentPage = 0;
  currentSize = 0;
  loading = false;
  ConfirmFlag = false;
  isShowDrawer = false;
  materialType = [] as any;
  srcList = [] as any;
  materialDetail = {} as any;
  status = [
    {
      value: '0',
      label: '全部'
    },
    {
      value: '2',
      label: '已使用'
    },
    {
      value: '1',
      label: '未使用'
    }
  ];

  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  @Watch('title', { immediate: true })
  onChangeTitle(val: any) {
    if (val === '素材库') {
      this.getList();
      this.getType();
    }
  }

  async ShowDrawer(row: any) {
    this.srcList = [];
    this.isShowDrawer = true;
    const {
      data: { data }
    } = await service.getByNumber(row.number);
    this.materialDetail = { ...data };
    if (this.materialDetail.status === 1) {
      this.materialDetail.status = '未使用';
    } else if (this.materialDetail.status === 2) {
      this.materialDetail.status = '已使用';
    }
    if (this.materialDetail.type === 1) {
      this.materialDetail.type = '日常';
    } else if (this.materialDetail.type === 2) {
      this.materialDetail.type = '活动';
    } else {
      this.materialDetail.type = '个性化';
    }
    if (!this.materialDetail.tagName) {
      this.materialDetail.tagName = '所有用户';
      this.materialDetail.tagColor = '';
    }
    const res = await serviceC.getOSSToken('user/material/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + this.materialDetail.smUrl, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
    }
  }

  async copy(row: any) {
    this.$router.push({
      path: '/material/detail',
      query: {
        id: row.id
      }
    });
  }

  materialId = '';
  del(row: any) {
    this.ConfirmFlag = true;
    this.materialId = row.id;
  }

  async confirmDone() {
    const {
      data: { msg }
    } = await service.remove(this.materialId);
    if (msg === '操作成功') {
      if (this.params.pageNum > 1 && this.list.length <= 1) {
        this.params.pageNum--;
      }
      this.ConfirmFlag = false;
      this.getList();
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
    }
  }

  changeTag(value: any) {
    this.tagList.forEach((item: any) => {
      if (item.id === value) {
        this.queryForm.tagName = item.name;
      }
    });
  }

  onQuery() {
    this.params.pageNum = 1;
    this.currentPage = 1;
    this.getList();
  }

  onReset() {
    this.currentSize = 10;
    this.currentPage = 1;
    this.params = {
      pageNum: 1,
      pageSize: 10,
      searchParam: ''
    };

    this.$queryForm.resetFields();
    this.getList();
  }

  // table 每页显示条数改变
  tablePageSizeChange(pageSize: number) {
    this.change({ pageSize, pageNum: 1 });
  }

  // table 当前显示页改变
  tablePageChange(pageNum: number) {
    this.change({ pageNum });
  }

  // 触发表格刷新
  change(newPageInfo: any) {
    this.params = Object.assign({}, this.params, newPageInfo);
    this.getList();
  }

  urlList = [] as any;
  async getList() {
    this.loading = true;
    const {
      data: { data }
    } = await service.query(this.params, this.queryForm);
    this.pageTotal = data.total;
    if (data.records !== null) {
      this.list = data.records;
      this.list.forEach(async (item: any) => {
        if (item.status === 1) {
          item.status = '未使用';
        } else if (item.status === 2) {
          item.status = '已使用';
        }
        if (item.type === 1) {
          item.type = '日常';
        } else if (item.type === 2) {
          item.type = '活动';
        } else {
          item.type = '个性化';
        }
        if (!item.tagName) {
          item.tagName = '所有用户';
          item.tagColor = '';
        }
        const res = await serviceC.getOSSToken('user/material/upload');
        if (res.data.data) {
          const client = new OSS({
            region: 'oss-cn-shanghai',
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: res.data.data.accessKeyId,
            accessKeySecret: res.data.data.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: res.data.data.securityToken,
            // 填写Bucket名称。
            bucket: res.data.data.bucketName
          });
          if (item.smUrl.includes('user/material/upload')) {
            const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + item.smUrl, {
              process: 'image/resize,w_200' // 设置图片处理参数。
            });
            item.smUrl = url;
          }
        }
      });
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  async getType() {
    const {
      data: { data }
    } = await service.getType();
    if (data) {
      this.materialType = data;
    } else {
      this.materialType = [];
    }
  }

  activated() {
    this.getList();
  }

  // table
  // tableRequest = service.query;
  get tableColumns() {
    const data: ColumnItem<materialItem>[] = [
      { slot: 'smUrl', label: '预览图', prop: 'smUrl', minWidth: 160 },
      { slot: 'number', label: '素材编号', prop: 'number', minWidth: 130 },
      { label: '素材类型', prop: 'type' },
      { slot: 'status', label: '是否使用', prop: 'status', minWidth: 90 },
      { label: '创建时间', prop: 'createdOn', minWidth: 150 },
      { slot: 'tagName', label: '用户画像', prop: 'tagName' },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 260
      }
    ];
    return data;
  }
}
