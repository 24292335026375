
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import service from '@/api/reportFile';
import { ElForm } from 'element-ui/types/form';
@Component({ components: {} })
export default class FactorDetail extends Vue {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  title = '';
  ConfirmFlag = false;
  isWarningAll = false;
  isWarning = false;
  WarnAllText = '请填写必填项';
  list = [] as {
    targetId: string;
    description: string;
    seq: number;
  }[];

  alllist = [] as any;
  mlist = [] as any;
  @Watch('alllist.length')
  onChangeAllLength(newVal: any) {
    if (!newVal) {
      this.isWarningAll = false;
    }
  }

  @Watch('mlist.length')
  onChangeLength(newVal: any) {
    if (!newVal) {
      this.isWarning = false;
    }
  }

  verifydes() {
    this.isWarning = !this.mlist.every((item: any) => item.description);
  }

  verifydesAll() {
    this.isWarningAll = !this.alllist.every((item: any) => item.description);
  }

  edit(value?: string, index?: number) {
    if (value === '总段落') {
      this.alllist[0].disabled = false;
    } else if (value === '分段落') {
      this.mlist.forEach((item: any, i: any) => {
        if (index === i) {
          item.disabled = false;
        }
      });
    }
  }

  del(value?: string, index?: number) {
    if (value === '总段落') {
      this.alllist = [];
    }
    if (value === '分段落') {
      this.mlist.splice(index!, 1);
      this.mlist.forEach((item: any, index: any) => {
        item.seq = index + 1;
      });
    }
  }

  handleAdd(value: string) {
    const row = {
      description: '',
      seq: 1,
      disabled: true
    };
    if (value === '总段落') {
      row.seq = 0;
      this.alllist.push(row);
    } else if (value === '分段落' && this.mlist.length < 20) {
      this.mlist.forEach((item: any) => {
        row.seq = item.seq + 1;
      });
      this.mlist.push(row);
    }
  }

  async activated() {
    this.title = '编辑' + ' ' + this.$route.query.targetName + '-形成因素';
    let list = [] as any;
    this.isWarning = false;
    this.isWarningAll = false;
    if (this.$route.params) {
      const {
        data: { data }
      } = await service.causeDetail(this.$route.params);
      list = data;
      list.forEach((item: any) => {
        item.disabled = true;
      });
      this.alllist = list.filter((item: any) => !item.seq);
      this.mlist = list.filter((item: any) => item.seq);
    }
  }

  closeConfirm() {
    this.ConfirmFlag = false;
    this.$router.go(-1);
  }

  verify() {
    if (!this.alllist.length && !this.mlist.length) {
      this.isWarningAll = true;
      this.WarnAllText = '至少添加一个总段落或分段落';
      return;
    } else {
      this.isWarningAll = false;
    }
    if (!this.alllist.every((item: any) => item.description)) {
      this.isWarningAll = true;
      this.WarnAllText = '请填写必填项';
      return;
    }
    this.isWarning = !this.mlist.every((item: any) => item.description);
  }

  save() {
    this.verify();
    if (this.isWarning || this.isWarningAll) return;
    this.ConfirmFlag = true;

    this.list = [];
    if (this.alllist.length) {
      this.list.push({
        targetId: this.$route.params.targetId,
        description: this.alllist[0].description,
        seq: this.alllist[0].seq
      });
    }
    this.mlist.forEach((item: any, index: any) => {
      this.list.push({
        targetId: this.$route.params.targetId,
        description: item.description,
        seq: index + 1
      });
    });
  }

  // 确认保存
  async confirmDone() {
    const {
      data: { msg }
    } = await service.causeEdit(this.$route.params, this.list);
    if (msg === '操作成功') {
      this.$notify({
        title: '保存成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.closeConfirm();
    }
  }
}
