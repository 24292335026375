
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import service from '@/api/consumerTag';
import { ElForm } from 'element-ui/types/form';
import YkWarn from '@/components/Yk_Warn/index.vue';

@Component({ components: { YkWarn } })
export default class PageConsumerTag extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    likeName: null,
    orders: [
      {
        column: 'modified_on',
        asc: false
      }
    ]
  };

  params = {
    pageNum: 1,
    pageSize: 10
  };

  list = [] as any;
  pageTotal = 0;
  loading = false;
  isWarn = false;
  ConfirmFlag = false;
  isShow = false;
  portraitId = null;
  warnTitle = '';
  warnText = '';
  add() {
    this.$router.push('/consumerTag/detail');
  }

  edit(id: any) {
    this.$router.push(`/consumerTag/detail/${id}`);
  }

  async del(row: any) {
    this.portraitId = row.id;
    service
      .tagCanDelCheck(row.id)
      .then(() => {
        this.ConfirmFlag = true;
      })
      .catch((err: any) => {
        this.isWarn = true;
        this.warnText = err.data.msg;
      });
  }

  // 删除完成
  async confirmDone() {
    const {
      data: { msg }
    } = await service.remove(this.portraitId);
    if (msg === '操作成功') {
      if (this.params.pageNum > 1 && this.list.length <= 1) {
        this.params.pageNum--;
      }
      this.ConfirmFlag = false;
      this.getList();
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
    }
  }

  sortChange(val: any) {
    if (val.order === 'descending') {
      this.queryForm.orders[0].asc = false;
    } else {
      this.queryForm.orders[0].asc = true;
    }
    this.getList();
  }

  onReset() {
    this.params.pageNum = 1;
    this.$queryForm.resetFields();
    this.getList();
  }

  handlePageNum(value: number) {
    this.params.pageNum = value;
    this.getList();
  }

  async getList() {
    this.loading = true;
    const {
      data: { data }
    } = await service.query(this.params, this.queryForm);
    this.pageTotal = data.total;

    this.list = data.records;
    this.list.forEach((item: any) => {
      if (item.type === 1) {
        item.type = '系统';
      } else {
        item.type = '人工';
      }
    });
    this.loading = false;
  }

  activated() {
    this.onReset();
  }

  // table
  tableRequest = service.query;
  removeRequest = service.remove;
  get tableColumns() {
    const data: ColumnItem<consumerTagItem>[] = [
      { label: '画像名称', prop: 'name' },
      { label: '画像类型', prop: 'type' },
      { label: '画像说明', prop: 'description', tooltip: true },
      { label: '修改时间', prop: 'modifiedOn', sortable: 'custom' },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 200
      }
    ];
    return data;
  }
}
