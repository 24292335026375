
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import service from '@/api/reportFile';
import drawerDetail from './drawerDetail.vue';
import { setSkinType, getSkinType } from '@/utils/cookies';

@Component({
  components: { drawerDetail }
})
export default class ContrastReport extends Vue {
  @Prop({ type: String, required: true })
  mode!: string;

  @Prop({ type: String, required: true })
  skinQuestionType!: string; // 肌肤问题类型

  @Prop({ type: [Object], default: () => ({}) })
  skinTagInfo!: Record<string, any>; // 指标id  apply:0首次报告 1变化报告

  radio = '首次报告';
  planTitle = '解决方案';
  optionSkin = ''; // 变化报告-护肤选择选项进行查询
  optionLive = ''; // 变化报告-生活选择选项进行查询
  optionDoctor = ''; // 变化报告-医美选择选项进行查询
  contrastType = ''; // 变化报告的肤质敏感程度- 一级
  planChildType = ''; // 变化报告的肤质敏感程度-二级
  firstTextType = '通用版'; // 首次报告的肤质敏感程度
  isDeitGeneral = false; // 是否修改通用文案
  generalText = ''; // 通用文案内容
  isShowDrawer = false; // 是否打开抽屉
  isShowRadio = true;
  dataLoading = false;

  isWarning = false;
  ConfirmFlag = false;
  disabledText = true;
  warnText = '';
  firstParams = {
    skinTargetId: '1',
    textType: '1',
    type: ''
  };

  changeParams = {
    skinTargetId: '',
    symptom: '',
    type: '',
    textType: '1',
    appendTextContentId: ''
  } as any;

  firstReport = {} as any;
  firstPlanList = ['通用版', '干性不敏感', '油性不敏感', '干性敏感', '油性敏感'];

  idxArray = [
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
    '十',
    '十一',
    '十二',
    '十三',
    '十四',
    '十五',
    '十六',
    '十七',
    '十八',
    '十九',
    '二十'
  ];

  skinList = [] as any; // 变化报告的护肤建议
  liveList = [] as any; // 变化报告的生活建议
  doctorList = [] as any; // 变化报告的医美就医建议
  skinTableList = [] as any; // 变化报告的护肤建议
  liveTableList = [] as any; // 变化报告的生活建议
  doctorTableList = [] as any; // 变化报告的医美就医建议

  contrastPlanList = [
    {
      title: '新问题/变严重/持续中',
      symptom: '0',
      iconName: 'el-icon-arrow-up',
      children: [
        {
          title: '通用版',
          textType: '1',
          isDefault: '1'
        },
        {
          title: '干性不敏感',
          textType: '1',
          isDefault: '1'
        },
        {
          title: '油性不敏感',
          textType: '1',
          isDefault: '1'
        },
        {
          title: '干性敏感',
          textType: '1',
          isDefault: '1'
        },
        {
          title: '油性敏感',
          textType: '1',
          isDefault: '1'
        }
      ]
    },
    {
      title: '已改善',
      symptom: '-1',
      iconName: 'el-icon-arrow-up',
      children: []
    },
    {
      title: '已恢复',
      symptom: '-2',
      iconName: 'el-icon-arrow-up',
      children: []
    }
  ];

  contrastSuggestList = [
    {
      title: '护肤建议',
      tableData: []
    },
    {
      title: '生活建议',
      tableData: []
    },
    {
      title: '医美就医建议',
      tableData: []
    }
  ] as any;

  letterList = [] as any; // 26位字母列表数据
  changeSkinId = ['8', '9', '10', '11', '12', '14'];
  firstSkinId = ['13', '15', '16'];

  @Watch('skinTagInfo', { deep: true, immediate: true })
  async onChangeInfo(newVal: any) {
    if (newVal) {
      this.firstParams.skinTargetId = newVal.targetId;
      this.changeParams.skinTargetId = newVal.targetId;
    }
    if (this.mode === '全部' && this.firstSkinId.includes(newVal.targetId)) {
      this.planTitle = '首次报告解决方案';
      this.isShowRadio = false;
      this.radio = '首次报告';
    } else if (this.mode === '全部' && this.changeSkinId.includes(newVal.targetId)) {
      this.planTitle = '变化报告解决方案';
      this.isShowRadio = false;
    } else if (
      (this.mode === '全部' && !this.changeSkinId.includes(newVal.targetId)) ||
      (this.mode === '全部' && !this.firstSkinId.includes(newVal.targetId))
    ) {
      this.planTitle = '解决方案';
      this.isShowRadio = true;
    }
    if ((newVal.targetId && this.mode !== '首次报告') || (newVal.targetId && this.radio === '变化报告')) {
      this.changeParams.symptom = '0';
      this.sysNum = 3;
    }
    this.optionSkin = '';
    this.optionLive = '';
    this.optionDoctor = '';
    this.changeParams.appendTextContentId = '';
  }

  @Watch('radio')
  onChangeRadio(newVal: any) {
    this.optionSkin = '';
    this.optionLive = '';
    this.optionDoctor = '';
    this.changeParams.appendTextContentId = '';
    if (newVal === '首次报告') {
      this.getFirstReport();
      this.sysNum = 5;
      this.firstTextType = '通用版';
    }
    if (newVal === '变化报告') {
      this.planChildType = '通用版';
      this.contrastType = '';
      this.changeParams.type = 1;
      this.changeParams.symptom = '0';
      this.sysNum = 3;
      // if (this.changeParams.symptom === '0' && this.sysNum === 3) {
      //   this.changeParams.type = 1;
      //   this.sysNum = 2;
      //   this.getSolution(this.changeParams);
      // }
      // if (this.changeParams.symptom === '0' && this.sysNum === 2) {
      //   this.changeParams.type = 2;
      //   this.sysNum = 1;
      //   this.getSolution(this.changeParams);
      // }
      // if (this.changeParams.symptom === '0' && this.sysNum === 1) {
      //   this.changeParams.type = 4;
      //   this.sysNum = 0;
      //   this.getSolution(this.changeParams);
      // }
    }
  }

  @Watch('mode')
  onChangeMode(newVal: any) {
    if (newVal === '全部') {
      this.planTitle = '解决方案';
      this.radio = '首次报告';
      this.isShowRadio = true;
    } else if (newVal === '变化报告') {
      this.planTitle = '变化报告解决方案';
      this.radio = '变化报告';
      this.changeParams.type = 1;
      this.changeParams.symptom = '0';
      this.sysNum = 3;
      this.isShowRadio = false;

      this.isShowRadio = false;
    } else if (newVal === '首次报告') {
      this.planTitle = '首次报告解决方案';
      this.radio = '首次报告';
      this.isShowRadio = false;
    }
    this.optionSkin = '';
    this.optionLive = '';
    this.optionDoctor = '';
    this.changeParams.appendTextContentId = '';
  }

  @Watch('firstTextType')
  async onChangeTextType(newVal: any) {
    if (newVal === '通用版') {
      this.firstParams.textType = '1';
    } else if (newVal === '干性不敏感') {
      this.firstParams.textType = '2';
    } else if (newVal === '油性不敏感') {
      this.firstParams.textType = '3';
    } else if (newVal === '干性敏感') {
      this.firstParams.textType = '4';
    } else if (newVal === '油性敏感') {
      this.firstParams.textType = '5';
    }
  }

  // 监听变化报告的 新增/持续/加重文案类型
  @Watch('planChildType')
  async onChangePlanChildType(newVal: any) {
    if (newVal === '通用版') {
      this.changeParams.textType = '1';
      this.sysNum = 3;
    } else if (newVal === '干性不敏感') {
      this.changeParams.textType = '2';
      this.sysNum = 3;
    } else if (newVal === '油性不敏感') {
      this.changeParams.textType = '3';
      this.sysNum = 3;
    } else if (newVal === '干性敏感') {
      this.changeParams.textType = '4';
      this.sysNum = 3;
    } else if (newVal === '油性敏感') {
      this.changeParams.textType = '5';
      this.sysNum = 3;
    }
    this.optionSkin = '';
    this.optionLive = '';
    this.optionDoctor = '';
    this.changeParams.symptom = '0';
  }

  // 监听变化报告的 改善、消退类型
  @Watch('contrastType')
  async onChangeContrastType(newVal: any) {
    if (newVal === '已改善') {
      this.changeParams.symptom = '-1';
    } else if (newVal === '已恢复') {
      this.changeParams.symptom = '-2';
    }
    this.optionSkin = '';
    this.optionLive = '';
    this.optionDoctor = '';
    this.changeParams.appendTextContentId = '';
    this.isWarning = false;
    this.isDeitGeneral = false;
    this.disabledText = true;
    this.getGoodSolution({ skinTargetId: this.changeParams.skinTargetId, symptom: this.changeParams.symptom });
  }

  @Watch('firstParams', { deep: true })
  async onChangeParams(newVal: any) {
    if ((newVal && this.mode === '首次报告') || (newVal && this.radio === '首次报告')) {
      this.getFirstReport();
    }
  }

  sysNum = 3;
  @Watch('changeParams', { deep: true })
  async onChangeParam(newVal: any) {
    if (this.contrastType === '已改善') {
      this.changeParams.symptom = '-1';
      this.getGoodSolution({ skinTargetId: this.changeParams.skinTargetId, symptom: this.changeParams.symptom });
    } else if (this.contrastType === '已恢复') {
      this.changeParams.symptom = '-2';
      this.getGoodSolution({ skinTargetId: this.changeParams.skinTargetId, symptom: this.changeParams.symptom });
    }
    if (newVal.symptom === '0' && this.sysNum === 3) {
      newVal.type = 1;
      this.sysNum = 2;
      this.getSolution(newVal);
    }
    if (newVal.symptom === '0' && this.sysNum === 2) {
      newVal.type = 2;
      this.sysNum = 1;
      this.getSolution(newVal);
    }
    if (newVal.symptom === '0' && this.sysNum === 1) {
      newVal.type = 4;
      this.sysNum = 0;
      this.getSolution(newVal);
    }
  }

  @Watch('optionSkin')
  async onChangeOptionSkin(newVal: any) {
    if (newVal === '') {
      this.changeSkin('');
    }
  }

  @Watch('optionLive')
  async onChangeOptionLive(newVal: any) {
    if (newVal === '') {
      this.changeLive('');
    }
  }

  @Watch('optionDoctor')
  async onChangeOptionDoctor(newVal: any) {
    if (newVal === '') {
      this.changeDoctor('');
    }
  }

  // 展开变化报告的肌肤敏感度
  handleUnfold(value: string) {
    this.contrastPlanList.forEach((item: any) => {
      if (value === item.title && item.iconName.includes('arrow-down')) {
        item.iconName = 'el-icon-arrow-up';
        this.planChildType = '通用版';
        this.contrastType = '';
      } else if (value === item.title && item.iconName.includes('arrow-up')) {
        item.iconName = 'el-icon-arrow-down';
      }
    });
  }

  verify() {
    if (!this.generalText) {
      this.isWarning = true;
    } else {
      this.isWarning = false;
    }
  }

  async changeSkin(value: any) {
    this.dataLoading = true;
    this.changeParams.type = 1;
    this.changeParams.appendTextContentId = value;
    this.sysNum = 0;
    let list = [] as any;
    this.skinTableList.forEach((item: any) => {
      item.appendTextContent = [];
      item.eventType = [];
    });
    const {
      data: { data }
    } = await service.getSolution(this.changeParams);
    if (data !== null) {
      data.reportTextContents.forEach((report: any, index: any) => {
        this.skinTableList.forEach((item: any) => {
          if (value) {
            item.query = true;
            item.index = index + 1;
            report.appendReportTextContent !== null
              ? item.appendTextContent.push(report.appendReportTextContent.description)
              : item.appendTextContent.push('-');
            report.appendReportTextContent !== null
              ? item.eventType.push(report.appendReportTextContent.eventType)
              : item.eventType.push(null);
          } else {
            item.query = false;
          }
        });
      });
      list = this.skinTableList.filter((item: any, index: any) => index < data.reportTextContents.length);
      this.contrastSuggestList[0].tableData = JSON.parse(JSON.stringify(list)); // 解决数据更新视图不更新
    }
    this.dataLoading = false;
  }

  async changeLive(value: any) {
    this.dataLoading = true;
    this.changeParams.type = 2;
    this.changeParams.appendTextContentId = value;
    this.sysNum = 0;
    let list = [] as any;

    this.liveTableList.forEach((item: any) => {
      item.appendTextContent = [];
      item.eventType = [];
    });
    const {
      data: { data }
    } = await service.getSolution(this.changeParams);
    if (data !== null) {
      data.reportTextContents.forEach((report: any, index: any) => {
        this.liveTableList.forEach((item: any) => {
          if (value) {
            item.query = true;
            item.index = index + 1;

            report.appendReportTextContent !== null
              ? item.appendTextContent.push(report.appendReportTextContent.description)
              : item.appendTextContent.push('-');
            report.appendReportTextContent !== null
              ? item.eventType.push(report.appendReportTextContent.eventType)
              : item.eventType.push(null);
          } else {
            item.query = false;
          }
        });
      });
      list = this.liveTableList.filter((item: any, index: any) => index < data.reportTextContents.length);
      this.contrastSuggestList[1].tableData = JSON.parse(JSON.stringify(list)); // 解决数据更新视图不更新
    }
    this.dataLoading = false;
  }

  async changeDoctor(value: any) {
    this.dataLoading = true;
    this.changeParams.type = 4;
    this.changeParams.appendTextContentId = value;
    this.sysNum = 0;
    let list = [] as any;
    this.doctorTableList.forEach((item: any) => {
      item.appendTextContent = [];
      item.eventType = [];
    });
    const {
      data: { data }
    } = await service.getSolution(this.changeParams);
    if (data !== null) {
      data.reportTextContents.forEach((report: any, index: any) => {
        this.doctorTableList.forEach((item: any) => {
          if (value) {
            item.query = true;
            item.index = index + 1;
            report.appendReportTextContent !== null
              ? item.appendTextContent.push(report.appendReportTextContent.description)
              : item.appendTextContent.push('-');
            report.appendReportTextContent !== null
              ? item.eventType.push(report.appendReportTextContent.eventType)
              : item.eventType.push(null);
          } else {
            item.query = false;
          }
        });
      });
      list = this.doctorTableList.filter((item: any, index: any) => index < data.reportTextContents.length);
      this.contrastSuggestList[2].tableData = JSON.parse(JSON.stringify(list)); // 解决数据更新视图不更新
    }
    this.dataLoading = false;
  }

  // 获取下拉列表数据
  async focusNum(value: any) {
    const params = {} as any;
    this.skinList = [] as any;
    this.liveList = [] as any;
    this.doctorList = [] as any;
    this.optionSkin = '';
    this.optionLive = '';
    this.optionDoctor = '';
    this.changeParams.appendTextContentId = '';

    Object.keys(this.changeParams).forEach((key: any) => {
      params[key] = this.changeParams[key];
    });
    if (value === '护肤建议') {
      params.type = 1;
    } else if (value === '生活建议') {
      params.type = 2;
    } else if (value === '医美就医建议') {
      params.type = 4;
    }
    const {
      data: { data }
    } = await service.appendContentDownList(params);

    if (data !== null) {
      data.forEach((item: any) => {
        item.oppointQuestion = [];
        item.questionAndOptions.forEach((options: any) => {
          options.questionOptions = options.questionOptionSeqs.map((seq: number) => {
            seq = this.letterList[seq - 1];
            const newOptions = item.oppointQuestion.find((i: any) => i.code === options.code);
            if (!newOptions) {
              item.oppointQuestion.push({
                code: options.code,
                optionNames: [seq]
              });
            } else if (newOptions) {
              newOptions.optionNames.push(seq);
            }
            return seq;
          });
        });
        if (item.oppointQuestion.length) {
          if (value === '护肤建议') {
            const idsStr = item.oppointQuestion
              .map(function (obj: any) {
                return obj.code + '/' + obj.optionNames.join('/');
              })
              .join(',');
            this.skinList.push({ value: item.id, label: idsStr });
          } else if (value === '生活建议') {
            const idsStr = item.oppointQuestion
              .map(function (obj: any) {
                return obj.code + '/' + obj.optionNames.join('/');
              })
              .join(',');
            this.liveList.push({ value: item.id, label: idsStr });
          } else if (value === '医美就医建议') {
            const idsStr = item.oppointQuestion
              .map(function (obj: any) {
                return obj.code + '/' + obj.optionNames.join('/');
              })
              .join(',');
            this.doctorList.push({ value: item.id, label: idsStr });
          }
        }
      });
    }
  }

  appendReportContent = {} as any;
  async showDrawer(id: any) {
    const {
      data: { data }
    } = await service.getReportTextDetailList(id);

    if (data !== null) {
      this.appendReportContent = data;
    } else {
      this.appendReportContent = {};
    }
    this.isShowDrawer = true;
  }

  // 编辑通用文案
  editGeneral() {
    this.isDeitGeneral = true;
    this.disabledText = false;
  }

  symptom = '';
  editData = {
    skinTargetId: '',
    symptom: '',
    description: ''
  };

  saveGeneralText() {
    this.editData.skinTargetId = this.changeParams.skinTargetId;
    if (this.contrastType === '已改善') {
      this.editData.symptom = '-1';
    } else if (this.contrastType === '已恢复') {
      this.editData.symptom = '-2';
    }
    if (this.generalText) {
      this.editData.description = this.generalText;
      this.ConfirmFlag = true;
    }
  }

  // 确认保存通用文案
  async confirmDone() {
    const {
      data: { msg }
    } = await service.saveGoodSolution(this.editData);
    if (msg === '操作成功') {
      this.$notify({
        title: '保存成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.ConfirmFlag = false;
      this.isDeitGeneral = false; // 保存完通用文案按钮改为编辑
      this.disabledText = true;
    }
  }

  // 解决方案-首次报告-编辑 {path:'/reportFile/planFirstDetail',query:}
  editfirstPlan(value: string) {
    setSkinType({
      targetId: this.skinTagInfo.targetId,
      targetName: this.skinQuestionType,
      mode: this.mode,
      radio: this.radio,
      planChildType: '通用版',
      firstTextType: this.firstTextType
    });
    let text = '';
    let type = '1';
    if (value === 'skinProtectSuggest') {
      text = '护肤建议';
      type = '1';
    } else if (value === 'livingHabitsSuggest') {
      text = '生活习惯-建议';
      type = '2';
    } else if (value === 'livingHabitsAttention') {
      text = '生活习惯-注意';
      type = '3';
    }
    const title = this.skinQuestionType + ' - ' + '首次报告' + ' - ' + this.firstTextType + ' - ' + text;
    this.$router.push({
      path: '/reportFile/planFirstDetail',
      query: {
        title: title,
        textType: this.firstParams.textType,
        targetId: this.firstParams.skinTargetId,
        type: type
      }
    });
  }

  // 解决方案-变化报告-编辑
  editplan(value: string) {
    setSkinType({
      targetId: this.skinTagInfo.targetId,
      targetName: this.skinQuestionType,
      mode: this.mode,
      radio: this.radio,
      planChildType: this.planChildType,
      firstTextType: '通用版'
    });
    let symptom = '';
    if (this.planChildType) {
      symptom = '新问题/变严重/持续中';
    } else {
      symptom = '';
    }
    const title = this.skinQuestionType + ' - ' + '变化报告' + ' - ' + symptom + ' ' + this.planChildType + ' - ' + value;
    this.$router.push({
      path: '/reportFile/planChangeDetail',
      query: {
        title: title,
        skinTargetId: this.firstParams.skinTargetId,
        textType: this.planChildType,
        type: value,
        symptom
      }
    });
  }

  // 解决方案-变化报告-已改善/已恢复 查询
  async getGoodSolution(params: any) {
    const {
      data: { data }
    } = await service.getGoodSolution(params);
    if (data !== null) {
      this.generalText = data.description;
    } else {
      this.generalText = '';
    }
  }

  cList = [] as any;
  // 解决方案-列表
  async getSolution(params: any) {
    this.dataLoading = true;
    this.cList = [];
    this.contrastSuggestList.forEach((item: any) => {
      item.tableData = [];
      item.total = '';
    });
    const {
      data: { data }
    } = await service.getSolution(params);
    if (data !== null) {
      this.cList.push(data);
      let newClist = this.cList.reduce((prev: any, curr: any) => prev.concat(curr), []);
      newClist = [...new Set(newClist)];
      if (newClist !== null) {
        newClist.forEach((item: any) => {
          if (item.reportTextContents !== null) {
            item.reportTextContents.forEach((report: any, index: any) => {
              report.num = this.idxArray[index];
              report.appendTextContent = [];
              report.eventType = [];
              report.query = false;
              if (report.type === 1) {
                this.contrastSuggestList[0].total = item.total;
                this.contrastSuggestList[0].tableData.push(...item.reportTextContents);
              } else if (report.type === 2) {
                this.contrastSuggestList[1].total = item.total;
                this.contrastSuggestList[1].tableData.push(...item.reportTextContents);
              } else if (report.type === 4) {
                this.contrastSuggestList[2].total = item.total;
                this.contrastSuggestList[2].tableData.push(...item.reportTextContents);
              }
            });
          }
        });
      }
      this.contrastSuggestList.forEach((item: any) => {
        item.tableData = [...new Set(item.tableData)];
      });
      this.skinTableList = this.contrastSuggestList[0].tableData;
      this.liveTableList = this.contrastSuggestList[1].tableData;
      this.doctorTableList = this.contrastSuggestList[2].tableData;
    } else {
      this.contrastSuggestList.forEach((item: any) => {
        item.tableData = [];
        item.total = '';
      });
    }
    this.dataLoading = false;
  }

  // 解决方案-首次报告详情
  async getFirstReport() {
    this.firstReport = {};
    const {
      data: { data }
    } = await service.firstReportDetail(this.firstParams);

    if (data !== null) {
      this.firstReport = data;
    } else {
      this.firstReport = {
        skinProtectSuggest: [],
        livingHabitsSuggest: [],
        livingHabitsAttention: []
      };
    }
  }

  created() {
    for (let i = 65; i <= 90; i++) {
      this.letterList.push(String.fromCharCode(i));
    }
    this.getFirstReport();
  }

  activated() {
    // const res = JSON.parse(getSkinType()!);
    if (getSkinType()) {
      this.radio = JSON.parse(getSkinType()!).radio;
      this.planChildType = JSON.parse(getSkinType()!).planChildType;
      this.firstTextType = JSON.parse(getSkinType()!).firstTextType;
    } else {
      this.radio = '首次报告';
      this.planChildType = '通用版';
      this.firstTextType = '通用版';
    }

    this.sysNum = 3;
    this.contrastType = '';
    this.contrastSuggestList.forEach((item: any) => {
      item.tableData = [];
      item.total = '';
    });
    this.getFirstReport();

    for (let i = 1; i <= 3; i++) {
      if (i === 3) {
        this.changeParams.type = i + 1;
      } else {
        this.changeParams.type = i;
      }

      // this.getSolution(this.changeParams);
    }
  }
}
