import request from '@/utils/request';

const service = {
  query(params: any, data: any) {
    return request.post(
      `/api/cms/v1/banner/page?pageNum=${params.pageNum}&pageSize=${params.pageSize}&searchParam=${params.searchParam}`,
      data
    );
  },
  created(data: any) {
    return request.post('/api/cms/v1/banner/add', data);
  },
  update(data: any) {
    return request.post('/api/cms/v1/banner/update', data);
  },
  detail(id: any) {
    return request.get(`/api/cms/v1/banner/getById/${id}`);
  },
  getSCList(params: any, data: any) {
    return request.post(
      `/api/cms/v1/banner/sm/page?pageNum=${params.pageNum}&pageSize=${params.pageSize}&searchParam=${params.searchParam}`,
      data
    );
  },
  getByBaNumber(id: any) {
    return request.get(`/api/cms/v1/banner/getByBaNumber/${id}`);
  },
  getCalendar(data: any) {
    return request.post('/api/cms/v1/banner/list', data);
  },
  getSort(data: any) {
    return request.post('/api/cms/v1/banner/sort/list', data);
  },
  updateSort(data: any) {
    return request.post('/api/cms/v1/banner/sort', data);
  }
};

export default service;
