
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/log';

@Component({
  components: {}
})
export default class PageLog extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm: LogForm = {
    moduleName: null, // 模块名称
    operatorType: null, // 操作类型
    operatorObjectKeyword: null, // 操作对象
    descriptionKeyword: null, // 详情关键字
    operatorAndExternalIdKeyword: null, // 操作人和账号id关键字
    searchEndTime: null, // 搜索结束时间
    searchStartTime: null, // 搜索开始时间
    searchTime: [] as Date[],
    orders: [
      {
        column: 'created_on',
        asc: false
      }
    ]
  };

  onQuery(params?: Record<string, any>) {
    if (this.queryForm.searchTime && this.queryForm.searchTime !== null) {
      this.queryForm.searchStartTime = this.queryForm?.searchTime[0];
      this.queryForm.searchEndTime = this.queryForm?.searchTime[1];
    }
    return this.$refTableM?.request(Object.assign({}, this.queryForm, params));
  }

  moduleNameList = [] as any;
  operatorType = [] as any;
  roleFlag = false;
  onReset() {
    this.queryForm.searchEndTime = null;
    this.queryForm.searchStartTime = null;
    this.$queryForm.resetFields();
    this.queryForm.moduleName = null;
    this.queryForm.operatorObjectKeyword = null;
    this.roleFlag = false;
    this.onResetM(true);
  }

  sortChange(val: any) {
    if (val.order === 'descending' || val.order === null) {
      this.queryForm.orders[0].asc = false;
    } else if (val.order === 'ascending') {
      this.queryForm.orders[0].asc = true;
    }
    this.onQuery();
  }

  async getModuleNameList() {
    const {
      data: { data }
    } = await service.getModuleNameList();

    this.moduleNameList = data;
  }

  async getOperatorObjectTypeList() {
    const {
      data: { data }
    } = await service.getOperatorObjectTypeList();
    this.operatorType = data;
  }

  activated() {
    this.getOperatorObjectTypeList();
    this.getModuleNameList();
    this.onQuery();
  }

  created() {
    if (this.$route.params) {
      this.queryForm.moduleName = this.$route.params.moduleName as any;
      this.queryForm.operatorObjectKeyword = this.$route.params.operatorObjectId as any;
    }
  }

  getDefaultTime() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    if (this.queryForm.searchTime) {
      this.queryForm.searchTime[0] = this.$moment(start).format('yyyy-MM-DD HH:mm:ss') as any;
      this.queryForm.searchTime[1] = this.$moment(end).format('yyyy-MM-DD HH:mm:ss') as any;
    }
  }

  // table
  tableRequest = service.query;
  get tableColumns() {
    const data: ColumnItem<LogItem>[] = [
      { label: '操作完成时间', prop: 'createdOn', sortable: 'custom' },
      { label: '操作人员', prop: 'operator' },
      { label: '模块名称', prop: 'moduleName' },
      { label: '操作类型', prop: 'operatorType' },
      { label: '操作对象', prop: 'operatorObject' },
      { label: '操作详情', prop: 'description', tooltip: true }
    ];
    return data;
  }
}
