
import { Component, Vue, Ref } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/devices';

@Component({ components: {} })
export default class PageDevices extends Vue {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    deviceId: null // 设备id
  };

  text = '请输入设备ID后，进行查询';

  async onQuery() {
    if (this.queryForm.deviceId) {
      const form = new FormData();
      form.append('clientId', this.queryForm.deviceId!);
      service
        .info(form)
        .then(() => {
          this.$router.push({ path: `/devices/detail/${this.queryForm.deviceId}` });
          this.queryForm.deviceId = null;
          this.text = '请输入设备ID后，进行查询';
        })
        .catch(() => {
          this.text = '未查询到设备，请检查设备ID输入是否正确';
        });
    }
  }

  activated() {
    this.queryForm.deviceId = null;
  }
}
