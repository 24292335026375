
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import YkEcharts from '@c/YK_Echarts/index.vue';
import YkWarn from '@/components/Yk_Warn/index.vue';
import * as echarts from 'echarts';
import service from '@/api/consumerUser';
import { UserModule } from '@/store/modules/user';
@Component({ components: { YkEcharts, YkWarn } })
export default class UserCard extends Vue {
  @Prop({ type: Object, required: true })
  formData!: {};

  userInfo = {} as any;
  deviceInfo = [] as any;
  cinfo = {} as any;
  isBing = false;
  isWarn = false;
  bindAccountType = [] as any;
  shareOptions = {} as echarts.EChartsOption;
  useOptions = {} as echarts.EChartsOption;
  flag = false;
  isActive = '使用';
  IOSAticData = [] as any; // IOS30天使用数据
  WechatAticData = [] as any; // 小程序30天使用数据
  AndroidAticData = [] as any; // 安卓 30天使用数据
  IOSShacData = [] as any; // IOS30天使用数据
  WechatShacData = [] as any; // 小程序30天使用数据
  AndroidShacData = [] as any; // 安卓 30天使用数据
  xAxisData = [] as any; // x轴显示数据
  options = {
    legend: {
      data: ['Android', 'iOS', '小程序'],
      left: '40%',
      bottom: '10%'
    },
    tooltip: {
      // 显示 一组数据 当前拐点的数据
      trigger: 'axis',
      triggerOn: 'mousemove',
      // 提示框的悬浮位置
      position: (point, params, dom, rect, size) => {
        const x = point[0]; //
        const y = point[1];
        const boxWidth = size.contentSize[0];
        const boxHeight = size.contentSize[1];
        let posX = 0; // x坐标位置
        let posY = 0; // y坐标位置
        if (x < boxWidth) {
          // 左边放不开
          posX = 5;
        } else {
          // 左边放的下
          posX = x;
        }

        if (y < boxHeight) {
          // 上边放不开
          posY = 5;
        } else {
          // 上边放得下
          posY = y - boxHeight;
        }
        return [posX, posY];
      },
      // 自定义提示框内容
      formatter: (data: any) => {
        let sum = 0;
        data.forEach((item: any) => {
          sum += item.data;
        });
        let str = sum + '</br>';
        data.reverse().forEach((item: any) => {
          str = str + item.marker + item.seriesName + ' : ' + item.data + '</br>';
        });
        return str;
      },
      axisPointer: {
        // 坐标轴指示器，坐标轴触发有效，sahdow为阴影，鼠标放下去的时候显示后面的阴影
        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
      }
    },
    xAxis: {
      data: this.xAxisData,
      axisLine: { show: false },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        show: true,
        interval: 0
      },
      axisTick: {
        alignWithLabel: true
      }
    },
    yAxis: {},
    grid: {
      bottom: 100
    }
  } as echarts.EChartsOption;

  lastMonth = 0;
  currentMonth = 0;
  @Watch('formData', { immediate: true })
  onChangeInfo(newVal: any) {
    if (newVal) {
      this.bindAccountType = [];
      if (newVal.bindAccountType) {
        newVal.bindAccountType.forEach((item: any) => {
          if (item === 1) {
            item = '微信';
          } else if (item === 2) {
            item = 'qq';
          } else if (item === 3) {
            item = '微博';
          } else if (item === 4) {
            item = '微信小程序';
          }
          this.bindAccountType.push(item);
        });
      }

      if (newVal.registerType === 1) {
        newVal.registerType = '手机号注册';
      } else if (newVal.registerType === 2) {
        newVal.registerType = '邮箱注册';
      } else if (newVal.registerType === 3) {
        newVal.registerType = '微信授权注册';
      } else if (newVal.registerType === 4) {
        newVal.registerType = '微博授权注册';
      } else if (newVal.registerType === 5) {
        newVal.registerType = 'qq授权注册';
      }

      this.userInfo = { ...newVal };
      this.userInfo.bindAccount = '-';
      if (this.bindAccountType.length > 1) {
        this.bindAccountType.forEach((item: string) => {
          this.userInfo.bindAccount += item + '、';
        });
      } else if (this.bindAccountType.length === 1) {
        this.userInfo.bindAccount = this.bindAccountType[0];
      }
      Object.keys(this.userInfo).forEach((key: any) => {
        if (this.userInfo[key] === null) {
          this.userInfo[key] = '-';
        }
      });
    }
  }

  queryDevice(item: any) {
    // 匹配code 查看本账号是否有【设备查询】权限
    if (UserModule.useData?.permissionCodes) {
      const bol = UserModule.useData.permissionCodes.some((item: any) => item === '35pZR2v9oFA4');
      if (bol) {
        this.$router.push({ path: `/devices/detail/${item.clientId}` });
      } else {
        this.isWarn = true;
      }
    }
  }

  async getBindDeviceL(id: any) {
    const {
      data: { data }
    } = await service.getBindDeviceL(id);
    if (data) {
      this.deviceInfo = data;
      this.deviceInfo.forEach((item: any) => {
        Object.keys(item).forEach((key: any) => {
          if (item[key] === null) {
            item[key] = '-';
          }
        });
      });
    } else {
      this.deviceInfo = [
        {
          deviceModel: '-',
          clientId: '-',
          binding: '-'
        }
      ];
    }
  }

  async getCinfo(id: any) {
    const {
      data: { data }
    } = await service.getCinfo(id);
    this.cinfo = data;
    // 获取30天使用统计
    const aticData = [] as any;
    Object.keys(data.aticData).forEach((key: any) => {
      aticData.push(data.aticData[key]);
    });
    aticData.forEach((item: any) => {
      this.IOSAticData.push(item.iOS);
      this.WechatAticData.push(item.wechat);
      this.AndroidAticData.push(item.android);
    });
    // 获取30天分享统计
    const shacData = [] as any;
    Object.keys(data.shacData).forEach((key: any) => {
      shacData.push(data.shacData[key]);
    });
    shacData.forEach((item: any) => {
      this.IOSShacData.push(item.iOS);
      this.WechatShacData.push(item.wechat);
      this.AndroidShacData.push(item.android);
    });

    Object.keys(this.cinfo.lastInfo).forEach((key: any) => {
      if (this.cinfo.lastInfo[key] === null) {
        this.cinfo.lastInfo[key] = '-';
      }
    });
  }

  async created() {
    // NOTE 兰元使用第三方埋点统计 后端接口没有数据
    // this.getCinfo(+this.$route.params.consumerUserId);
    this.getBindDeviceL(+this.$route.params.consumerUserId);
    for (let i = 30; i > 0; i--) {
      const date = new Date();
      this.currentMonth = date.getMonth() + 1; // 当前月：0-11
      if (this.currentMonth === 1) {
        this.lastMonth = 12;
      } else {
        this.lastMonth = this.currentMonth - 1;
      }

      date.setTime(date.getTime() - 3600 * 1000 * 24 * i);
      this.xAxisData.push(this.$moment(date).format('MM/DD').toString());
    }
    this.xAxisData = this.xAxisData.map((item: string, index: number) => {
      if (item.split('/')[0].includes(this.lastMonth.toString()) && index !== 0) {
        item = item.split('/')[1].toString();
      } else if (item.split('/')[0].includes(this.currentMonth.toString()) && item.split('/')[1] !== '01' && index !== 0) {
        item = item.split('/')[1].toString();
      }

      return item;
    });

    this.options.xAxis = {
      data: this.xAxisData,
      axisLine: { show: false },
      splitLine: { show: false },
      splitArea: { show: false },
      axisLabel: {
        show: true,
        interval: 0
      },
      axisTick: {
        alignWithLabel: true
      }
    };

    this.useOptions = { ...this.options };
    this.shareOptions = { ...this.options };
    this.useOptions.series = [
      {
        name: 'Android',
        type: 'bar',
        stack: 'x',
        data: this.AndroidAticData,
        color: '#6bda6b'
      },
      {
        name: 'iOS',
        type: 'bar',
        stack: 'x',
        data: this.IOSAticData,
        color: '#8a97b0'
      },
      {
        name: '小程序',
        type: 'bar',
        stack: 'x',
        data: this.WechatAticData,
        color: '#6ccaf6'
      }
    ];
    this.shareOptions.series = [
      {
        name: 'Android',
        type: 'bar',
        stack: 'x',
        data: this.AndroidShacData,
        color: '#ff7070'
      },
      {
        name: 'iOS',
        type: 'bar',
        stack: 'x',
        data: this.IOSShacData,
        color: '#fac858'
      },
      {
        name: '小程序',
        type: 'bar',
        stack: 'x',
        data: this.WechatShacData,
        color: '#5673cb'
      }
    ];
  }

  activated() {
    this.flag = false;
  }
}
