
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import { ElForm } from 'element-ui/types/form';
import BannerTable from './component/table.vue';
import drawer from './component/drawer.vue';
import service from '@/api/banner';
import serviceC from '@/api/consumerUser';
import serviceS from '@/api/banner/material';
import OSS from 'ali-oss';
import Sortable from 'sortablejs';
@Component({
  components: { BannerTable, drawer }
})
export default class PageBannerSort extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    baNumber: null
  };

  isShowDrawer = false;
  list = [] as any;
  currentData = [] as any;
  isWarning = false;
  loading = false;
  warnText = '';
  ConfirmFlag = false;
  params = [] as any;
  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  drawerTitle = 'Banner详情';
  srcList: string[] = [];
  drawerDetails = {} as any;

  async BaShowDrawer(row: any) {
    this.srcList = [];
    this.isShowDrawer = true;
    this.drawerTitle = 'Banner详情';
    const {
      data: { data }
    } = await service.getByBaNumber(row.baNumber);
    this.drawerDetails = { ...data };
    if (this.drawerDetails.baStatus === 0) {
      this.drawerDetails.baStatus = '待上线';
    } else if (this.drawerDetails.baStatus === 1) {
      this.drawerDetails.baStatus = '已上线';
    } else {
      this.drawerDetails.baStatus = '已下线';
    }
    if (this.drawerDetails.type === 1) {
      this.drawerDetails.type = '日常';
    } else if (this.drawerDetails.type === 2) {
      this.drawerDetails.type = '活动';
    } else {
      this.drawerDetails.type = '个性化';
    }
    if (this.drawerDetails.smStatus === 1) {
      this.drawerDetails.smStatus = '未使用';
    } else if (this.drawerDetails.smStatus === 2) {
      this.drawerDetails.smStatus = '已使用';
    }

    if (!this.drawerDetails.tagName) {
      this.drawerDetails.tagName = '所有用户';
      this.drawerDetails.tagColor = '';
    }
    const res = await serviceC.getOSSToken('user/material/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + this.drawerDetails.smUrl, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
    }
  }

  async SmShowDrawer(row: any) {
    this.srcList = [];
    this.isShowDrawer = true;
    this.drawerTitle = '素材详情';
    const {
      data: { data }
    } = await serviceS.getByNumber(row.smNumber);
    this.drawerDetails = { ...data };
    if (this.drawerDetails.status === 1) {
      this.drawerDetails.status = '未使用';
    } else if (this.drawerDetails.status === 2) {
      this.drawerDetails.status = '已使用';
    }
    if (this.drawerDetails.type === 1) {
      this.drawerDetails.type = '日常';
    } else if (this.drawerDetails.type === 2) {
      this.drawerDetails.type = '活动';
    } else {
      this.drawerDetails.type = '个性化';
    }
    if (!this.drawerDetails.tagName) {
      this.drawerDetails.tagName = '所有用户';
      this.drawerDetails.tagColor = '';
    }
    const res = await serviceC.getOSSToken('user/material/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      const url = client.signatureUrl(res.data.data.pathPrefix.split('/')[0] + '/' + this.drawerDetails.smUrl, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
    }
  }

  columnStyle(event: any) {
    if (this.currentData.length === 1 && event.row.id === this.currentData[0].id) {
      let bdWidth = '';
      if (event.columnIndex === 0) {
        bdWidth = '1px 0px 1px 1px';
      } else if (event.columnIndex === 7) {
        bdWidth = '1px 1px 1px 0px';
      } else {
        bdWidth = '1px 0px';
      }
      return {
        'border-color': '#888fe7',
        'border-style': 'solid',
        'border-width': bdWidth
      };
    } else {
      return {
        'border-bottom': '1px solid #dfe6ec'
      };
    }
  }

  // 创建sortable实例
  initSortable() {
    const ele = document.querySelector('.el-table__body > tbody');
    // 创建拖拽实例
    Sortable.create(ele, {
      animation: 150, // 动画
      disabled: false, // 拖拽不可用? false 启用（刚刚渲染表格的时候起作用，后面不起作用）
      handle: '.move', // 指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
      filter: '.disabled', // 指定不可拖动的类名（el-table中可通过row-class-name设置行的class）
      dragClass: 'dragClass', // 设置拖拽样式类名
      ghostClass: 'ghostClass', // 设置拖拽停靠样式类名
      chosenClass: 'chosenClass', // 设置选中样式类名
      // 开始拖动事件
      onStart: () => {
        // console.log('开始拖动');
      },
      onEnd: (event: any) => {
        const tempTableData = [...this.list]; // 先存一份临时变量表头数据
        let temp = '' as any;
        temp = tempTableData[event.oldIndex]; //
        tempTableData.splice(event.oldIndex, 1);
        tempTableData.splice(event.newIndex, 0, temp);
        this.list = [];
        this.$nextTick(() => {
          this.list = tempTableData.map((item: any, index: any) => {
            item.sort = index + 1;
            return item;
          });
        });
      }
    });
  }

  mounted() {
    this.initSortable();
  }

  async save() {
    this.ConfirmFlag = true;
    this.params = [] as any;
    this.list.forEach((item: any) => {
      this.params.push({ id: item.id, sort: item.sort === null ? 0 : item.sort });
    });
  }

  // 确认保存
  async confirmDone() {
    const {
      data: { msg }
    } = await service.updateSort(this.params);
    if (msg === '操作成功') {
      this.ConfirmFlag = false;
      this.getList();
      this.$router.push('/banner/index');
    }
  }

  async onQuery() {
    this.loading = true;
    service
      .getSort(this.queryForm)
      .then((data: any) => {
        this.loading = false;
        if (data.data) {
          const bol = data.data.data.every((item: any) => item.status === 2);
          if (bol) {
            this.currentData = [];
            this.isWarning = true;
            this.warnText = '已下线的Banner信息无法排序';
          } else {
            this.currentData = data.data.data;
            this.isWarning = false;
          }
        }
      })
      .catch(() => {
        this.loading = false;
        this.currentData = [];
        this.isWarning = true;
        this.warnText = '该ID不存在，请检查后重新搜索';
      });
    // this.loading = false;
  }

  async getList() {
    this.loading = true;
    const {
      data: { data }
    } = await service.getSort(this.queryForm);
    if (data !== null) {
      this.list = data.filter((item: any) => item.status !== 2);
      this.list.forEach((item: any) => {
        if (item.smType === 1) {
          item.smType = '日常';
        } else if (item.smType === 2) {
          item.smType = '活动';
        } else {
          item.smType = '个性化';
        }
        if (item.status === 0) {
          item.status = '待上线';
        } else if (item.status === 1) {
          item.status = '已上线';
        }
        if (!item.tagName) {
          item.tagName = '所有用户';
          item.tagColor = '';
        }
        item.validTime = item.startTime + ' ~ ' + item.endTime;
      });
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  activated() {
    this.isWarning = false;
    this.$queryForm.resetFields();
    this.currentData = [];
    this.getList();
  }

  get tableColumns() {
    const data: ColumnItem<bannerItem>[] = [
      { slot: 'baNumber', label: 'Banner编号', prop: 'baNumber' },
      { slot: 'smNumber', label: '素材编号', prop: 'smNumber' },
      { label: '素材类型', prop: 'smType' },
      { slot: 'status', label: '状态', prop: 'status' },
      { label: '生效日期', prop: 'validTime', width: 300 },
      { slot: 'tagName', label: '用户画像', prop: 'tagName' },
      {
        slot: 'actions',
        prop: 'actions',
        label: '排序',
        width: 200
      }
    ];
    return data;
  }
}
