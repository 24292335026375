
import { Component, Vue } from 'vue-property-decorator';
import YkUpload from '@c/YK_Upload/index.vue';
import YkWarn from '@/components/Yk_Warn/index.vue';
import userDrawer from './drawer.vue';
import userCard from './userCard.vue';
import userDialog from './userDialog.vue';
import service from '@/api/consumerUser';
import serviceTag from '@/api/consumerTag';
import { UserModule } from '@/store/modules/user';
import OSS from 'ali-oss';

@Component({ components: { YkUpload, YkWarn, userDrawer, userCard, userDialog } })
export default class UserDetail extends Vue {
  queryForm = {
    likeName: null,
    orders: [
      {
        column: 'modified_on',
        asc: false
      }
    ]
  };

  params = {
    pageNum: 1,
    pageSize: 100
  };

  userRemarks = [] as any;
  formData = {} as any;
  tagName = [] as any;

  // moreDrawer = false;
  remarkDrawer = false;
  remark = false;
  dialogFlag = false;
  ConfirmFlag = false;
  isEdit = false;
  isWarn = false;
  RemarkInfo = {} as any;
  tagList = [] as any;
  pictures = [] as any;
  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  srcList = [] as string[];

  textarea = '';

  closeRemark() {
    this.remarkDrawer = false;
    this.remark = false;
    this.textarea = '';
    this.srcList = [];
  }

  async uploadOk(val: any) {
    this.pictures = [];
    // this.srcList = [];
    const {
      data: { data }
    } = await service.getOSSToken('user/remark/upload');
    if (data) {
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: data.securityToken,
        // 填写Bucket名称。
        bucket: data.bucketName
      });
      const url = client.signatureUrl(data.pathPrefix + val.file.name, {
        process: 'image/resize,w_200' // 设置图片处理参数。
      });
      this.srcList.push(url);
      this.pictures.push(data.pathPrefix + val.file.name);
      try {
        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
        await client.put(data.pathPrefix + val.file.name, val.file);
      } catch (e) {
        console.log(e);
      }
    }
  }

  viewpic() {
    const Show = document.querySelector('.el-image__inner') as HTMLElement | null;
    Show!.click();
  }

  removepic(index: any) {
    this.srcList.splice(index, 1);
  }

  // 删除画像
  async clearTag(name: string) {
    const tagIds = [] as number[];
    this.formData.tags.forEach((item: any) => {
      if (item.name !== name) {
        tagIds.push(item.id);
      }
    });
    const {
      data: { msg }
    } = await service.saveTags({ userId: +this.$route.params.consumerUserId, tagIds });

    if (msg === '操作成功') {
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.getDetail();
    }
  }

  remarkId = '';
  // 编辑备注
  editRemark(row: any) {
    this.isEdit = true;
    this.remark = true;
    this.textarea = row.remarks;
    this.remarkId = row.id;
    // 需要把图片地址拿到
    this.srcList = row.pics;
  }

  // 删除备注
  async delRemark(row: any) {
    const { id, userId, remarks, pictures } = row;
    this.ConfirmFlag = true;
    this.RemarkInfo = { id, userId, remarks, pictures };
  }

  // 删除完成
  async confirmDone() {
    const {
      data: { msg }
    } = await service.delRemark(this.RemarkInfo);
    if (msg === '操作成功') {
      this.ConfirmFlag = false;
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.getRemarks();
      this.getDetail();
    }
  }

  // 新增和编辑备注
  async seveRemark() {
    if (this.isEdit) {
      this.pictures = [];
      this.srcList.forEach((item: string) => {
        this.pictures.push(item.split('?')[0]);
      });
    }
    this.pictures = this.pictures.map((item: any) => {
      const index = item.indexOf('O');
      return item.substring(index);
    });

    let message = '';
    const params = {
      userId: this.$route.params.consumerUserId,
      remarks: this.textarea,
      pictures: this.pictures ? this.pictures.toString() : ''
    };
    if (this.isEdit) {
      const {
        data: { msg }
      } = await service.updateRemark({ ...params, id: this.remarkId });
      message = msg;
    } else {
      const {
        data: { msg }
      } = await service.addRemark(params);
      message = msg;
    }
    if (message === '操作成功') {
      this.$notify({
        title: '保存成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.getRemarks();
      this.remark = false;
      this.textarea = '';
      this.getDetail(); // 重新获取用户信息
      this.isEdit = false;
      this.srcList = [];
      this.pictures = [];
    }
  }

  // 查看历史备注
  queryHistory() {
    // 匹配code 查看本账号是否有【日志管理】权限
    if (UserModule.useData?.permissionCodes) {
      const bol = UserModule.useData.permissionCodes.some((item: any) => item === '2Uuq25KlFrXP');
      if (bol) {
        this.$router.push({
          path: '/log/index',
          name: 'log',
          params: {
            moduleName: '用户列表',
            operatorObjectId: this.$route.params.consumerUserId
          }
        });
        this.closeRemark();
      } else {
        this.isWarn = true;
      }
    }
  }

  created() {
    this.getTagList();
    this.getDetail();
  }

  // 获取用户详情
  async getDetail() {
    const {
      data: { data }
    } = await service.detail(this.$route.params.consumerUserId);
    this.formData = data;
    // if (this.formData.gender === 1) {
    //   this.formData.gender = '男';
    // } else if (this.formData.gender === 2) {
    //   this.formData.gender = '女';
    // } else if (!this.formData.gender) {
    //   this.formData.gender = '-';
    // }
    if (this.formData.tags !== null) {
      this.formData.tags.forEach((tag: any) => {
        if (tag.color === null) {
          tag.color = 1;
        }
      });
    }
    if (this.formData.skinTargets !== null) {
      this.formData.skinTargets.forEach((skin: any) => {
        if (skin.dimension === 1) {
          this.formData.skinName = skin.name;
        } else if (skin.dimension === 2) {
          this.formData.skinSens = skin.name;
        }
      });
    }

    const res = await service.getOSSToken('user/head_profile/upload');
    if (res.data.data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: res.data.data.accessKeyId,
        accessKeySecret: res.data.data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: res.data.data.securityToken,
        // 填写Bucket名称。
        bucket: res.data.data.bucketName
      });
      // item.pictures.forEach((pic: any) => {
      if (this.formData.headPortrait !== null) {
        this.formData.headPortrait = client.signatureUrl(
          res.data.data.pathPrefix.split('/')[0] + '/' + this.formData.headPortrait,
          {
            process: 'image/resize,w_200' // 设置图片处理参数。
          }
        );
      }
    }
  }

  // 获取用户备注列表
  async getRemarks() {
    const {
      data: { data }
    } = await service.queryRemarks({ id: this.$route.params.consumerUserId });

    data.forEach(async (item: any) => {
      item.pics = [];
      if (item.pictures) {
        item.pictures = item.pictures.split(',');
        const res = await service.getOSSToken('user/remark/upload');
        if (res.data.data) {
          const client = new OSS({
            region: 'oss-cn-shanghai',
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: res.data.data.accessKeyId,
            accessKeySecret: res.data.data.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: res.data.data.securityToken,
            // 填写Bucket名称。
            bucket: res.data.data.bucketName
          });
          item.pictures.forEach((pic: any) => {
            const url = client.signatureUrl(pic, {
              process: 'image/resize,w_200' // 设置图片处理参数。
            });
            item.pics.push(url);
          });
        }
      }
    });
    this.userRemarks = data;
  }

  // 获取用户画像列表
  async getTagList() {
    const params = {
      pageNum: 1,
      pageSize: 10000
    };
    const {
      data: { data }
    } = await serviceTag.query(params, this.queryForm);
    this.tagName = data.records;
    this.tagName = this.tagName.filter((item: any) => item.type !== 1);
  }

  userRemark() {
    this.remarkDrawer = true;
    this.getRemarks();
  }

  async done(list: string[]) {
    this.tagList = [] as any;
    this.tagName.forEach((item: any) => {
      list.forEach((tagName: string) => {
        if (item.name === tagName) {
          this.tagList.push({
            id: item.id,
            name: item.name,
            description: item.description
          });
        }
      });
    });
    const tagIds = this.tagList.map((item: any) => item.id);

    const {
      data: { msg }
    } = await service.saveTags({ userId: +this.$route.params.consumerUserId, tagIds });

    if (msg === '操作成功') {
      this.getDetail();
      this.dialogFlag = false;
    }
  }
}
