
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import service from '@/api/reportFile';
import drawerQuestion from './components/drawerQuestion.vue';
@Component({ components: { drawerQuestion } })
export default class BannerDetail extends Vue {
  @Ref('appendSelect') $appendSelect: any;
  title = '';
  isShowDrawer = false;
  ConfirmFlag = false;
  isWarningDefault = false;
  defaultWarnText = '请填写必填项';
  replenishWarnText = '请填写必填项';
  isWarningReplenish = false;
  isWarningAppend = false;
  letterList = [] as any; // 26位字母列表数据
  initreportText = {} as any; // 详情默认数据
  changeParams = {
    skinTargetId: '', // 肌肤问题id
    textType: '', // 敏感程度 01 通用版 02干性不敏感 03油性不敏感 04干性敏感 05油性敏感
    type: '', //  1护肤建议 2 生活建议点 3生活注意点
    symptom: '', // 0 新增、持续、加重  -1 改善  -2 消退
    defaultTextList: [] as {
      description: string;
      textNum: string;
      seq: string;
      addToTextList: [];
    }[],
    replenishTextList: [] as {
      textNum: string;
      description: string;
      eventType: string;
    }[]
  } as any;

  detailParams = {
    skinTargetId: '', // 肌肤问题id
    textType: '' // 敏感程度 01 通用版 02干性不敏感 03油性不敏感 04干性敏感 05油性敏感
  } as any;

  addOption = '';
  options = [
    {
      value: '追加文案',
      lable: '追加文案'
    },
    {
      value: '删除文案',
      lable: '删除文案'
    }
  ];

  defaultcode = [] as any;
  defaultList = [] as any;
  appendList = [] as any;
  replenishList = [] as replenishList[];
  questionParams = {
    codeOrAskLike: '', // 编码或者问题模糊搜素
    skinTargetIds: [] as any, // 肌肤问题类型
    classify: '' // 题目分类1 护肤建议 2 生活建议 4 就医建议
  };

  isShowTooltip = false;
  isdoctor = false;
  confirmContent = '是否确定保存？';
  confirmDetails = '文案修改后，将于下一个自然日0点生效';
  // textInfo = {
  //   appedReportTextId: ''
  // } as any;

  regEn = /[`~!@#$%^&*_+<>?:"{}/;,'[\]()]/im;
  regCn = /[！#￥——：；“”‘、|《。》？、【】[\]]/im;
  regL = /[a-zA-Z]/im;
  @Watch('replenishList.length')
  onChangeLength(newVal: any) {
    if (!newVal) {
      this.isWarningReplenish = false;
      this.replenishWarnText = '请填写必填项';
    }
  }

  changeDefault() {
    this.defaultList.forEach((item: any) => {
      // item.description = item.description.replace(/[^0-9a-zA-Z\u4E00-\u9FA5· ， . （） ]/g, '').replace(/\s*/g, '');
      item.description = item.description.replace(/\s*/g, '');
      // if (this.regEn.test(`${item.description}`) || this.regCn.test(`${item.description}`) || item.description.includes(' ')) {
      //   this.isWarningDefault = true;
      //   this.defaultWarnText = '请检查文字格式,仅允许输入· , . ()';
      //   setTimeout(() => {
      //     this.isWarningDefault = false;
      //   }, 1000);
      // } else {
      //   this.isWarningDefault = false;
      // }
    });
  }

  verifydesDefault() {
    this.isWarningDefault = !this.defaultList.every((item: any) => item.description);
    this.defaultWarnText = '请填写必填项';
  }

  verifydesReplenish() {
    if (!this.replenishList.every((item: any) => item.description)) {
      this.isWarningReplenish = true;
      this.replenishWarnText = '请填写必填项';
      return;
    }

    this.isWarningReplenish = false;
  }

  handleAdd(value: string) {
    const letter = this.letterList[this.changeParams.skinTargetId - 1];
    const reportType = 2;
    const skinType = '0' + this.changeParams.textType;
    const suggestType = this.changeParams.type;
    const num = '01';
    const row = {
      textNum: '',
      description: '',
      disabled: true
    } as any;
    if ((value === '默认文案' && this.defaultList === null) || (value === '默认文案' && !this.defaultList.length)) {
      this.defaultList = [];
      row.textNum = letter + reportType + skinType + suggestType + num;
      this.defaultList.push(row);
    } else if (value === '默认文案' && this.defaultList.length < 20) {
      this.defaultList.forEach((item: any) => {
        if (item.textNum.substring(item.textNum.length - 2).split('')[0] === '0') {
          row.textNum = item.textNum.substring(0, 6) + (+item.textNum.substring(item.textNum.length - 2).split('')[1] + 1);
        } else if (item.textNum.substring(item.textNum.length - 2).split('')[0] !== '0') {
          row.textNum = item.textNum.substring(0, 5) + (+item.textNum.substring(item.textNum.length - 2) + 1);
        }
        this.defaultList.push(row);
        this.defaultList = [...new Set(this.defaultList)];
      });
    }
    if ((value === '补充文案' && this.replenishList === null) || (value === '补充文案' && !this.replenishList.length)) {
      this.replenishList = [];
      row.questionAndOptions = null;
      row.textNum = letter + reportType + skinType + suggestType + num;
      this.replenishList.push(row);
    } else if (value === '补充文案' && this.replenishList.length < 20) {
      row.questionAndOptions = null;
      this.replenishList.forEach((item: any) => {
        if (item.textNum.substring(item.textNum.length - 2).split('')[0] === '0') {
          row.textNum = item.textNum.substring(0, 6) + (+item.textNum.substring(item.textNum.length - 2).split('')[1] + 1);
        } else if (item.textNum.substring(item.textNum.length - 2).split('')[0] !== '0') {
          row.textNum = item.textNum.substring(0, 5) + (+item.textNum.substring(item.textNum.length - 2) + 1);
        }
        this.replenishList.push(row);
        this.replenishList = [...new Set(this.replenishList)];
      });
    }
  }

  add(title: any, textNum: any) {
    const row = {
      textNum: '',
      description: '',
      disabled: true,
      addValue: '追加文案',
      options: [
        {
          value: '追加文案',
          lable: '追加文案'
        },
        {
          value: '删除文案',
          lable: '删除文案'
        }
      ],
      questionAndOptions: null
    };
    if (this.appendList !== null && this.appendList.length) {
      const res = this.appendList.find((item: any) => item.textNum.split('-')[0] === textNum);
      if (res === undefined) {
        row.textNum = textNum + '-' + 1;
      } else {
        this.appendList.forEach((item: any) => {
          if (item.textNum.split('-')[0] === textNum && item.textNum.split('-')[1]) {
            row.textNum = textNum + '-' + (+item.textNum.split('-')[1] + 1);
          }
        });
        this.appendList.push(row);
      }
      this.appendList.push(row);
      this.appendList = [...new Set(this.appendList)];
    } else {
      this.appendList = [];
      row.textNum = textNum + '-' + 1;
      this.appendList.push(row);
    }
  }

  edit(value?: string, index?: number, textNum?: string) {
    if (value === '默认文案') {
      this.defaultList.forEach((item: any, i: any) => {
        if (index === i) {
          item.disabled = false;
        }
      });
      this.$set(this.defaultList, index!, this.defaultList[index!]);
    } else if (value === '补充文案') {
      this.replenishList.forEach((item: any, i: any) => {
        if (index === i) {
          item.disabled = false;
        }
      });
      this.$set(this.replenishList, index!, this.replenishList[index!]);
    }
  }

  editadd(title?: string, index?: number) {
    this.appendList.forEach((item: any, i: any) => {
      if (index === i) {
        item.disabled = false;
        item.showSelet = true;
      }
    });
    this.$set(this.appendList, index!, this.appendList[index!]);
  }

  addValue = '';
  bluraddValue(event: any, index: any, value: any) {
    this.addValue = event.target.value;
    if (event.relatedTarget === null) {
      this.appendList.forEach((item: any, i: any) => {
        if (index === i && value === '追加文案' && value === this.addValue) {
          item.addValue = '删除文案';
          item.disabled = true;
          item.showSelet = true;
          item.description = '';
        }
        if (index === i && value === '删除文案' && value === this.addValue) {
          item.addValue = '追加文案';
          item.disabled = false;
          // item.showSelet = false;
        }
      });

      this.$set(this.appendList, index!, this.appendList[index!]);
    }
  }

  del(value?: string, index?: number, num?: string) {
    if (value === '默认文案' && this.defaultList.length > 1) {
      this.defaultList.splice(index!, 1);
      this.appendList = this.appendList.filter((item: any) => !item.textNum.includes(num));
    } else if (value === '默认文案' && this.defaultList.length === 1 && !this.isdoctor) {
      this.isWarningDefault = true;
      this.defaultWarnText = '至少设置1条默认文案';
      setTimeout(() => {
        this.isWarningDefault = false;
      }, 1000);
    } else if (value === '默认文案' && this.defaultList.length === 1 && this.isdoctor) {
      this.ConfirmFlag = true;
      this.confirmContent = '是否确认删除该条文案？';
      this.confirmDetails = '';
    }
    if (value === '追加文案') {
      this.appendList.splice(index!, 1);
    }
    if (value === '补充文案') {
      this.replenishList.splice(index!, 1);
    }
  }

  textInfo = {
    appedReportTextId: '',
    defaultText: '',
    description: '',
    eventType: '',
    options: [],
    textId: '',
    textNum: ''
  } as any;

  async showDrawer(title: string, num: any, id: any, options: any) {
    this.textInfo.appedReportTextId = id;
    this.textInfo.options = options;
    this.textInfo.textNum = num;
    if (title === '追加文案') {
      this.defaultList.forEach((item: any) => {
        if (num.includes(item.textNum)) {
          this.textInfo.defaultText = item.description;
          this.textInfo.textId = item.textId;
        }
      });
      this.appendList.forEach((item: any) => {
        if (item.textNum === num) {
          this.textInfo.description = item.description;
        }
        if (item.addValue === '追加文案') {
          this.textInfo.eventType = '1';
        } else if (item.addValue === '删除文案') {
          this.textInfo.eventType = '2';
        }
      });
    } else {
      this.textInfo.eventType = '0';
      this.textInfo.textId = '';
      this.replenishList.forEach((item: any) => {
        if (item.textNum === num) {
          this.textInfo.description = item.description;
          this.textInfo.defaultText = '-';
        }
      });
    }

    this.questionParams.skinTargetIds = [];
    this.questionParams.classify = this.changeParams.type;
    this.questionParams.skinTargetIds.push(this.changeParams.skinTargetId);
    this.isShowDrawer = true;
  }

  saveDrawer(appointParams: any) {
    if (appointParams.eventType === '0') {
      this.replenishList.forEach((item: any) => {
        if (item.textNum === appointParams.textNum) {
          item.description = appointParams.description;
          item.questionAndOptions = [];
          item.questionAndOptions.push(...appointParams.appointQuestion);

          if (item.questionAndOptions !== null) {
            item.questionAndOptions.forEach((options: any) => {
              options.optionNames = options.questionOptionSeqs.map((seq: any) => {
                seq = this.letterList[seq - 1];
                return seq;
              });
            });
          }
        }
      });
    } else {
      this.appendList.forEach((item: any) => {
        if (item.textNum === appointParams.textNum) {
          item.description = appointParams.description;
          item.questionAndOptions = [];
          item.questionAndOptions.push(...appointParams.appointQuestion);

          if (item.questionAndOptions !== null) {
            item.questionAndOptions.forEach((options: any) => {
              options.optionNames = options.questionOptionSeqs.map((seq: any) => {
                seq = this.letterList[seq - 1];
                return seq;
              });
            });
          }
          if (appointParams.eventType === '1') {
            item.addValue = '追加文案';
          } else if (appointParams.eventType === '2') {
            item.addValue = '删除文案';
          }
        }
      });
    }
    this.isShowDrawer = false;
  }

  // 详情
  async getReportDetail() {
    this.detailParams.textType = this.changeParams.textType;
    this.detailParams.skinTargetId = this.changeParams.skinTargetId;
    this.detailParams.type = this.changeParams.type;
    this.defaultList = [];
    this.appendList = [];
    this.replenishList = [];

    const {
      data: { data }
    } = await service.changeReportDetail(this.detailParams);
    if (data !== null) {
      this.initreportText = { ...data };
      this.defaultList = data.reportTextContents;
      this.replenishList = data.replenishReportTextContents;
    } else {
      this.defaultList = [];
      this.appendList = [];
      this.replenishList = [];
    }

    if (this.defaultList !== null && this.defaultList.length) {
      this.defaultList.forEach((item: any) => {
        item.disabled = true;
        if (item.appendReportTextContents !== null) {
          this.appendList.push(...item.appendReportTextContents);
        }
      });
    }
    // 追加文案数据有问题 NOTE
    if (this.appendList !== null && this.appendList.length) {
      this.appendList.forEach((item: any) => {
        item.options = [];
        item.options.push(
          {
            label: '追加文案',
            value: '追加文案'
          },
          {
            label: '删除文案',
            value: '删除文案'
          }
        );
        if (item.eventType === 1 || item.eventType === '1') {
          item.addValue = '追加文案';
        } else if (item.eventType === 2 || item.eventType === '2') {
          item.addValue = '删除文案';
          item.showSelet = true;
        }
        item.disabled = true;
        if (item.questionAndOptions !== null) {
          item.questionAndOptions.forEach((options: any) => {
            if (options.questionOptionDrafts !== null) {
              options.optionIds = options.questionOptionDrafts.map((seq: any) => {
                return seq.id;
              });
              options.optionNames = options.questionOptionDrafts.map((seq: any) => {
                seq.seq = this.letterList[seq.seq - 1];
                return seq.seq;
              });
            }
          });
        }
      });
    }
    if (this.replenishList !== null && this.replenishList.length) {
      this.replenishList.forEach((item: any) => {
        item.disabled = true;
        if (item.questionAndOptions !== null) {
          item.questionAndOptions.forEach((options: any) => {
            if (options.questionOptionDrafts !== null) {
              options.optionIds = options.questionOptionDrafts.map((seq: any) => {
                return seq.id;
              });
              options.optionNames = options.questionOptionDrafts.map((seq: any) => {
                seq.seq = this.letterList[seq.seq - 1];
                return seq.seq;
              });
            }
          });
        }
      });
    }
  }

  async created() {
    for (let i = 65; i <= 90; i++) {
      this.letterList.push(String.fromCharCode(i));
    }
    // this.getReportDetail();
  }

  activated() {
    if (this.$route.query.title.includes('医美')) {
      this.isdoctor = true;
    } else {
      this.isdoctor = false;
    }
    if (this.$route.query.title) {
      this.title = '编辑' + ' ' + this.$route.query.title;
    }
    Object.keys(this.changeParams).forEach((key: any) => {
      this.changeParams[key] = this.$route.query[key] || this.changeParams[key];
      this.changeParams.symptom = '0';
      if (key === 'textType' && this.changeParams[key] === '通用版') {
        this.changeParams[key] = '1';
      } else if (key === 'textType' && this.changeParams[key] === '干性不敏感') {
        this.changeParams[key] = '2';
      } else if (key === 'textType' && this.changeParams[key] === '油性不敏感') {
        this.changeParams[key] = '3';
      } else if (key === 'textType' && this.changeParams[key] === '干性敏感') {
        this.changeParams[key] = '4';
      } else if (key === 'textType' && this.changeParams[key] === '油性敏感') {
        this.changeParams[key] = '5';
      }
      if (key === 'type' && this.changeParams[key] === '护肤建议') {
        this.changeParams[key] = '1';
      } else if (key === 'type' && this.changeParams[key] === '生活建议') {
        this.changeParams[key] = '2';
      } else if (key === 'type' && this.changeParams[key] === '医美就医建议') {
        this.changeParams[key] = '4';
      }
    });
    this.appendList = [];
    this.getReportDetail();
    this.isWarningDefault = false;
    this.isWarningAppend = false;
  }

  closeConfirm() {
    this.ConfirmFlag = false;
    if (this.confirmContent.includes('保存')) {
      this.$router.go(-1);
    }
  }

  verify() {
    if (!this.defaultTextList.length && !this.isdoctor) {
      this.isWarningDefault = true;
      this.defaultWarnText = '至少设置1条默认文案';
      return;
    }
    if (this.defaultTextList.length) {
      this.verifydesDefault();
    }
    this.isWarningAppend = !this.appendList.every((item: any) => item.questionAndOptions !== null);
    if (this.replenishList !== null) {
      if (!this.replenishList.every((item: any) => item.description)) {
        this.isWarningReplenish = true;
        this.replenishWarnText = '请填写必填项';
        return;
      }

      if (!this.replenishList.every((item: any) => item.questionAndOptions !== null)) {
        this.isWarningReplenish = true;
        this.replenishWarnText = '请指定题目';
        return;
      }
      this.isWarningReplenish = false;
    }
  }

  defaultTextList = [] as defaultTextList[];
  addToTextList = [] as addToText[];
  save() {
    this.defaultTextList = [] as defaultTextList[];
    this.addToTextList = [] as addToText[];
    this.changeParams.replenishTextList = [];

    if (this.replenishList !== null && this.replenishList.length) {
      this.replenishList.forEach((item: any) => {
        item.questionAndOption = [];
        if (!item.questionAndOptions) {
          item.questionAndOptions = null;
        }
        if (item.questionAndOptions !== null && item.questionAndOptions.length) {
          item.questionAndOptions.forEach((options: any) => {
            item.questionAndOption.push({
              questionId: options.questionId,
              code: options.code,
              questionOptionIds: options.optionIds
            });
          });
        }
        this.changeParams.replenishTextList.push({
          textNum: item.textNum,
          description: item.description,
          eventType: '0',
          questionAndOptions: item.questionAndOption.length ? item.questionAndOption : null
        });
      });
    }
    if (this.defaultList !== null && this.defaultList.length) {
      this.defaultList.forEach((item: any, index: any) => {
        this.defaultTextList.push({
          description: item.description,
          textNum: item.textNum,
          seq: index + 1,
          addToTextList: []
        });
      });
    }

    if (this.appendList !== null && this.appendList.length) {
      this.addToTextList = [];
      this.defaultTextList.map((defaultItem, index) => {
        // defaultItem.seq = index + 1;
        delete defaultItem.disabled;
        this.appendList.forEach((o: any) => {
          if (o.textNum.includes(defaultItem.textNum)) {
            if (o.addValue === '追加文案') {
              o.eventType = '1';
            } else if (o.addValue === '删除文案') {
              o.eventType = '2';
            }
            o.questionAndOption = [];
            if (!o.questionAndOptions) {
              o.questionAndOptions = null;
            }
            if (o.questionAndOptions !== null && o.questionAndOptions.length) {
              o.questionAndOptions.forEach((options: any) => {
                o.questionAndOption.push({
                  questionId: options.questionId,
                  code: options.code,
                  questionOptionIds: options.optionIds
                });
              });
            }

            this.addToTextList.push({
              textNum: o.textNum,
              description: o.description,
              eventType: o.eventType,
              questionAndOptions: o.questionAndOption.length ? o.questionAndOption : null
            });
            defaultItem.addToTextList = this.addToTextList;
          }
        });

        if (defaultItem.addToTextList?.length) {
          defaultItem.addToTextList = defaultItem.addToTextList!.filter(
            (addText: any) => addText.textNum.split('-')[0] === defaultItem.textNum
          );
        } else {
          defaultItem.addToTextList = null;
        }
      });
    }

    this.changeParams.defaultTextList = this.defaultTextList;
    this.verify();
    if (this.isWarningDefault || this.isWarningAppend || this.isWarningReplenish) return;
    this.ConfirmFlag = true;
    this.confirmContent = '是否确定保存？';
    this.confirmDetails = '文案修改后，将于下一个自然日0点生效';
  }

  // 确认保存
  async confirmDone() {
    if (this.isdoctor && this.confirmContent.includes('删除')) {
      this.ConfirmFlag = false;
      this.defaultList = [];
      this.appendList = [];
      return;
    }
    const {
      data: { msg }
    } = await service.changeReportEdit(this.changeParams);
    if (msg === '操作成功') {
      this.$notify({
        title: '保存成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.closeConfirm();
    }
  }
}
