
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import service from '@/api/reportFile';
import { setSkinType, getSkinType } from '@/utils/cookies';
@Component({
  components: {}
})
export default class ContrastReport extends Vue {
  @Prop({ type: String, required: true })
  mode!: string;

  @Prop({ type: String, required: true })
  skinQuestionType!: string; // 肌肤问题类型

  @Prop({ type: [Object], default: () => ({}) })
  skinTagInfo!: Record<string, any>; // 指标id  apply:0首次报告 1变化报告

  isShowTag = true;
  reportTag = [
    {
      title: '新问题',
      description: '',
      symptom: 1
    },
    {
      title: '变严重',
      description: '',
      symptom: 2
    },
    {
      title: '持续中',
      description: '',
      symptom: 0
    },
    {
      title: '已改善',
      description: '',
      symptom: -1
    },
    {
      title: '已恢复',
      description: '',
      symptom: -2
    }
  ];

  skinType = {} as any;
  causeList = [] as any;
  targetIds = ['13', '15', '16'];
  @Watch('skinTagInfo', { deep: true })
  async onChangeInfo(newVal: any) {
    if (newVal) {
      this.getDetail(newVal);
    }
    if (this.mode === '全部' && this.targetIds.includes(newVal.targetId)) {
      this.isShowTag = false;
    } else if (this.mode === '全部' && !this.targetIds.includes(newVal.targetId)) {
      this.isShowTag = true;
    }
  }

  @Watch('mode')
  onChangeMode(newVal: any) {
    if (newVal === '首次报告') {
      this.isShowTag = false;
    } else {
      this.isShowTag = true;
    }
  }

  editFactor() {
    setSkinType({
      targetId: this.skinTagInfo.targetId,
      targetName: this.skinQuestionType,
      mode: this.mode,
      radio: this.skinType.radio ? this.skinType.radio : '首次报告',
      planChildType: this.skinType.planChildType ? this.skinType.planChildType : '通用版',
      firstTextType: this.skinType.firstTextType ? this.skinType.firstTextType : '通用版'
    });
    this.$router.push({
      path: `/reportFile/factorDetail/${this.skinTagInfo.targetId}`,
      query: { targetName: this.skinQuestionType }
    });
  }

  editLabel() {
    setSkinType({
      targetId: this.skinTagInfo.targetId,
      targetName: this.skinQuestionType,
      mode: this.mode,
      radio: this.skinType.radio ? this.skinType.radio : '首次报告',
      planChildType: this.skinType.planChildType ? this.skinType.planChildType : '通用版',
      firstTextType: this.skinType.firstTextType ? this.skinType.firstTextType : '通用版'
    });
    this.$router.push({
      path: `/reportFile/changeDetail/${this.skinTagInfo.targetId}/${this.skinTagInfo.apply}`,
      query: { targetName: this.skinQuestionType }
    });
  }

  async getDetail(params: any) {
    const {
      data: { data }
    } = await service.causeDetail({ targetId: params.targetId });
    this.causeList = data;
    this.reportTag.forEach((item: any) => {
      item.description = '';
    });
    const skinTagList = await service.skinTagDetail(params);
    if (skinTagList.data.data.length) {
      this.reportTag.forEach((Tag: any) => {
        skinTagList.data.data.forEach((item: any) => {
          if (Tag.symptom === item.symptom) {
            Tag.description = item.description;
          }
        });
      });
    } else {
      this.reportTag.forEach((item: any) => {
        item.description = '';
      });
    }
  }

  async mounted() {
    this.getDetail(this.skinTagInfo);
  }

  activated() {
    this.getDetail(this.skinTagInfo);
    if (getSkinType()) {
      this.skinType = JSON.parse(getSkinType()!);
    }
  }
}
