import request from '@/utils/request';

const service = {
  detail(id: any) {
    return request({
      method: 'POST',
      url: `/api/device/v1/device/detail/?devId=${id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  info(data: any) {
    return request({
      method: 'POST',
      url: '/api/device/v1/device/info',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data
    });
  },
  getBindUser(id: any) {
    return request({
      method: 'POST',
      url: `/api/user/v1/belong/device/list/?devId=${id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getFirmware(id: any) {
    return request({
      method: 'POST',
      url: `/api/ota/v1/firmware/upgrade/info/fiveList?deviceId=${id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default service;
