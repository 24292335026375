
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/deviceLog';
import serviceC from '@/api/consumerUser';
import { downLoadFile } from '@/utils/download';
import OSS from 'ali-oss';

@Component({ components: {} })
export default class PageDeviceLog extends Mixins(MixinTable) {
  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    cpIdLike: null,
    fullnameLike: null,
    dataType: null
  } as any;

  typeList = [
    {
      value: 1,
      label: '日志'
    },
    {
      value: 2,
      label: '错误码'
    }
  ];

  params = {
    pageNum: 1,
    pageSize: 10
  };

  list = [] as any;
  pageTotal = 0;
  ConfirmFlag = false;
  versionLog = null as any;
  edit(row: any) {
    this.$router.push({ name: 'deviceLogDetail' });
    sessionStorage.setItem('versionLog', JSON.stringify(row));
  }

  download(row: any) {
    this.versionLog = row;
    this.ConfirmFlag = true;
  }

  // 下载完成
  async confirmDone() {
    const fileList = JSON.parse(this.versionLog.dataContent);
    const path = fileList[0].url.split('/').slice(0, -1).join('/');
    const {
      data: { data }
    } = await serviceC.getOSSToken(path);
    if (data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: data.securityToken,
        // 填写Bucket名称。
        bucket: data.bucketName
      });
      fileList.forEach((item: any) => {
        if (item.url) {
          item.url = client.signatureUrl(data.pathPrefix.split('/')[0] + '/' + item.url);
        }
      });
      const list = fileList.filter((item: any) => item.url);
      downLoadFile(list, `${this.versionLog.createdOn}  ${this.versionLog.cpId}`);
    }

    this.ConfirmFlag = false;
  }

  handlePageNum(value: number) {
    this.params.pageNum = value;
    this.getList();
  }

  query() {
    if (!this.queryForm.fullnameLike && !this.queryForm.cpIdLike && !this.queryForm.dataType) return;

    if (this.queryForm.fullnameLike !== null) {
      const bol = this.limitLength(this.queryForm.fullnameLike);
      if (!bol) {
        this.$notify({
          title: '用户昵称限制2-16个字节',
          message: '',
          type: 'warning',
          position: 'bottom-right'
        });
        return;
      }
    }
    if (this.queryForm.cpIdLike !== null && this.queryForm.cpIdLike.length > 18) {
      this.$notify({
        title: 'SN码限制输入18位数字',
        message: '',
        type: 'warning',
        position: 'bottom-right'
      });
      return;
    }
    this.getList();
  }

  limitLength(value: any) {
    const split = value.split('');
    // 判断一段文字里面有几个汉字跟英文
    const map = split.map((s: any, i: any) => {
      return value.charCodeAt(i) >= 0 && value.charCodeAt(i) <= 128 ? 1 : 2;
    });
    // 计算数组相加的综合
    const charLength =
      map.length > 0 &&
      map.reduce((accumulator: any, currentValue: any, index: any) => {
        const count = accumulator + currentValue;
        return count;
      });
    if (charLength >= 2 && charLength <= 16) {
      return true;
    }
    return false;
  }

  onReset() {
    this.params.pageNum = 1;
    this.$queryForm.resetFields();
    this.list = [];
  }

  async getList() {
    const {
      data: { data }
    } = await service.query(this.params, this.queryForm);
    if (data.records !== null) {
      this.pageTotal = data.total;
      this.list = data.records;
    } else {
      this.list = [];
    }
  }

  activated() {
    this.onReset();
  }

  // table
  get tableColumns() {
    const data: ColumnItem<DeviceLogItem>[] = [
      { label: '时间', prop: 'createdOn', width: 260 },
      { label: '用户ID', prop: 'userId' },
      { label: '用户昵称', prop: 'fullname' },
      { label: '设备sn', prop: 'cpId' },
      { label: '设备型号', prop: 'deviceModel' },
      { label: '固件版本', prop: 'firmwareVersion' },
      {
        label: '数据类型',
        prop: 'dataType',
        formatter(row, col, value, index) {
          return value === 1 ? '日志' : '错误码';
        }
      },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 200
      }
    ];
    return data;
  }
}
