
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
@Component({ components: {} })
export default class QuestionDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  drawer!: boolean;

  @Prop({ type: [Object], default: () => ({}) })
  details?: Record<string, any>;

  @Prop({ type: String, required: true })
  title!: '';

  @Prop({ type: [Array], required: true })
  list!: Record<string, any>[];

  @Prop({ type: [Array], required: true })
  letterList!: Record<string, any>[];

  @Prop({ type: [Object], default: () => ({}) })
  questionInfo?: Record<string, any>;

  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    questionName: null // 问题名称
  };

  rowkeys = [] as any;
  tableData = [] as any;
  isDrawer = false;
  optionStatus = [] as any;
  optNum = 0;
  @Watch('drawer')
  onChangeDrawer(newVal: boolean) {
    this.isDrawer = newVal;
    this.rowkeys = [];
    if (this.questionInfo!.id) {
      this.tableData.forEach((element: any) => {
        element.questionOptionDrafts.forEach((q: any) => {
          if (q.questionId !== this.questionInfo!.id) {
            q.optionalStatus = -1;
          }
        });
      });
    }
    this.queryForm.questionName = null;
    this.tableData = this.list;
  }

  @Watch('list')
  onChangeList(newVal: boolean) {
    this.optNum = 0;
    this.tableData = newVal;
    this.optionStatus = [...this.list];
    this.tableData.forEach((element: any) => {
      element.questionOptionDrafts.forEach((q: any) => {
        if (q.optionalStatus === 1) {
          q.status = true;
        }
        if (q.optionalStatus === -1) {
          q.disabled = true;
        }
        this.optNum++;
      });
    });
  }

  changeCheckList(e: any, row: any, index: any) {
    let num = 0;
    // 修改选中项
    this.tableData.forEach((element: any) => {
      element.questionOptionDrafts.forEach((q: any) => {
        if (q.status !== true) {
          num++;
        }
        if (row.questionId !== q.questionId && !q.disabled) {
          q.flag = true;
          q.optionalStatus = -1;
        }
        if (q.id === row.id) {
          q.status = e;
        }
      });
    });
    // 第二遍 判断未选中的数量 是不是和 题目总数量相同，相同就放开禁用
    this.tableData.forEach((element: any) => {
      element.questionOptionDrafts.forEach((q: any) => {
        if (num === this.optNum && !q.disabled) {
          q.optionalStatus = 0;
        }
        // 判断最初状态是否是禁用
        this.optionStatus.forEach((item: any) => {
          item.questionOptionDrafts.forEach((itemq: any) => {
            if (itemq.optionalStatus === -1 && q.id === itemq.id && q.disabled) {
              q.optionalStatus = -1;
            }
          });
        });
      });
    });

    this.rowkeys[index] = true;
    this.tableData = JSON.parse(JSON.stringify(this.tableData)); // 解决数据更新视图不更新
  }

  onQuery() {
    if (this.queryForm.questionName) {
      this.tableData = this.tableData.filter((item: any) => {
        if (item.code.includes(this.queryForm.questionName) || item.ask.includes(this.queryForm.questionName)) {
          return item;
        }
      });
    } else {
      this.tableData = this.list;
    }
  }

  save() {
    this.$emit('save', this.tableData);
  }
}
