
import { Component, Mixins } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import service from '@/api/consumerUser';
import { downLoadFile } from '@/utils/download';
import OSS from 'ali-oss';
import axios from 'axios';

@Component({ components: {} })
export default class DeviceLogDetail extends Mixins(MixinTable) {
  activeName = '0';
  dataType = 1;
  ConfirmFlag = false;
  content = '是否确认下载日志？';
  versionLog = {} as any;
  fileList = [] as any;
  tabContent = '';
  errCode = '';
  handleClick() {
    this.getContent(+this.activeName);
  }

  // 下载完成
  async confirmDone() {
    const list = this.fileList.filter((item: any) => item.url);
    downLoadFile(list, `${this.versionLog.createdOn}  ${this.versionLog.cpId}`);
    this.ConfirmFlag = false;
  }

  getContent(index: number) {
    if (this.fileList[index].url) {
      axios.get(this.fileList[index].url).then((res: any) => {
        this.tabContent = res.data.replace(/\n/g, '<br/>');
      });
    } else {
      this.tabContent = this.fileList[index].failed;
    }
  }

  async activated() {
    this.versionLog = JSON.parse(sessionStorage.getItem('versionLog')!);
    this.dataType = this.versionLog.dataType;
    this.fileList = JSON.parse(this.versionLog.dataContent);
    this.fileList.forEach(async (item: any) => {
      item.tabName = item.name;
      if (item.name === 'messages') {
        item.tabName = '/var/log/messages';
      }
    });

    // 错误码直接显示，没有下载
    if (this.dataType === 2) {
      this.errCode = this.fileList[0].url || this.fileList[0].failed;
      return;
    }
    const path = this.fileList[0].url.split('/').slice(0, -1).join('/');
    const {
      data: { data }
    } = await service.getOSSToken(path);
    if (data) {
      const client = new OSS({
        region: 'oss-cn-shanghai',
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: data.securityToken,
        // 填写Bucket名称。
        bucket: data.bucketName
      });
      this.fileList.forEach(async (item: any) => {
        if (item.url) {
          item.url = client.signatureUrl(data.pathPrefix.split('/')[0] + '/' + item.url);
        }
      });
      this.getContent(0);
    }
  }
}
