import request from '@/utils/request';

const service = {
  query(params: any, data: any) {
    return request.post(`/api/user/v1/user/log/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}`, data);
  },
  getModuleNameList() {
    return request.post('/api/user/v1/user/log/getModuleNameList');
  },
  getOperatorObjectTypeList() {
    return request.post('/api/user/v1/user/log/getOperatorObjectTypeList');
  }
};

export default service;
