
import { Component, Mixins } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import service from '@/api/questions';
import questionTable from './table.vue';
import YkWarn from '@/components/Yk_Warn/index.vue';
import Sortable from 'sortablejs';
@Component({
  components: { questionTable, YkWarn }
})
export default class PageTopicGroupDetailUp extends Mixins(MixinTable) {
  title = '已上线题组';
  isWarning = false;
  isWarn = false;
  loading = false;
  list = [] as any;
  ConfirmFlag = false;
  questionInfo = {} as any;
  sortList = {
    id: '',
    questionDrafts: [] as any
  } as any;

  id = 0;
  async del(row: any) {
    const {
      data: { data }
    } = await service.canDelQuestion(row.id);

    if (data) {
      this.isWarn = false;
      this.id = row.id;
      this.ConfirmFlag = true;
    } else {
      this.isWarn = true;
    }
  }

  async confirmDone() {
    const {
      data: { msg }
    } = await service.delQuestionPub(this.id, {});
    if (msg === '操作成功') {
      this.ConfirmFlag = false;
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.getList();

      this.getHasChange();
    }
  }

  // 创建sortable实例
  initSortable() {
    const ele = document.querySelector('.el-table__body > tbody');
    // 创建拖拽实例
    Sortable.create(ele, {
      animation: 150, // 动画
      disabled: false, // 拖拽不可用? false 启用（刚刚渲染表格的时候起作用，后面不起作用）
      handle: '.move', // 指定拖拽目标，点击此目标才可拖拽元素(此例中设置操作按钮拖拽)
      filter: '.disabled', // 指定不可拖动的类名（el-table中可通过row-class-name设置行的class）
      dragClass: 'dragClass', // 设置拖拽样式类名
      ghostClass: 'ghostClass', // 设置拖拽停靠样式类名
      chosenClass: 'chosenClass', // 设置选中样式类名
      // 开始拖动事件
      onStart: () => {
        // console.log('开始拖动');
      },
      onEnd: (event: any) => {
        const tempTableData = [...this.list]; // 先存一份临时变量表头数据
        let temp = '' as any;
        temp = tempTableData[event.oldIndex]; //
        tempTableData.splice(event.oldIndex, 1);
        tempTableData.splice(event.newIndex, 0, temp);
        this.list = [];
        this.$nextTick(async () => {
          // this.sortIds = [];
          this.sortList.questionDrafts = [];
          this.list = tempTableData;
          this.list.forEach((item: any) => {
            this.sortList.questionDrafts.push({
              id: item.id,
              parentId: item.parentId
            });
          });
          const {
            data: { msg }
          } = await service.questionSort(this.sortList);
          if (msg === '操作成功') {
            this.getList();
            this.getHasChange();
          }
        });
      }
    });
  }

  mounted() {
    this.initSortable();
  }

  created() {
    if (this.$route.query) {
      this.title = this.title + ' ' + this.$route.query.code;
      this.questionInfo.code = this.$route.query.code;
    }
  }

  activated() {
    if (this.$route.params.id) {
      this.sortList.id = this.$route.params.id;

      this.questionInfo.id = this.$route.params.id;
      this.getList();
      this.getHasChange();
    }
  }

  async getHasChange() {
    const {
      data: { data }
    } = await service.groupHasChange(this.$route.params.id);
    if (data) {
      this.isWarning = true;
    } else {
      this.isWarning = false;
    }
  }

  async getList() {
    this.loading = true;
    const {
      data: { data }
    } = await service.groupQuestionList(this.$route.params.id);
    if (data) {
      this.list = data;
      this.list.forEach((item: any) => {
        if (item.classify === 1) {
          item.classify = '护肤建议';
        } else if (item.classify === 2) {
          item.classify = '生活建议';
        } else if (item.classify === 4) {
          item.classify = '医美/就医建议';
        }
        if (item.optionType === 1) {
          item.optionType = '单选';
        } else if (item.optionType === 2) {
          item.optionType = '多选';
        }
        if (item.questionType === 1) {
          item.questionType = '主题';
        } else if (item.questionType === 2) {
          item.questionType = '普通题';
        } else if (item.questionType === 3) {
          item.questionType = '延展题';
        }
        if (item.genderCondition === 0) {
          item.genderCondition = '所有用户';
        } else if (item.genderCondition === 1) {
          item.genderCondition = '男';
        } else if (item.genderCondition === 2) {
          item.genderCondition = '女';
        }
        if (item.skinTargetNames !== null) {
          item.skinTargetNames = item.skinTargetNames.toString();
        }
      });
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  get tableColumns() {
    const data: ColumnItem<questionItem>[] = [
      {
        slot: 'code',
        prop: 'code',
        label: '题目编号'
      },
      { label: '选项类型', prop: 'optionType' },
      { label: '肌肤问题类型', prop: 'skinTargetNames', tooltip: true },
      { label: '题目分类', prop: 'classify' },
      { label: '题目名称', prop: 'ask', tooltip: true },
      {
        slot: 'sort',
        prop: 'sort',
        label: '顺序'
      },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 200
      }
    ];
    return data;
  }
}
