
import { Component, Vue, Watch } from 'vue-property-decorator';
import service from '@/api/consumerUser';
import material from './material.vue';
import banner from './banner.vue';

@Component({
  components: { material, banner }
})
export default class PageBanner extends Vue {
  queryForm = {
    likeName: null,
    orders: [
      {
        column: 'modified_on',
        asc: false
      }
    ]
  };

  params = {
    pageNum: 1,
    pageSize: 10000
  };

  radio = '首页Banner';
  tagList = [] as any;
  async getTagList() {
    const {
      data: { data }
    } = await service.getTagList(this.params, this.queryForm);
    this.tagList = data.records;
    this.tagList = this.tagList.filter((item: any) => item.type === 1);
    this.tagList.forEach((item: any) => {
      item.label = item.name;
      item.value = item.id;
    });
  }

  @Watch('radio')
  onRadioChange() {
    if (this.radio === '首页Banner' && this.$route.path !== '/banner/index') {
      delete this.$route.params.title;
    }
  }

  activated() {
    if (this.$route.params.title === 'SC') {
      this.radio = '素材库';
    } else {
      this.radio = '首页Banner';
    }
    this.getTagList();
  }
}
