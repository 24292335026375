
import { Component, Mixins, Ref, Prop, Watch } from 'vue-property-decorator';
import { MixinTable } from '@/utils/mixins';
import type { ColumnItem } from '@/components/YK_Table/index.vue';
import { ElForm } from 'element-ui/types/form';
import service from '@/api/questions';
import YkWarn from '@/components/Yk_Warn/index.vue';
import questionTable from './table.vue';

@Component({
  components: { questionTable, YkWarn }
})
export default class PageTopic extends Mixins(MixinTable) {
  @Prop({ type: String, required: false })
  title?: string;

  @Ref('queryForm') readonly $queryForm!: ElForm;
  queryForm = {
    codeOrAskLike: null, // 编号或者题目
    skinTargetId: null, // 肌肤问题类型
    classify: null // 题目分类  1护肤建议、2生活建议、4医美就医建议
  } as any;

  params = {
    pageNum: 1,
    pageSize: 10
  };

  loading = false;
  isWarn = false;
  letterList = [] as string[]; // 字母列表
  ConfirmFlag = false;
  pageTotal = 0;
  currentPage = 1;
  currentSize = 0;
  list = [] as any;
  questionTypeList = [
    {
      id: 1,
      label: '护肤建议'
    },
    {
      id: 2,
      label: '生活建议'
    },
    {
      id: 4,
      label: '医美/就医建议'
    }
  ] as any;

  tagColor = [
    {
      textColor: '#F2C52D',
      textBgColor: '#FFF5D5'
    },
    {
      textColor: '#429CC4',
      textBgColor: '#D7F1FD'
    },
    {
      textColor: '#C3619D',
      textBgColor: '#FCE8F4'
    },
    {
      textColor: '#B69C8F',
      textBgColor: '#F2EEEC'
    },
    {
      textColor: '#8CD729',
      textBgColor: '#EBF7DB'
    },
    {
      textColor: '#646CCF',
      textBgColor: '#DBDDF7'
    }
  ];

  skinTypeList = [] as any;
  dimension = 0;

  @Watch('title')
  onChangeTitle(newVal: any) {
    if (newVal === '题目') {
      this.params = {
        pageNum: 1,
        pageSize: 10
      };

      this.getList();
      this.getByDimension();
    }
  }

  handleSizeChange(val: number) {
    this.params.pageSize = val;
    this.currentSize = val;
    this.getList();
  }

  handleCurrentChange(val: number) {
    this.currentPage = val;
    this.params.pageNum = val;
    this.getList();
  }

  id = 0;
  async del(row: any) {
    const {
      data: { data }
    } = await service.canDelQuestion(row.id);
    if (data) {
      this.isWarn = false;
      this.id = row.id;
      this.ConfirmFlag = true;
    } else {
      this.isWarn = true;
    }
  }

  async confirmDone() {
    const {
      data: { msg }
    } = await service.delQuestionPub(this.id, {});
    if (msg === '操作成功') {
      if (this.params.pageNum > 1 && this.list.length <= 1) {
        this.params.pageNum--;
      }
      this.ConfirmFlag = false;
      this.$notify({
        title: '删除成功',
        message: '',
        type: 'success',
        position: 'bottom-right'
      });
      this.getList();
    }
  }

  onReset() {
    this.currentSize = 10;
    this.currentPage = 1;
    this.$queryForm.resetFields();
    this.params = {
      pageNum: 1,
      pageSize: 10
    };
    this.getList();
  }

  options = [] as any;
  async getList() {
    this.loading = true;
    const {
      data: { data }
    } = await service.questionList(this.params, this.queryForm);
    if (data !== null) {
      this.pageTotal = data.total;
      this.list = data.records;
      this.list.forEach((item: any) => {
        if (item.classify === 1) {
          item.classify = '护肤建议';
        } else if (item.classify === 2) {
          item.classify = '生活建议';
        } else if (item.classify === 4) {
          item.classify = '医美/就医建议';
        }
        if (item.optionType === 1) {
          item.optionType = '单选';
        } else if (item.optionType === 2) {
          item.optionType = '多选';
        }
        if (item.questionType === 1) {
          item.questionType = '主题';
        } else if (item.questionType === 2) {
          item.questionType = '普通题';
        } else if (item.questionType === 3) {
          item.questionType = '延展题';
        }
        if (item.genderCondition === 0) {
          item.genderCondition = '所有用户';
        } else if (item.genderCondition === 1) {
          item.genderCondition = '男';
        } else if (item.genderCondition === 2) {
          item.genderCondition = '女';
        }
        if (item.skinTargetNames !== null) {
          item.skinTargetNames = item.skinTargetNames.toString();
        }
      });
      this.options = this.arrayTransfer(this.list);
      this.list.forEach((item: any) => {
        this.options.forEach((option: any) => {
          if (item.questionGroupId === option.questionGroupId) {
            item.index = option.index;
          }
        });
      });
    } else {
      this.list = [];
    }
    this.loading = false;
  }

  // 查询皮肤问题类型
  async getByDimension() {
    const {
      data: { data }
    } = await service.getByDimension({ dimension: this.dimension });
    this.skinTypeList = data;
  }

  created() {
    for (let i = 65; i <= 90; i++) {
      this.letterList.push(String.fromCharCode(i));
    }
  }

  arrayTransfer(data: any) {
    const resultArr = [] as any;

    data.forEach((item: any, index: any) => {
      index++;
      if (index > this.tagColor.length - 1) {
        index = 0;
      }
      let flag = true;
      resultArr.forEach((item2: any, index2: any) => {
        item.index = index;
        if (item.questionGroupId === item2.questionGroupId) {
          flag = false;
          resultArr[index2].children.push(item);
        }
      });
      if (flag) {
        resultArr.push({
          index: index,
          questionGroupId: item.questionGroupId,
          children: [item]
        });
      }
    });

    return resultArr;
  }

  get tableColumns() {
    const data: ColumnItem<questionItem>[] = [
      {
        slot: 'code',
        prop: 'code',
        label: '题目编号'
      },
      { label: '选项类型', prop: 'optionType' },
      { label: '肌肤问题类型', prop: 'skinTargetNames', tooltip: true },
      { label: '题目分类', prop: 'classify' },
      { label: '题目名称', prop: 'ask', tooltip: true },
      {
        slot: 'sort',
        prop: 'seq',
        label: '顺序'
      },
      {
        slot: 'actions',
        prop: 'actions',
        label: '操作',
        width: 200
      }
    ];
    return data;
  }
}
