
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class YkWarn extends Vue {
  @Prop({ type: String, required: true })
  warnTitle!: string;

  @Prop({ type: String, required: false })
  warnText!: string;

  @Prop({ type: Boolean, required: true })
  isWarn!: boolean;

  @Prop({ type: String, required: false })
  type!: 'warn' | 'error' | 'success';

  icon = {
    warn: 'mingcute:warning-line',
    error: 'mi:circle-error',
    success: 'mdi:success-circle-outline'
  };

  handleClose() {
    this.$emit('close');
  }
}
